import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from "axios";
import { apiJSONURL } from '../pages/LiveCasino';
import UploadPhotoForm from './UploadPhoto';

export const dataLink = process.env.REACT_APP_DATA;

function Register() {
  
  async function encrypt(text) {
    const response = await axios.post(
      apiJSONURL,
      null,
      {
        params: {
          cmd: "getKey",
        },
      }
    );
    const key = response.data.key;
    const iv = key;
    const encodedText = new TextEncoder().encode(text);
    const cryptoKey = await window.crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode(key),
      { name: 'AES-CBC' },
      false,
      ['encrypt']
    );
    const encrypted = await window.crypto.subtle.encrypt(
      { name: 'AES-CBC', iv: new TextEncoder().encode(iv) },
      cryptoKey,
      encodedText
    );
    return new Uint8Array(encrypted);
  }
  
  function uint8ArrayToBase64(uint8Array) {
    let binary = '';
    const len = uint8Array.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(uint8Array[i]);
    }
    return btoa(binary);
  }

  const [formData, setFormData] = useState({
    phoneNumber: '',
    isGovtEmployee: true,
    source: '',
    referralID: '',
    otp: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [otpErrorMessage, setOtpErrorMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
  const [loading, setLoading] = useState(false);
  const [showMainReg, setShowMainReg] = useState(true);
  const [showRegOtp, setShowRegOtp] = useState(false);
  const [showRegPhoto, setShowRegPhoto] = useState(false);
  const [showRegConfirm, setshowRegConfirm] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const [countdown, setCountdown] = useState(0);

  const {phoneNumber, isGovtEmployee, source, referralID, otp, firstname, lastname, birthday } = formData;

  const getMaxDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 21);
    return today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
};

  useEffect(() => {
    setIsVisible(source === 'referral');
  }, [source]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const domain = window.location.hostname;
    const ag = urlParams.get('ag');
    if (domain.includes('festival')) {
      Cookies.set('ag', 'SG8JPTMR', { expires: 1 });
    } else if (ag) {
      Cookies.set('ag', ag, { expires: 1 });
    }

    const from = urlParams.get('from');
    if (from) {
      Cookies.set('fromParam', from, { expires: 1 });
    }
    const fromParam = Cookies.get('fromParam')
    if (fromParam === 'referral') {
      setIsVisible(true);
      setFormData(prevState => ({ ...prevState, source: 'referral' }));
    } else if (fromParam === 'postersBanners') {
      setFormData(prevState => ({ ...prevState, source: 'postersBanners' }));
    } else if (fromParam === 'X') {
      setFormData(prevState => ({ ...prevState, source: 'twitter' }));
    } else if (fromParam === 'facebook') {
      setFormData(prevState => ({ ...prevState, source: 'fb' }));
    } else if (fromParam === 'propellerads') {
      setFormData(prevState => ({ ...prevState, source: 'propellerads' }));
    } else if (fromParam === 'instagram') {
      setFormData(prevState => ({ ...prevState, source: 'instagram' }));
    } else if (fromParam === 'tiktok') {
      setFormData(prevState => ({ ...prevState, source: 'tiktok' }));
    } else if (fromParam === 'sms') {
      setFormData(prevState => ({ ...prevState, source: 'sms' }));
    }
    
    const ref = urlParams.get('ref');
    if (ref) {
      const decodedRef = atob(ref);
      const [refUsername, refCode] = decodedRef.split('-');
      Cookies.set('ref', refCode, { expires: 1 });
      setFormData(prevState => ({ ...prevState, referralID: refUsername }));
    }
  }, []);

  useEffect(() => {
    const body = document.querySelector('body');
    if (isLoggedIn) {
      body.classList.add('isLoggedIn');
    } else {
      body.classList.remove('isLoggedIn');
    }

    const handleClick = (event) => {
      if (event.target.classList.contains('modal-close')) {
        document.querySelectorAll('input').forEach(input => input.value = '');
        document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => checkbox.checked = false);
        document.querySelectorAll('select').forEach(select => select.selectedIndex = 0);
        setErrorMessage('');
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isLoggedIn]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const agValue = Cookies.get('ag') || 'SG8JPPMR';

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSetnumber = async () => {
    
    if (!phoneNumber) {
    setErrorMessage("Please enter your phone number");
    return;
    }

    if (phoneNumber.length !== 11) {
      setErrorMessage("Phone number must be 11 characters long");
      return;
    }

    if (!/^\d+$/.test(phoneNumber)) {
      setErrorMessage("Phone number must only include numbers");
      return;
    }

    if (!/^09/.test(phoneNumber)) {
      setErrorMessage("Phone number must start with 09");
      return;
    }

    if (!isGovtEmployee) {
      setErrorMessage("Please agree to the Terms and Conditions and Privacy Policy to proceed.");
      return;
    }
    setErrorMessage('');
    handleRegister();
     setshowRegConfirm(false);
    //  setShowMainReg(false);
  }

  const handleRegister = async () => {
    // const over21 = document.querySelector('input[name="Over21"]').checked;
    // const government = document.querySelector('input[name="Goverment"]').checked;
    // const gel = document.querySelector('input[name="Gel"]').checked;
    // const ndrp = document.querySelector('input[name="Ndrp"]').checked;

    // if (!over21 || !government || !gel || !ndrp) {
    //   setErrorMessage('Please confirm all required points before proceeding.');
    //   return;
    // }

    let sourceValue = source;
    switch (source) {
      case 'fb':
      case 'fbads':
        sourceValue = 'Facebook Ads';
        break;
      case 'instagram':
        sourceValue = 'Instagram';
        break;
      case 'twitter':
        sourceValue = 'Twitter';
        break;
      case 'tiktok':
        sourceValue = 'Tiktok';
        break;
      case 'google':
        sourceValue = 'Google Search';
        break;
      case 'googleAds':
        sourceValue = 'Google Ads';
        break;
      case 'referral':
        sourceValue = 'Friend Referral';
        break;
      case 'sms':
        sourceValue = 'SMS';
        break;
      case 'postersBanners':
        sourceValue = 'Posters/Banners';
        break;
      case 'others':
        sourceValue = 'Others';
        break;
      default:
        break;
    }
    
    try {
      if (Cookies.get('fromSkinnyMikes')) {
        sourceValue = 'Skinny Mike\'s';
      }
      setLoading(true);
      const response = await fetch(dataLink, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams({
          cmd: "registerjs",
          ag: agValue,
          firstname: firstname,
          lastname: lastname,
          birthday: birthday,
          phone: phoneNumber,
          domain: "sg8.casino",
          from: sourceValue,
          findus1: 'referral',
          findus2: referralID,
        })
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      if (data.success) {
        localStorage.setItem('username', data.username);
        await encrypt(data.username).then(encrypted => {
          localStorage.setItem('encryptedUsername', uint8ArrayToBase64(encrypted));
        });
        if ('true') {
          setShowRegOtp(true);
          setshowRegConfirm(false);
          setShowMainReg(false);
          setCountdown(60)
        } else {
          setIsLoggedIn(true);
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("registered", "true");
          setErrorMessage('Registration successful:');
          if (window.location.pathname === "/promotion/skinny-mikes/" || window.location.pathname === "/promotion/skinny-mikes") {
                 window.location.href = "/promotion/skinny-mikes/";
               }else{
          window.location.href = "/";
            }
        }
      } else {
        setErrorMessage(data.emsg || "Registration failed. Please try again.");
      }
    } catch (error) {
      setErrorMessage("There was a problem with the registration operation. Please try again.");
    }
      setLoading(false);
  };

  const handleResendOTP = async () => {
    if (countdown > 0) return; // Prevent resending if countdown is active

    try {
      const response = await axios.post(
        dataLink,
        null,
        {
          params: {
            cmd: "resendOTP",
            mobile: phoneNumber,
          },
        }
      );
      setCountdown(60); // Start the countdown after OTP is sent
    } catch (error) {
      console.error("Error getting OTP:", error);
    }
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(timer); // Clear the timer when countdown reaches 0 or component unmounts
    }
  }, [countdown]);

  const messageClass = errorMessage === 'Registration successful:' ? 'smsg' : 'emsg';

  const handleOTP = async () => {
    setLoading(true);
    const username = localStorage.getItem('username');

    try {
      const response = await fetch(dataLink, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams({
          cmd: 'verifyAccount',
          u: username,
          otp: otp
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.success) {
        setShowRegPhoto(true);
        setShowRegOtp(false);
        setshowRegConfirm(false);
        // localStorage.setItem("isLoggedIn", "true");
        // localStorage.setItem("registered", "true");
        // setIsLoggedIn(true);
        // window.location.href = "/";
      } else {
        setOtpErrorMessage(data.e || "Registration failed. Please try again.");
      }
    } catch (error) {
      setOtpErrorMessage("An unexpected error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {showMainReg && (
      <div>
      <img className='reg-banner' src='/images/welcome-bonus-reg.webp' alt='Get ₱30 Free on Sign Up – Claim Now!'/>
      <div className='form-register'>
        <div className='formcol mobile-number'>
          <div>
            <label>Provide your mobile number</label>
            <input type="tel" name="phoneNumber" onChange={handleInputChange} placeholder="Phone Number" />
          </div>
        </div>
        <label className='notgoverment'>
          <input
            type="checkbox"
            name="isGovtEmployee"
            onChange={handleInputChange}
            defaultChecked
            required
            title="Please confirm that you are not a government official or employee."
          />
          I agree to the <a href='/terms-and-condition/' target="_blank">Terms and Conditions</a> and <a href='/privacy-policy/' target='_blank'>Privacy and Policy</a>.
        </label>
        <button onClick={handleSetnumber}>Register</button>
        {errorMessage && <p className={messageClass}>{errorMessage}</p>}
      </div>
      </div>)}
      {showRegConfirm && (
        <div>
          <img className='reg-banner' src='/images/welcome-bonus-reg.webp' alt='Get ₱30 Free on Sign Up – Claim Now!'/>
        <div className='confirmation-container' style={{backgroundImage: 'url("/images/registration-form-bg.jpg")'}}>
<div class="formcol datacollect">
  <div class="regirow">
    <div class="form-group">
      <label for="firstname">First Name</label>
      <input type="text" id="firstname" name="firstname" placeholder="First Name" />
    </div>
    <div class="form-group">
      <label for="lastname">Last Name</label>
      <input type="text" id="lastname" name="lastname" placeholder="Last Name" />
    </div>
  </div>
  <div class="form-group">
    <label for="birthday">Birthday</label>
    <input type="date" id="birthday" name="birthday" placeholder="dd/mm/yyyy" />
  </div>
</div>
          {errorMessage && <p className={messageClass}>{errorMessage}</p>}
          <br/>
          <button onClick={handleRegister}>Confirm</button>
          </div>
        </div>
      )}
      {showRegOtp && (
        <div className='form-OTP'>
          <div>
            <label>An OTP has been sent to your registered mobile number.</label>
            <input type="text" name="otp" onChange={handleInputChange} placeholder="INPUT OTP" />
            <button className={`resendotp ${countdown > 0 ? 'countdown-active' : ''}`} onClick={handleResendOTP} disabled={countdown > 0}>
              {countdown > 0 ? `Resend in ${countdown}s` : "Resend"}
            </button>
            <button onClick={handleOTP} disabled={loading}>
              {loading ? 'Loading...' : 'Submit OTP'}
            </button>
            {otpErrorMessage && (
          <p style={{ color: '#ff8400', fontWeight: '600', display: 'block,', textAlign: 'center', padding: '10px', fontSize: '.9em' }}>{otpErrorMessage}</p>
            )}
          </div>
        </div>
      )}{loading && <div className='loadingContainer'><img src="/images/sg8.gif" alt="Loading..." /><span>Processing...</span></div>}
      {showRegPhoto && (
        <div className='form-photo' style={{backgroundImage: 'url("/images/registration-form-bg.jpg")'}}>
          <UploadPhotoForm/>
        </div>
      )}
    </div>
  );
}

export default Register;
import React from 'react';
import { Helmet } from "react-helmet";
import {currentPath, currentDomain} from '../../App';
import CouponRedeem from '../../snippets/CouponRedeem';

function Coupon() {;



  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Redeem Coupon | Top Online Casino 2024</title> 
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta name="description" content="Explore the top online slot games of 2024 with SG8 Casino. Enjoy premium slots and exciting gameplay. Play today"/>
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="main-wrapper">
      <div className='skinny-mikes-promotion' style={{backgroundImage: 'url("/images/sm-bg.webp")', width: '100%', backgroundSize: 'cover', backgroundPosition: 'center', marginTop: '-50px', paddingTop: '50px', paddingBottom: '50px'}}>
        <div className='coupon-area'  style={{backgroundImage: 'url("/images/mainbg.webp")', backgroundSize: 'cover'}}>
          <img className='sm-title' src='/images/ugne.webp'/>
          <CouponRedeem/>
        </div>
      </div>
      </div>
    </>
  );
}

export default Coupon;

import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import MainSlider from "../../snippets/MainSlider";
import { Helmet } from "react-helmet";
import {currentPath, currentDomain} from "../../App";

export default function ReferralBonus() {
  const title = "Referral Bonus";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("ReferralBonus");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("ReferralBonus");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
      <Helmet>
                <meta charSet="utf-8" />
                <title>SG8 Casino Referral Bonus | Earn Up to 10% for Each Friend</title> 
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta name="description" content="Invite your friends to SG8 Casino and earn up to 10% referral bonus. Share the excitement and enjoy extra rewards together. Refer now!"/>
                <link rel="canonical" href={`${currentDomain}${currentPath}`} />
            </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />
        <h2>Referral Bonus up to 10%</h2>
        <h2>General Mechanics:</h2>
        <ol>
          <li>
            Referral commission is based on the referred player’s total losses
            per week (Monday 06:00 AM – Monday 05:59 AM) and the referrer’s VIP
            Level (%)
          </li>
          <li>
            NGR = GGR – Welcome Bonus/Birthday Bonus/ other Bonuses – Cashback –
            Promotion.
          </li>
          <li>Expenses</li>
          <li>NGR (Net Gaming Revenue)</li>
          <li>GGR (Gross Gaming Revenue)</li>
        </ol>
        
        <h2>To be eligible for the SG Referral bonus, referrer MUST have</h2>
        <ol>
           <li>At least 4 Active referred players weekly.</li>
           <li>Minimum of Php 8,000 losses from referrals.</li>

        </ol>
        <h2>Sample Computation:</h2>
        <ol>
          <li>Referred players total losses: Php 10,000</li>
          <li>Expenses: Php 7,000 (including and not limited to bonuses, promotions and etc)</li>
          <li>Earned referral commission: Php (10,000 – 7,000) x 10% (Referrer’s VIP Level is PLATINUM = Php 300)</li>
          <li>SG8 also reserves the right to exclude any players/applicants from this promotion where it detects applicants bearing the same identity (same name, mailing address. Email address, telephone number, payment account) when registering on the website.</li>
          <li>1x rollover is required before withdrawal bonus</li>
          <li>VIP Tiers and Requirements
            <ol style={{listStyleType: "upper-roman"}}>
              <li>VIP Tiers will be updated at 1PM (GMT+8) on the 1st day of every month.</li>
              <li>Meeting both requirements is necessary to advance to the next tier.</li>
            </ol>
          </li>
        </ol>
        <h2>Terms and conditions:</h2>
        <ol>
          <li>General SG8.Casino terms and conditions apply.</li>
          <li>
            SG8.Casino reserves the right to take back any bonuses claimed by
            players in the event that the terms and conditions of the promotion
            are found to have been breached by the player, and/or there is
            reasonable suspicion of foul play.
          </li>
        </ol>
      </div>
    </>
  );
}

import React from "react"
import { checkSchedule } from "./checkSchedule"
export default function RollingCommissionIdle({ handleClose, handleRedirect }) {
  let provider = checkSchedule("2024-12-01T00:00:00") ? "PGSoft" : "Fa Chai"
  return (
    <div className="modal trigger-modal">
      <div className="trigger-main-container rolling-commission">
        <button
          className="verification-close modal-close"
          onClick={handleClose}
        >
          ✖
        </button>
        <div className="trigger-image">
          <img
            className="desktop"
            src={
              checkSchedule("2024-12-01T00:00:00")
                ? "/images/promotion/sg8-monthly-roll-out-pgsoft-popup.webp"
                : "/images/rollout-pop-fc.webp"
            }
            alt="Get up to .25% rolling commission!"
          />
          <img
            className="mobile"
            src={
              checkSchedule("2024-12-01T00:00:00")
                ? "/images/promotion/sg8-monthly-roll-out-pgsoft-popup.webp"
                : "/images/rollout-pop-fc.webp"
            }
            alt="Get up to .25% rolling commission!"
          />
        </div>
        <h2>Are You Still There?</h2>
        <p>
          Check out <b>{provider}</b>
          <br />
          and get up to <b>.25% rolling commission!</b>
        </p>
        <button className="yellow-btn" onClick={handleRedirect}>
          Click here to play
        </button>
      </div>
    </div>
  )
}

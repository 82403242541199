import React, { useState, useEffect } from "react"
import { tokenURL, httpsLaunchURL, apiJSONURL } from "../pages/LiveCasino"
import axios from "axios"

const RecentGames = () => {
  const [recentGames, setRecentGames] = useState([])
  const [launching, setLaunching] = useState(false)

  useEffect(() => {
    const loadedRecentGames =
      JSON.parse(localStorage.getItem("recentGames")) || []
    setRecentGames(loadedRecentGames)
  }, [])

  const launchGame = async (game) => {
    setLaunching(true)

    try {
      const username = localStorage.getItem("username")
      const encryptedUsernameResponse = await axios.post(
        apiJSONURL,
        `cmd=getToken&u=${username}`
      )
      const encryptedUsername = encryptedUsernameResponse.data.token

      const tokenResponse = await axios.get(
        `${tokenURL}?u=${encryptedUsername}&g=${game.gameId}&t=transfer&m=mobile`
      )
      const sessionToken = tokenResponse.data.token

      const lang = "en"
      const isMobile = window.innerWidth <= 840
      const mobileIndicator = isMobile ? "1" : "0"
      const url = `${httpsLaunchURL}?s=${sessionToken}&l=${lang}&m=${mobileIndicator}`
      console.log("Launching game with URL:", url)

      window.open(
        url,
        "_blank",
        "toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=600"
      )

      saveRecentGame(game) // Save the game to recent games
    } catch (error) {
      console.error("Failed to launch game:", error)
    } finally {
      setLaunching(false)
    }
  }

  const saveRecentGame = (game) => {
    let recentGames = JSON.parse(localStorage.getItem("recentGames")) || []
    recentGames = recentGames.filter((g) => g.gameId !== game.gameId) // Remove duplicate game
    recentGames.unshift(game) // Add the new game to the beginning
    if (recentGames.length > 5) {
      recentGames.pop() // Remove the oldest game if more than 5
    }
    localStorage.setItem("recentGames", JSON.stringify(recentGames))
  }

  return (
    <div className="recentGamesWrapper">
      {recentGames.map((game, index) => (
        <div key={index} className="game">
          <div className="gameImg">
            <img
              alt={`${game.gameName}`}
              src={game.gameImg}
              style={{ opacity: 1, transition: "opacity 0.5s" }}
            />
          </div>
          <div className="gameDetails">
            <div className="gameName">{game.gameName}</div>
            <button onClick={() => launchGame(game)}>Play Now</button>
          </div>
        </div>
      ))}
      {launching && (
        <div className="launchingOverlay">
          <div className="loadingSpinner"></div>
          <p>Launching game...</p>
        </div>
      )}
    </div>
  )
}

export default RecentGames

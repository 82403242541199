import React, { createContext, useState, useEffect } from "react"

// Create the context
export const GlobalContext = createContext()

// Create the provider component that will wrap the entire app
export const GlobalContextProvider = ({ children }) => {
  // Global state variables
  const [activeModal, setActiveModal] = useState()
  const [uniqueGames, setUniqueGames] = useState(null)
  const [nonFeaturedGames, setNonFeaturedGames] = useState(null)
  const [nJGames, setNJGames] = useState(null)
  const [showDailyNotif, setShowDailyNotif] = useState(false)
  const [activePopup, setActivePopup] = useState(null)
  const [gameTime, setGameTime] = useState(null)
  const [isInGame, setIsInGame] = useState(false)
  const [popupModalActive, setPopupModalActive] = useState(false)
  const [voucherCode, setVoucherCode] = useState()
  const [status, setStatus] = useState()
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  function calculateTimeLeft(targetDate) {
    const difference = new Date(targetDate) - new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }
    return timeLeft
  }

  useEffect(() => {
    if (!localStorage.getItem("uniqueGames")) {
      localStorage.setItem("uniqueGames", 0)
    } else {
      const ug = Number(localStorage.getItem("uniqueGames")) //convert to number
      setUniqueGames(ug)
    }
  }, [uniqueGames]) // Run the effect only when uniqueGames is null

  useEffect(() => {
    if (!localStorage.getItem("nJGames")) {
      localStorage.setItem("nJGames", 0)
    } else {
      const ug = Number(localStorage.getItem("nJGames")) //convert to number
      setNJGames(ug)
    }
  }, [nJGames])

  useEffect(() => {
    if (!localStorage.getItem("nonFeaturedGames")) {
      localStorage.setItem("nonFeaturedGames", 0)
    } else {
      const ng = Number(localStorage.getItem("nonFeaturedGames")) //convert to number
      setNonFeaturedGames(ng)
    }
  }, [nonFeaturedGames])

  return (
    <GlobalContext.Provider
      value={{
        uniqueGames,
        setUniqueGames,
        nJGames,
        setNJGames,
        showDailyNotif,
        setShowDailyNotif,
        activePopup,
        setActivePopup,
        gameTime,
        setGameTime,
        isInGame,
        setIsInGame,
        popupModalActive,
        setPopupModalActive,
        activeModal,
        setActiveModal,
        nonFeaturedGames,
        setNonFeaturedGames,
        voucherCode,
        setVoucherCode,
        status,
        setStatus,
        timeLeft,
        setTimeLeft,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

import React, { useState, useEffect } from 'react'
import BackButton from '../../snippets/BackButton'
import { Helmet } from 'react-helmet'
import MainSlider from '../../snippets/MainSlider'
import { currentPath, currentDomain } from '../../App'

export default function CashBackBonus() {
  const title = 'Cashback Bonus'
  const [sliderCurrentPage, setSliderCurrentPage] = useState('CashBack')
  const [bannerCurrentPage, setBannerCurrentPage] = useState('CashBack')
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 70)
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SG8 Casino Cashback Bonus | Up to 6% Back on Your Bets</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Get rewarded at SG8 Casino with our cashback bonus, offering up to 6% back on your bets. Enjoy risk-free gaming and maximize your returns. Join now!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />

        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />
        <h2>Offer Cashback Up to 6%</h2>
        <p>Minimum of 3% bonus up to 6% bonus</p>
        <div className="table-main">
          <table>
            <thead>
              <th>VIP Tier</th>
              <th>Turnover Requirement</th>
              <th>Bonus</th>
            </thead>
            <tbody>
              <tr>
                <td>Bronze</td>
                <td>5x</td>
                <td>3%</td>
              </tr>
              <tr>
                <td>Silver</td>
                <td>5x</td>
                <td>4%</td>
              </tr>
              <tr>
                <td>Gold</td>
                <td>5x</td>
                <td>5%</td>
              </tr>
              <tr>
                <td>Platinum</td>
                <td>5x</td>
                <td>6%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h2>General Mechanics:</h2>
        <ol>
          <li>Open to all SG8 Players.</li>
          <li>
            Promotion period from 6:00 AM to 5:59 AM the next day. A minimum net
            loss of Php500 during the period qualifies for a cashback
          </li>
          <li>
            Players will receive up to 6% bonus based on their VIP tier and net
            losses within the promotion period.
          </li>
          <li>No maximum cashback to be paid out.</li>
          <li>5x turnover is required before withdrawal of bonus.</li>
          <li>Crediting of bonus will be automatic every 3PM the next day.</li>
          <li>This promotion is limited to one redemption daily</li>
        </ol>
        <h2>Terms and conditions:</h2>
        <ol>
          <li>
            All prizes and other marketing costs shall be shouldered by SG8
          </li>
          <li>
            SP reserves the right to take back any bonuses claimed by players in
            the event that the terms and conditions of the promotion are found
            to have been breached by the player, and/or there is reasonable
            suspicion of foul play.
          </li>
          <li>
            SP has the right to modify or cancel the promotion without prior
            notice and is subject to PAGCOR’s approval.
          </li>
          <li>
            SP retains the authority to disqualify a participant from the
            promotion upon observing instances of counter or arbitrage betting.
          </li>
          <li>All terms and conditions for this marketing promotion apply.</li>
        </ol>
      </div>
    </>
  )
}
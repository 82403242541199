import React from "react"
import BackButton from "../../snippets/BackButton"
import { Helmet } from "react-helmet"
import { currentPath, currentDomain } from "../../App"

export default function BlackFridayPage() {
  const title = "Black Friday 3 Days Sale-abration"
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          3-Days Black Friday Sale-abration – Hurry Before It's Gone!
        </title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Black Friday just got better! Enjoy 3 days of amazing sales and save big on your deposits. Sale ends soon—don’t miss out!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <div className="hero-article">
          <img
            src="/images/black-friday-sale-sg8casino.webp"
            alt="black friday sale sg8 casino"
            className="desktop-view"
          />
          <img
            src="/images/black-friday-sale-sg8casino-mobile.webp"
            alt="black friday sale sg8 casino"
            className="mobile-view"
          />
        </div>
        <h1>Black Friday up to 40% Bonus</h1>
        <p className="promotion-bold-text">Nov 29, 2024 - Dec 2, 2024</p>
        <h2>General Mechanics:</h2>
        <ol className="promo-page-list-style">
          <li>Open to all verified and registered players of SG8 Casino.</li>
          <li>
            To be eligible to this promotion, players need to deposit minimum of
            Php 500.
            <p>
              For every deposit, players will receive 1 entry to spin the Slot
              Machine.
            </p>
            <p>
              Players can earn up to 5 entries per day during the promotion
              period.
            </p>
          </li>
          <li>
            Once a player becomes eligible for this promotion, a pop-up “Slot
            Machine: Deposit Rewards” will appear upon deposit.
          </li>
          <li>
            The maximum bonus of each spin depends on the deposited amount of
            the Slot Machine is capped at Php 10,000.
            <span>
              <strong>Sample calculation for the bonus cap:</strong>
            </span>
            <p>Deposit amount: Php 30,000</p>
            <p>Bonus percentage: 40%</p>
            <p>Bonus amount: Php 30,000 x 40% = Php 12,000</p>
            <p>
              Since the calculated bonus (Php 12,000) exceeds the maximum cap
              (Php 10,000), you will only receive the capped amount.
            </p>
          </li>
          <li>
            The crediting of credits will be automatic upon the random trigger
            of a slot machine spin.
          </li>
          <li>
            The following turnover requirements, if any bonus is claimed, must
            be met during the promotion period before any withdrawals can be
            made.
          </li>
          <li>
            The period covered for this promotion is from November 29, 2024,
            6:00 AM until December 2, 2024, 5:59 AM. <b><i>Please note that December 2 is still included in the cut-off date.</i></b>
            <table>
              <th>Bonus Percentage</th>
              <th>Turnover Requirements</th>
              <tr>
                <td>10%</td>
                <td>3x turnover</td>
              </tr>
              <tr>
                <td>20%</td>
                <td>5x turnover</td>
              </tr>
              <tr>
                <td>30%</td>
                <td>10x turnover</td>
              </tr>
              <tr>
                <td>40%</td>
                <td>15x turnover</td>
              </tr>
            </table>
            <span>
              <strong>Sample calculation for 10% bonus:</strong>
            </span>
            <p>Deposit Amount: Php 500.</p>
            <p>Calculation: Php 500 x 10% = Php 50.</p>
            <p>Turnover Requirements: Php 50 x 3 = Php 150.</p>
          </li>
        </ol>
        <h2>Terms and Conditions:</h2>
        <ol className="promo-page-list-style">
          <li>
            All prizes and other marketing costs shall be shouldered by SG8.
          </li>
          <li>
            SP reserves the right to take back any bonuses claimed by players in
            the event that the terms and conditions of the promotion are found
            to have been breached by the player and/or there is reasonable
            suspicion of foul play.
          </li>
          <li>
            SP has the right to modify or cancel the promotion without prior
            notice and is subject to PAGCOR's approval.
          </li>
          <li>
            SP retains the authority to disqualify a participant from the
            promotion upon observing instances of counter or arbitrage betting.
          </li>
          <li>All terms and conditions for this marketing promotion apply.</li>
        </ol>
      </div>
    </>
  )
}

import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import {currentPath, currentDomain} from "../../App";

export default function PromotionPage() {
  const title = "Deposit Bonus";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("DepositBonus");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("DepositBonus");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SG8 Casino: Grab Your 25% Deposit Bonus Now and Boost Your Winnings!</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="SG8 Casino with our generous deposit bonus. Get more value for your money and enhance your gaming experience. Claim your bonus today!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />
        <h2>
          Deposit Bonus, Up to 25% bonus on Deposit,{" "}
          <span className="highlight">Max of Php 3,888</span>
        </h2>

       
        <h2>General Mechanics:</h2>
        <ol>
          <li>The player must be a verified player of SG8.Casino.</li>
          <li>Bonus Limit and Turnover Requirement before any withdrawals.</li>
        </ol>
        <div className="table-main">
          <table>
            <thead>
              <th>Deposit Bonus</th>
              <th>Bonus Limit</th>
              <th>Turnover Requirements</th>
            </thead>
            <tbody>
              <tr>
                <td>25% of Deposit</td>
                <td>Php 3,888</td>
                <td>3x</td>
              </tr>
              
            </tbody>
          </table>
        </div>
        <ol>
          <li>
            Bonus Computation and Turnover requirements example:
            <p>
              <strong>First Deposit:</strong> Php 1,000
            </p>
            <p>
              <strong>Bonus:</strong>Php 1,000 x 25% = Php 250
            </p>
            <p>
              <strong>Turnover Requirements:</strong>(Php 1,000 + Php 250) x
              3 = Php 3,750
            </p>
          </li>
          <li>
            The turnover requirement must be met within 30 days upon awarding
            and activation of the reward to be eligible. Otherwise, the same
            shall be cancelled.
          </li>
          <li>
            In order to claim the bonus, before deposit, the player must select
            the option “Bonus” in the Bonus Tab, after successful deposit, the
            bonus will be automatically credited to the account.
          </li>
          <li>This promotion is limited to a single redemption per player.</li>
          <li>
            This promo cannot be used in conjunction with any other active
            deposit bonus promos at the same time, if there’s any.
          </li>
        </ol>
        <h2>Terms and conditions:</h2>
        <ol>
          <li>
            All prizes and other marketing costs shall be shouldered by the
            Service Provider – SG8.
          </li>
          <li>General SG8.Casino terms and conditions apply.</li>
          <li>
            SG8.Casino reserves the right to take back any bonuses claimed by
            players in the event that the terms and conditions of the promotion
            are found to have been breached by the player, and/or there is
            reasonable suspicion of foul play.
          </li>
          <li>
            SG8.Casino reserves the right to modify or cancel the promotion at
            any time without prior notice and subject for PAGCOR’s approval.
          </li>
          <li>
            In case of any irregularities or disputes, SG8.Casino’s decision
            will be final. Any issues or concerns should be raised with the
            customer support team, and they will be resolved promptly and
            fairly.
          </li>
        </ol>
      </div>
    </>
  );
}

import Promotion from "./Promotion";

import React, { useState,useEffect } from "react"


function AppPromotion() {
  let appver;
  const activePage = window.location.pathname;
  if (activePage === "/app-promotion/" || activePage === "/app-promotion")
  {
    appver = true;
  }
  else
  {
    appver = false;
  }
  useEffect(() => {
    if (appver) {
      document.body.classList.add("app");
    }
    return () => {
      if (appver) {
        document.body.classList.remove("app");
      }
    };
  }, [appver, activePage]);
  
  return (
    <>
    <div className="app-promotion">
    <Promotion/>
    </div>
      </>
  )
}

export default AppPromotion

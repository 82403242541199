import React, { useState, useEffect } from "react"
import "./../style/gamestyle.css"
import BalloonComponent from "./BalloonComponent"

const BlackFridaySlots = () => {
  const [sequence, setSequence] = useState(["40%", "0%", "10%", "20%"])
  const [slots, setSlots] = useState(["10%", "20%", "30%", "40%"])
  const [spinning, setSpinning] = useState(false)
  const [animate, setAnimate] = useState(false)
  const [message, setMessage] = useState("")
  const [titleMessage, setTitleMessage] = useState("")
  const [button, setButton] = useState("")
  const [popupVisible, setPopupVisible] = useState(false)
  const [showSlotMachine, setShowSlotMachine] = useState(false)
  const [disableSpin, setDisableSpin] = useState(false)

  const spinSound = new Audio("/sound/slot-machine-sound.mp3")
  spinSound.volume = 0.1

  const username = localStorage.getItem("username")

  // Fetch entries to check eligibility
  const fetchEntries = async () => {
    try {
      const response = await fetch(
        `https://sg8.casino/json/wlp/json/indexPromo.jsp?cmd=getBFentries&u=${username}`
      )
      const data = await response.json()
      if (data.availableEntries === data.usedEntries) {
        const body = document.body
        if (body.classList.contains("isLoggedIn")) {
          setDisableSpin(true)
          setTitleMessage("You're not qualified")
          setMessage("Your chance to spin starts with a minimum ₱500 deposit")
          setButton("Okay")
          setPopupVisible(true)
        }
      } else if (data.availableEntries > data.usedEntries) {
        setDisableSpin(false)
        setMessage("")
        setTitleMessage("")
        const hasShownSlot = sessionStorage.getItem("hasShownSlotMachine")
        if (!hasShownSlot) {
          setShowSlotMachine(true)
          sessionStorage.setItem("hasShownSlotMachine", "true")
        }
      } else {
        setShowSlotMachine(false)
        setPopupVisible(false)
      }
    } catch (error) {
      console.error("Error fetching entries:", error)
    }
  }

  // Fetch prize after spinning
  const fetchPrize = async () => {
    try {
      const response = await fetch(
        `https://sg8.casino/json/wlp/json/indexPromo.jsp?cmd=getBFprize&u=${username}`
      )
      const data = await response.json()

      if (data.isWinner) {
        console.log("Winner detected:", data.prizeTier)
        const prizeSequences = {
          1: ["0%", "10%", "20%", "30%"],
          2: ["10%", "20%", "30%", "40%"],
          3: ["20%", "30%", "40%", "0%"],
          4: ["30%", "40%", "0%", "10%"],
        }

        const newSequence = prizeSequences[data.prizeTier] || sequence
        setSequence(newSequence) // Update state for future reference
        return {
          isWinner: data.isWinner,
          newSequence,
          prizeAmount: data.prizeAmount,
        }
      } else if (data.isWinner === false) {
        console.log("Winner False:", data.isWinner)
        const loseSequence = ["40%", "0%", "10%", "30%"]
        const newSequence = loseSequence || sequence
        setSequence(newSequence) // Update state for future reference
        return {
          isWinner: data.isWinner,
          newSequence,
          prizeAmount: data.prizeAmount,
        }
      }

      return data
    } catch (error) {
      console.error("Error fetching prize:", error)
      return null
    }
  }
  const spinSlots = async () => {
    if (spinning || disableSpin) return

    setSpinning(true)
    setAnimate(true)
    setMessage("")
    setTitleMessage("")
    setPopupVisible(false)

    spinSound.play()

    const prizeResult = await fetchPrize()

    if (prizeResult && prizeResult.isWinner) {
      const { newSequence, prizeAmount } = prizeResult

      setTimeout(() => {
        setSlots(newSequence) // Directly use new sequence
        setMessage(`You received ₱${prizeAmount}!`)
        setTitleMessage("🎉 Congrats! 🎉")
        setButton("Claim")
        setSpinning(false)
        setAnimate(false)
        setPopupVisible(true)
      }, 2700)
    } else {
      const { newSequence, prizeAmount } = prizeResult
      setTimeout(() => {
        setSlots(newSequence)
        setMessage("Better luck next time!")
        setTitleMessage("You did not win any prize")
        setButton("Okay")
        setSpinning(false)
        setAnimate(false)
        setPopupVisible(true)
      }, 2700)
    }
  }

  useEffect(() => {
    fetchEntries()
  }, [])

  useEffect(() => {
    console.log("Updated sequence:", sequence)
  }, [sequence])

  return (
    <>
      <div className="black-friday-promo">
        <div className="black-friday-floating-btn">
          {!showSlotMachine && (
            <>
              <div className="show-slot-button">
                <img
                  onClick={() => {
                    setShowSlotMachine(true)
                  }}
                  src="/images/black-friday-sale.png"
                  alt=""
                />
              </div>
            </>
          )}
        </div>
        <div className="slot-machine-container">
          {popupVisible && <BalloonComponent />}

          {showSlotMachine && (
            <>
              <div className="slot-machine-popup">
                <button
                  onClick={() => {
                    setShowSlotMachine(false)
                    setPopupVisible(false)
                  }}
                  className="close-slot-machine-button-bf"
                >
                  ✖
                </button>
                {popupVisible && (
                  <div className="popup-blackfriday">
                    <div className="popup-content-bf">
                      <h2>{titleMessage}</h2>
                      <p>{message}</p>
                      <button
                        className="yellow-btn"
                        onClick={() => {
                          window.location.reload()
                        }}
                      >
                        {button}
                      </button>
                    </div>
                  </div>
                )}
                <div className="black-friday-container">
                  <div className="slot-machine-tnc">
                    <h3>How to Join & Claim</h3>

                    <ol>
                      <li>
                        Open to all verified and registered player of
                        <strong> SG8 Casino.</strong>
                      </li>
                      <li>
                        Make a minimum deposit amount of <strong>₱500</strong>{" "}
                        to qualify for the promotion.
                      </li>
                      <li>
                        Every qualified deposit, you’ll receive 1 entry to spin
                        the Slot Machine.{" "}
                        <strong>Up to 5 entries per day</strong> during the
                        promotion period.
                      </li>
                      <li>
                        Any bonus triggered by the Slot Machine will be
                        <strong> automatically credited</strong> to your
                        account.
                      </li>
                    </ol>
                    <div className="hyperlink">
                      <a
                        href="/promotion/black-friday-sale/"
                        className="yellow-btn"
                      >
                        View T&C
                      </a>
                    </div>
                    <div className="pack-of-coins">
                      <img src="/images/pack-coins.webp" alt="" />
                      <img src="/images/pack-coins.webp" alt="" />
                    </div>
                  </div>

                  <div className="slot-machine-content">
                    <div className="slot-machine-asset-logo">
                      <img
                        src="/images/black-friday-asset-logo.webp"
                        alt="Black Friday"
                      />
                    </div>
                    <div className="slot-machine-asset-1">
                      <img
                        src="/images/black-friday-asset-1.webp"
                        alt="Asset 1"
                      />
                    </div>
                    <div className="slot-machine-arrow">
                      <img src="/images/arrow-slots.webp" alt="" />
                    </div>
                    <div className="slot-machine-asset-2">
                      <img src="/images/coins.webp" alt="Asset 1" />
                    </div>
                    <div className="slot-machine">
                      <div
                        className={`black-friday-slot-container ${
                          animate ? " black-friday-shaking" : ""
                        }`}
                      >
                        <div className="animated-border-box-glow"></div>
                        <div className="slots-bf-parent">
                          <div className="slots-machine-divider"></div>
                          <div
                            className={`slots-bf ${spinning ? "glowing" : ""}`}
                          >
                            <div className="slot-overlay"></div>
                            {slots.map((slot, index) => (
                              <div
                                key={index}
                                className={`slot-bf ${
                                  spinning ? "spinning blur" : ""
                                }`}
                              >
                                {slot}
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="slot-machine-side-bar">
                          <div className="slot-machine-handle-holder"></div>
                          <div className="lever-container">
                            <div
                              className={`lever-bar click ${
                                spinning && animate
                                  ? "pulling black-friday-shaking"
                                  : ""
                              } ${disableSpin ? "disabled" : ""}`}
                              onClick={() => {
                                if (!disableSpin) spinSlots()
                              }}
                            >
                              <div className="lever-bar-stick"></div>
                              <div className="circle-hole"></div>
                              <div className="lever-circle">SPIN NOW</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default BlackFridaySlots

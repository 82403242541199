import React, { useState, useEffect } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"

export const articleList = [
  {
    id: 0,
    href: "/jili-games/",
    heading: "JILI Games at SG8 Casino",
    context:
      "Are you ready to dive into a world of thrilling gaming? SG8 Casino invites you to explore the dynamic universe of JILI Games, now prominently featured on our platform. Whether you're on desktop or mobile, prepare for a gaming experience filled with thrill and huge potential winnings.",
    hidden: false,
  },
  {
    id: 6,
    href: "/jili-games/what-is-jili-games/",
    heading: "What is JILI Games?",
    context: "Explore the world of JILI Games.",
    hidden: false,
  },
  {
    id: 1,
    href: "/jili-games/how-to-play-at-sg8casino/",
    heading: "How to play JILI on SG8 Casino",
    context:
      "Learn to play JILI games on SG8 Casino with top-rated games, easy payment methods, and great bonuses.",
    hidden: false,
  },
  {
    id: 4,
    href: "/jili-games/why-filipino-love-jili-games/",
    heading: "Why Filipino Love JILI Games",
    context:
      "Top Choice for all Filipino Players JILI Games, with stunning gameplay and bonuses JILI came on top in the Philippines.",
    hidden: false,
  },
  {
    id: 7,
    href: "/jili-games/play-jili-with-gcash/",
    heading: "How to Play JILI in GCash?",
    context: "Your Platform for JILI Games with GCash",
    hidden: false,
  },
  {
    id: 8,
    href: "/jili-games/types-of-jili-games/",
    heading: "Types of JILI Games to Play",
    context:
      "Don’t know what to play? Choose from the four types of JILI games available at SG8.Casino.",
    hidden: false,
  },
  {
    id: 11,
    href: "/jili-games/volatility-jili-slots/",
    heading: "Volatility in JILI Slots: What is it & Which is Best?",
    context:
      "Low, mid or high volatility in slots tells how often a game pays out. Know more about it, its types, and how it tells you which JILI slot to play at SG8 Casino.",
    hidden: false,
  },
  {
    id: 2,
    href: "/jili-games/top-jili-games/",
    heading: "Top JILI Slots in the Philippines",
    context:
      "Discover the best JILI slots games, including popular titles like Super Ace, Boxing King & Fortune Gems.",
    hidden: false,
  },
  {
    id: 10,
    href: "/jili-games/jili-free-30-bonus-sg8-casino/",
    heading: "JILI Free 100? Get JILI Free 30 Bonus at SG8",
    context:
      "SG8 Casino offers a JILI Free 30 Welcome Bonus with fast cash-in and cash-out. Enjoy the ultimate gaming experience. Start playing today!",
    hidden: false,
  },
  {
    id: 5,
    href: "/jili-games/maximize-winnings-by-playing-jili-slots/",
    heading: "Maximize Your Winnings : Tips for Playing JILI Slots",
    context: "Tips to Win Big at SG8 Casino.",
    hidden: false,
  },
  {
    id: 3,
    href: "/jili-games/jili-best-casino-games/",
    heading: "JILI Best Online Casino Games",
    context:
      "JILI the best online casino games in the Philippines, with exciting slots and generous bonuses on SG8 Casino.",
    hidden: false,
  },
  {
    id: 9,
    href: "/jili-games/superace/",
    heading: "Play Super Ace at SG8 Casino",
    context:
      "Play JILI favorites Super Ace. Discover the excitement of JILI games and start winning today!",
    hidden: false,
  },
  {
    id: 12,
    href: "/jili-games/best-jili-games-2024/",
    heading: "Top 10 JILI Slots of 2024",
    context:
      "Explore the top 10 JILI slots of 2024! Find out which games offer the best odds, biggest jackpots, and most thrilling gameplay.",
    hidden: false,
  },
  {
    id: 13,
    href: "/jili-games/jili-pragmatic-play-bonuses-for-filipinos/",
    heading: "JILI vs. Pragmatic Play - Which Slot Are Better?",
    context:
      "Looking for the best slots games in the Philippines? See how JILI stacks up against Pragmatic Play in this detailed bonus showdown.",
    hidden: false,
  },
  {
    id: 14,
    href: "/jili-games/jili-slots-dominate-filipino-casino-scene/",
    heading: "Why JILI Slots Dominate Filipino Casino",
    context:
      "From high payouts to engaging themes, explore why JILI Slots are the top choice for Filipino online casino enthusiasts.",
    hidden: false,
  },
  {
    id: 15,
    href: "/jili-games/super-ace/",
    heading: "Super Ace - Jili Slot Games",
    context:
      "Explore Super Ace Casino by JILI Games a strategic slot with high RTP, Golden Spins, and cascading reels. Play now for big wins!",
    hidden: false,
  },
  {
    id: 16,
    href: "/jili-games/boxing-king/",
    heading: "Boxing King - Jili Slot Games",
    context:
      "Unleash your inner champion with JILI Games Boxing King. Engage in thrilling gameplay with cascading wins, multipliers, and stacked wilds.",
    hidden: false,
  },
  {
    id: 17,
    href: "/jili-games/fortune-gems/",
    heading: "Fortune Gems - Jili Slot Games",
    context:
      "Explore the vibrant world of JILI Games Fortune Gems, a slot that blends high RTP with captivating visuals. Spin to win big!",
    hidden: false,
  },
  {
    id: 18,
    href: "/jili-games/golden-empire/",
    heading: "Golden Empire - Jili Slot Games",
    context:
      "Golden Empire JILI slots offer massive jackpots and exciting features. Find out how to unlock these rewards and start winning now.",
    hidden: false,
  },
  {
    id: 19,
    href: "/jili-games/money-coming/",
    heading: "Money Coming - Jili Slot Games",
    context:
      "Money Coming JILI Slots: Uncover hidden features and strategies to boost your wins. Start winning big today!",
    hidden: false,
  },
  {
    id: 20,
    href: "/jili-games/color-game/",
    heading: "Color Games - Jili Games",
    context:
      "Master the Color Games JILI slot with our expert strategies. Learn how to play smart, win big, and enjoy every game!",
    hidden: false,
  },
  {
    id: 21,
    href: "/jili-games/mega-ace/",
    heading: "Mega Ace - Jili Slots Games",
    context:
      "Discover the thrills of JILI Games Mega Ace. With 46,656 paylines, Joker Cards, and huge multipliers, every spin is a step towards big rewards!",
    hidden: false,
  },
  {
    id: 22,
    href: "/jili-games/win-crazy-777-jili/",
    heading: "Win at Crazy 777 Jili Tips & Secrets Revealed",
    context:
      "Maximize your winnings with these expert strategies for Crazy 777 Jili. Learn how to play smarter and hit those jackpots!",
    hidden: false,
  },
  {
    id: 23,
    href: "/jili-games/best-jili-casino-sign-up-bonuses-2024/",
    heading: "Grab the Best JILI Casino Bonuses of 2024 Today",
    context:
      "Explore the top JILI Casino sign-up bonuses in 2024. Maximize your gaming experience with the most lucrative offers available today!",
    hidden: false,
  },
  {
    id: 24,
    href: "/jili-games/what-are-jili-slots/",
    heading: "What Are JILI Slots?",
    context:
      "Discover what makes JILI Slots unique. Get insights into popular games, bonus features, and how to increase your chances of winning.",
    hidden: false,
  },
  {
    id: 25,
    href: "/jili-games/how-to-win-at-jili-slot-777/",
    heading: "Winning Strategies for JILI Slot 777",
    context:
      "Want to win more at JILI Slot 777? Our in-depth guide offers top tips and winning strategies to help you master the game and boost your payouts",
    hidden: false,
  },
  {
    id: 26,
    href: "/jili-games/best-jili-slots-to-play-2024/",
    heading: "Best Jili Slots for 2024 - Play & Win Now",
    context:
      "Looking for the best Jili slots to play in 2024? Explore top picks with high RTP, fun features, and big wins. Don't miss these top-rated games!",
    hidden: false,
  },
  {
    id: 27,
    href: "/jili-games/super-ace-jili-slot-free-spins/",
    heading: "Super Ace Jili Slot Free Spins",
    context:
      "Want to know how to get the most from free spins in Super Ace Jili Slot? This guide has everything you need to spin and win",
    hidden: false,
  },
  {
    id: 28,
    href: "/jili-games/how-to-win-super-ace-jili-slot/",
    heading: "How to win on Super Ace Jili Slot",
    context:
      "Ready to win big on Super Ace Jili Slot? Our guide covers everything from multipliers to free spins. Learn how to boost your winnings today!",
    hidden: false,
  },
  {
    id: 29,
    href: "/jili-games/high-rtp-jili-slots-super-ace-money-coming/",
    heading: "High RTP Jili Slots - Super Ace & Money Coming",
    context:
      "Looking for high RTP slots? Play Super Ace and Money Coming for the best payouts, progressive jackpots, and huge winning potential",
    hidden: false,
  },
  {
    id: 30,
    href: "/jili-games/sg8-casino-php30-free-sign-up-bonus-jili-slots/",
    heading: "Claim ₱30 Free at SG8 Casino – JILI Slots No Deposit",
    context:
      "Get ₱30 free at SG8 Casino upon sign-up to play JILI slots. No deposit needed—sign up now and start spinning!",
    hidden: false,
  },
  {
    id: 31,
    href: "/jili-games/top-jili-slot-games-sg8-casino/",
    heading: "Win Big with the Best JILI Slot Games",
    context:
      "Looking for top JILI slot games? Play the most popular titles at SG8 Casino and win amazing prizes!",
    hidden: false,
  },
  {
    id: 32,
    href: "/jili-games/free-play-jili-slots-online/",
    heading: "Free JILI Slot Games",
    context:
      "SG8 Casino offers popular JILI slots you can play for free. Sign up for 30 PHP credits and start spinning without any risk",
    hidden: false,
  },
  {
    id: 33,
    href: "/jili-games/win-big-jili-slots/",
    heading: "Win Big with JILI Slots – Get Your ₱30 Bonus",
    context:
      "Play JILI slots at SG8 Casino! With Super Ace, Golden Empire, and more, experience thrilling features and win big – ₱30 bonus included.",
    hidden: false,
  },
  // next 34
]

const ITEMS_PER_PAGE = 8

function ArticleList({ hiddenItems }) {
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState("")
  const [searchSuggestions, setSearchSuggestions] = useState([])
  const [displayedTitles, setDisplayedTitles] = useState([])

  useEffect(() => {
    // Update displayed titles when page changes
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
    const endIndex = currentPage * ITEMS_PER_PAGE

    const currentTitles = filteredArticles
      .slice(startIndex, endIndex)
      .map((data) => data.heading)

    setDisplayedTitles(currentTitles)
  }, [currentPage])

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase()
    setSearchQuery(query)
    setCurrentPage(1)

    if (query) {
      const suggestions = filteredArticles
        .filter((data) => !data.hidden && !hiddenItems.includes(data.id))
        .filter(
          (data) =>
            data.heading.toLowerCase().includes(query) ||
            data.context.toLowerCase().includes(query)
        )
        .filter((data) => !displayedTitles.includes(data.heading)) // Exclude displayed titles

      setSearchSuggestions(suggestions)
    } else {
      setSearchSuggestions([])
    }
  }

  const handleSuggestionClick = (href) => {
    window.location.href = href
    setSearchQuery("")
    setSearchSuggestions([])
  }

  const filteredArticles = articleList
    .filter((data) => !data.hidden && !hiddenItems.includes(data.id))
    .filter(
      (data) =>
        data.heading.toLowerCase().includes(searchQuery) ||
        data.context.toLowerCase().includes(searchQuery)
    )

  const totalPages = Math.ceil(filteredArticles.length / ITEMS_PER_PAGE)

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const accordionItemsArticle = filteredArticles.length ? (
    filteredArticles.map((data, index) => (
      <div
        className={`accordion-wrapper ${
          index >= (currentPage - 1) * ITEMS_PER_PAGE &&
          index < currentPage * ITEMS_PER_PAGE
            ? ""
            : "hidden"
        }`}
        key={data.id}
      >
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <h2>{data.heading}</h2>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="accordion-article-list">
              <p>{data.context}</p>
              <a href={data.href}>Read More</a>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </div>
    ))
  ) : (
    <div className="no-results-message">
      <p>
        No results found. Try exploring our{" "}
        <a href="/slots/jili/">JILI Games</a> for more exciting options!
      </p>
    </div>
  )

  return (
    <>
      <div className="main-content-article">
        <div className="faq-list-article">
          <div className="faq-article-heading">
            <h2>Related Topic for JILI Games</h2>
            <div className="search-bar">
              <input
                type="text"
                placeholder="JILI related topics"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              {searchSuggestions.length > 0 && (
                <ul className="search-suggestions">
                  {searchSuggestions.map((suggestion) => (
                    <li
                      key={suggestion.id}
                      onClick={() => handleSuggestionClick(suggestion.href)}
                    >
                      {suggestion.heading}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <Accordion allowZeroExpanded>{accordionItemsArticle}</Accordion>
          <p className="number-of-articles">
            {filteredArticles.length} topics found related to jili.
          </p>
        </div>
        <div className="pagination-controls">
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>
            &larr; {/* Left arrow */}
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            &rarr; {/* Right arrow */}
          </button>
        </div>
      </div>
    </>
  )
}

export default ArticleList

import React, { useState, useEffect } from 'react';
import { apiJSONURL} from './LiveCasino';
import "../style/accountStyle.css";
import RecentGames from "../snippets/RecentGames";

function Dashboard() {
  const [isAnyFieldEmpty, setIsAnyFieldEmpty] = useState(false);

  useEffect(() => {
    const getPlayerDetails = async () => {
      try {
        const encryptedUsername = localStorage.getItem('encryptedUsername');
        const response = await fetch(apiJSONURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            cmd: 'getPlayerDetails',
            u: encryptedUsername
          }).toString()
        });

        const data = await response.json();

        if (
          !data.placeOfBirth ||
          !data.currentAddress ||
          !data.permanentAddress ||
          !data.nationality ||
          !data.natureOfWork ||
          !data.typeOfIndustry ||
          !data.sourceOfIncome ||
          !data.idType ||
          !data.idNumber ||
          !data.photoStatus
        ) {
          setIsAnyFieldEmpty(true);
        }
        if (data.photoStatus === "1234") {
          const photoElement = document.getElementById('handlePhotoClick');
          if (photoElement) {
            photoElement.classList.add('verifyprofile');
          }
        }
        if (data.isPwdSet === false) {
          const createPwElement = document.getElementById('handleCreatePasswordClick');
          if (createPwElement) {
            createPwElement.classList.add('verifyprofile');
          }
        }
      } catch (error) {
        console.error("Error fetching player details:", error);
      }
    };

    getPlayerDetails();
  }, []);

  useEffect(() => {
    if (isAnyFieldEmpty) {
      const element = document.getElementById('handleVerifyClick');
      if (element) {
        element.classList.add('verifyprofile');
      }
    }
  }, [isAnyFieldEmpty]);

  const [loading, setLoading] = useState(true);
  const [currentPassword, setCurrentPassword] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchPlayerDetail = async () => {
      try {
        const username = localStorage.getItem('encryptedUsername');
        const response = await fetch(apiJSONURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: `cmd=PlayerDetail&u=${username}`
        });
        const data = await response.json();
        const currentPassword = atob(data.password);
        setCurrentPassword(currentPassword);
        // You can set other state variables here if needed
      } catch (error) {
        console.error('Error fetching player detail:', error);
      }
    };

    fetchPlayerDetail();
  }, []);

  const handlePasswordChange = () => {
    const currentPwd = document.getElementById('currentPwd').value;
    const newPwd = document.getElementById('newPwd').value;
    const newPwd2 = document.getElementById('newPwd2').value;

    // Check if the new password meets the additional conditions
    let message = '';
    if (newPwd.length < 6 || newPwd.length > 8) {
        message = "Password needs to be between 6 - 8 characters.";
    } else if (currentPwd === newPwd) {
        message = "You cannot change to the same password!";
    } else if (!/[A-Z]/.test(newPwd) || !/[a-z]/.test(newPwd)) {
        message = "[Strong Password Requirement] Password must contain both uppercase and lowercase characters";
    } else if (!/\d/.test(newPwd)) {
        message = "[Strong Password Requirement] Password must contain at least one number (eg: 0-9)";
    }

    if (message !== '') {
        setAlertMessage(message);
        return; // Exit the function if any condition fails
    }

    // If all conditions pass, proceed with password change
    if (currentPwd === currentPassword) {
        const username = localStorage.getItem('username');
        if (newPwd === newPwd2) {
            const requestBody = new FormData();
            requestBody.append('cmd', 'changePassword');
            requestBody.append('u', username);
            requestBody.append('pwd', newPwd);

            fetch(apiJSONURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams(requestBody).toString()
            })
            .then(response => response.json())
            .then(data => {
                const responseMessage = data.s;
                if (responseMessage) {
                    setAlertMessage(responseMessage);
                } else if (data.emsg) {
                    setAlertMessage('Failed to change password: ' + data.emsg);
                } else {
                    setAlertMessage('Failed to change password: Unknown error');
                }
            })
            .catch(error => {
                console.error('Error changing password:', error);
                setAlertMessage('Error changing password: ' + error.message);
            });
        } else {
          setAlertMessage('New passwords do not match');
        }
    } else {
        setAlertMessage('Current password is incorrect');
    }
  };

  return (
    <>
      <div className="account-content dashboard">
        <div className="welcome-user">
        </div>
        <div className="dashboard-content">
        <div id="handleVerifyClick" className="welcome-user complete-account-btn-container">
          <div className='verifyText'>Verify your account</div>
          <button className='verifyButton' style={{ display: "block" }}>
            <img src='/images/check.webp'/>Verify my Account
          </button>
        </div>
        <div id="handlePhotoClick" className="welcome-user complete-account-btn-container">
          <div className='verifyText'>Upload identification photos</div>
          <button className='verifyButton' style={{ display: "block" }}>
            <img src='/images/photo.webp'/>Upload Photo
          </button>
        </div>
        <div id="handleCreatePasswordClick" className="welcome-user complete-account-btn-container">
          <div className='verifyText'>Create password</div>
          <button className='verifyButton' style={{ display: "block" }}>
            <img src='/images/password.webp'/>Create password
          </button>
        </div>
        <div className="account-right-container">
          <div className="featured-icons">
            <div className="icons-container">
              <a
                href="/slots/rtg/"
                className="featured-item"
                style={{ textDecoration: "none" }}
              >
                <img src="/images/slot.png" />
                <br />
                <span>SLOTS</span>
              </a>
              <a
                href="/live-casino/"
                className="featured-item"
                style={{ textDecoration: "none" }}
              >
                <img src="/images/live-casino.png" />
                <br />
                <span>LIVE CASINO</span>
              </a>
              <a
                href="/sportsbook/"
                className="featured-item calumpit-filter"
                style={{ textDecoration: "none" }}
              >
                <img src="/images/sports.png" />
                <br />
                <span>SPORTSBOOK</span>
              </a>
            </div>
          </div>
          <div className="recent-game-wrapper">
            <p>Recent Games</p>
            <RecentGames />
          </div>
        </div>
        <div className="vip-info-container">
          <div className="tier-info">
            <div className="account-collapsible">
              <ul>
                <li>
                  <h3>Change Password</h3>
                  <div className="collapse-content">
                    <div className="notice">
                      Notice: Password needs to be between 6 - 8 characters.
                    </div>
                    <form id="changePasswordForm">
                      <input name="cmd" type="hidden" value="CreatePlayer" />
                      <div>
                        <div className="field-row">
                          <label>Current Password</label>
                          <div className="field-container">
                            <input
                              type="password"
                              name=""
                              id="currentPwd"
                              maxLength="8"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="field-row">
                          <label>New Password</label>
                          <div className="field-container">
                            <input
                              type="password"
                              name="pwd"
                              id="newPwd"
                              maxLength="8"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="field-row">
                          <label>Confirm New Password</label>
                          <div className="field-container">
                            <input
                              type="password"
                              name=""
                              id="newPwd2"
                              maxLength="8"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        id="changepwd-alert-cont"
                        className="alert alert-info fade in alert-dismissable"
                      >
                        <div id="changepwd-alert">{alertMessage}</div>
                      </div>
                      <button
                        id="btn-submit"
                        className="red-button"
                        type="button"
                        onClick={handlePasswordChange}
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        </div>
      </div>

      <div className="main-content-wrapper"></div>
    </>
  );
}

export default Dashboard;

import React, { useState } from "react";

export default function SpinTheWheel({ toggleModal, isOpen }) {

    return (
             <div className={`game-modal ${isOpen ? "show" : ""}`}>
            <button className='modal-close' onClick={toggleModal}>✖</button>
            <div className="game-modal-content">
                <h2>SpinTheWheel</h2>
                <ol>
                    <li>Participants can enter Spin the Wheel in 3 different ways: 
                        <br/>
                        <ol>
                            <li>
                            Participate in Beer Pong and aim for the target criteria
                            </li>
                            <li>
                            Participate in Dart Throw and hit the required criteria
                            </li>
                            <li>
                            Spend a minimum of Php 800 at Skinny Mike
                            <br/>
                            Every Php800 spend will be given 1 try
                            </li>
                        </ol>
                    </li>
                    <li>
                    The wheel will have 8 parts:
                    <br/>
                    <ol>
                        <li>
                        <table>
                        <tr>
                        <th>No. of Parts</th>
                        <th>Prize</th>
                        </tr>
                        <tr>
                        <td>2</td>
                        <td>Free Cocktail Glass of your choice</td>
                        </tr>
                        <tr>
                        <td>2</td>
                        <td>Free Php 50 Credits</td>
                        </tr>
                        <tr>
                        <td>2</td>
                        <td>Free Pork BBQ (php85)</td>
                        </tr>
                        <tr>
                        <td>1</td>
                        <td>8 Raffle Tickets</td>
                        </tr>
                        <tr>
                        <td>1</td>
                        <td>Free Php 80 Credits</td>
                        </tr>
                        </table>
                        </li>
                    </ol>
                    </li>

                </ol>
            </div>
        </div>
    );
}
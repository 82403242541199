import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import SlotProviderList from "../../snippets/SlotProviderList";
import SlotsFilter from "../../snippets/SlotsFilter";
import GameList from "../../snippets/GameList";
import SlotSearchList from '../../snippets/SlotSearchList';
import { currentPath, currentDomain } from '../../App';
import ArticleList from '../../snippets/ArticleList';

const jiliFilterOptions = [
    { value: 'all', label: 'All' },
    { value: 'top', label: 'Top' },
    { value: 'featured', label: 'Featured' },
    { value: 'slots', label: 'Slots' },
    { value: 'table', label: 'Table' },
    { value: 'arcade', label: 'Arcade' },
];

const Accordion = ({ title, previewContent, fullContent }) => {
    const [isOpen, setIsOpen] = useState(true); // Default to open
    const [showFullContent, setShowFullContent] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            setShowFullContent(false); // Reset content display when accordion is closed
        }
    };

    const toggleContentDisplay = () => {
        setShowFullContent(!showFullContent);
    };

    return (
        <div className="accordion-item">
            <div className="accordion-header" onClick={toggleAccordion}>
                <h2>{title}</h2>
            </div>
            {isOpen && (
                <div className="accordion-content">
                    <div>
                        {previewContent}
                        {/* Hidden content is still in the DOM, but visually hidden using max-height */}
                        <div
                            style={{
                                maxHeight: showFullContent ? 'none' : '0',
                                overflow: 'hidden',
                                transition: 'max-height 0.5s ease-out',
                            }}
                        >
                            {fullContent}
                        </div>
                    </div>
                    <button onClick={toggleContentDisplay}>
                        {showFullContent ? 'Read Less' : 'Read More'}
                    </button>
                </div>
            )}
        </div>
    );
};


function Jili() {
    const [currentFilter, setCurrentFilter] = useState('all');
    const [sliderCurrentPage, setSliderCurrentPage] = useState('JILI');
    const [bannerCurrentPage, setBannerCurrentPage] = useState('JILI');
    const [searchQuery, setSearchQuery] = useState("");
    const hiddenItems = [4];
    const handleFilterChange = (filter) => {
        setCurrentFilter(filter);
    };

    const previewContent = (
        <>
            <p>JILI slots have gained immense popularity among Filipino Players, known for their engaging gameplay, vibrant graphics, and potential for big wins. With a wide variety of themes and features, JILI offers something for every type of player. But with so many options, which games stand out as the best? Explore the <a href="/jili-games/top-jili-games/" className="article-internal-link">Top JILI Slots in the Philippines</a>, focusing on those that offer the best gaming experiences and highest chances of winning.</p>
            <h2>Why JILI Slots Are Popular in the Philippines</h2>
            <p>JILI slots have captured the attention of <a href="/jili-games/why-filipino-love-jili-games/" className="article-internal-link">Filipino Players</a> due to their unique combination of entertainment, fairness, and accessibility. Here’s why they’re so popular:</p>
            <ul>
                <li><strong>Variety of Themes:</strong> From adventure and mythology to fruit machines and ancient history, JILI offers a wide array of slot themes that appeal to different preferences.</li>
                <li><strong>User-Friendly Interface:</strong> The slots are designed with intuitive navigation, making them accessible for both beginners and seasoned players.</li>
                <li><strong>High RTP Rates:</strong> Return to Player (RTP) percentages are important to players, and JILI is known for offering competitive RTPs, meaning better long-term winning odds.</li>
                <li><strong>Bonuses and Promotions:</strong> Filipino players are drawn to the frequent promotions, including free spins and deposit bonuses, available for JILI slots.</li>
            </ul>
            <h2>Top 5 JILI Slots in the Philippines</h2>
        </>
    );

    const fullContent = (
        <>
            <div className="top-slots">
                <img src="https://sg8.casino/json/resources/images/WLP/60/200x200/60030.jpg" alt="Super Ace"/>
                <div className='top-slots-content'>
                    <h3>Super Ace</h3>
                    <p>Super Ace is a standout JILI slot game that has captured the attention of many Filipino players. The game features a unique poker card theme combined with stunning visuals and exciting gameplay mechanics. With cascading reels and multiple ways to win, <a href="/jili-games/super-ace/" className="article-internal-link">Super Ace</a> keeps players engaged as they chase big payouts. Its high RTP and frequent bonuses make it a favorite among those who enjoy dynamic slot action.</p>
                </div>
            </div>
            <div className="top-slots">
                <img src="https://sg8.casino/json/resources/images/WLP/60/200x200/60047.jpg" alt="Golden Empire"/>
                <div className='top-slots-content'>
                    <h3>Golden Empire</h3>
                    <p>Golden Empire stands out for its immersive theme and engaging bonus rounds. Set in the backdrop of ancient civilizations, this slot game offers unique features like cascading reels and expanding wilds. Filipino players enjoy <a href="/jili-games/golden-empire/" className="article-internal-link">Golden Empire</a> because of its potential for large payouts, especially during bonus rounds.</p>
                </div>
            </div>
            <div className="top-slots">
                <img src="https://sg8.casino/json/resources/images/WLP/60/200x200/60050.jpg" alt="Fortune Gems"/>
                <div className='top-slots-content'>
                    <h3>Fortune Gems</h3>
                    <p>Fortune Gems is a favorite among Filipino slot enthusiasts due to its simplicity and frequent payouts. This <a href="/jili-games/fortune-gems/" className="article-internal-link">slot game</a> offers a straightforward but captivating gameplay experience, where players can win big with the right combination of gem symbols. The game’s clean design and focus on bonus features make it a top choice for those looking to maximize their wins in a short time.</p>
                </div>
            </div>
            <div className="top-slots">
                <img src="https://sg8.casino/json/resources/images/WLP/60/200x200/60010.jpg" alt="Jungle King"/>
                <div className='top-slots-content'>
                    <h3>Jungle King</h3>
                    <p>Jungle King is an adventurous slot that takes players deep into the jungle in search of hidden riches. The game offers a variety of special features like free spins, wilds, and a bonus game that adds to the excitement. The game's appeal lies in its dynamic gameplay and the chance to unlock big rewards through multipliers and bonus rounds.</p>
                </div>
            </div>
            <div className="top-slots">
                <img src="https://sg8.casino/json/resources/images/WLP/60/200x200/60019.jpg" alt="Crazy 777"/>
                <div className='top-slots-content'>
                    <h3>Crazy 777</h3>
                    <p>For players who enjoy classic slot machines with a modern twist, Crazy 777 is a go-to option. The game combines traditional slot symbols with exciting bonus features, giving players a nostalgic feel with the added thrill of <a href="/jili-games/win-crazy-777-jili/" className="article-internal-link">potential large payouts</a>. Crazy 777 is particularly popular in the Philippines for its simplicity and potential for hitting the jackpot.</p>
                </div>
            </div>
            <h2>Tips for Maximizing Your Wins on JILI Slots</h2>
            <ul>
                <li><strong>Choose High RTP Games:</strong> Always check the RTP percentage of a game before you play. Games with higher RTPs, typically above 96%, give you better odds over time.</li>
                <li><strong>Take Advantage of Promotions:</strong> Look for online casinos offering bonuses specifically for JILI slots, such as free spins or cashback promotions. These can help extend your playtime and increase your chances of winning.</li>
                <li><strong>Set a Budget and Stick to It:</strong> It’s easy to get caught up in the excitement of slot games, but responsible gaming is crucial. Set a budget for your slot sessions and never chase your losses.</li>
                <li><strong>Play Free Versions First:</strong> If you’re unfamiliar with a game, try out the free version first. This will give you a better understanding of the game’s mechanics and help you develop a strategy before playing with real money.</li>
            </ul>
            <p>JILI slots have become a staple in the Philippine online gaming community, offering exciting themes, high RTPs, and engaging gameplay. Whether you're drawn to the adventure of Jungle King or the simplicity of Fortune Gems, there’s something for every player. By choosing the right games, taking advantage of bonuses, and playing responsibly, you can enhance your experience and increase your chances of winning.</p>
        </>
    );

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Play JILI Slot Games in Philippines - Claim Jili Free ₱30 Now!</title> 
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta name="description" content="Get free ₱30 and play Jili Slot Games in the Philippines now! Don’t miss this limited-time offer. Start playing and winning today!"/>
                <link rel="canonical" href={`${currentDomain}${currentPath}`} />
            </Helmet>
            <div className="main-wrapper">
                <div className="main-content-area">
                    <MainSlider sliderCurrentPage={sliderCurrentPage} bannerCurrentPage={bannerCurrentPage} />
                    <SlotProviderList initialProvider="jili" />
                    <div className="slot-filter-search-wrapper">
                        <div className="provider-logo-slots">
                            <img src="/images/slots/small-logo/jili-logo-small.webp" alt="jili" style={{ width: '100px' }} />
                        </div>
                        <SlotSearchList searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                        <div className="filter-slots">
                            <SlotsFilter filter={currentFilter} setFilter={handleFilterChange} filterOptions={jiliFilterOptions} />
                        </div>
                    </div>
                </div>
            </div>
            <GameList provider="jili" filter={currentFilter} searchQuery={searchQuery} />

            <div className="main-content-wrapper">
                <div className='page-content-section'>
                    <Accordion 
                        title="What is JILI Slots?"
                        previewContent={previewContent} // First half of the content
                        fullContent={fullContent} // Second half of the content
                    />
                </div>
            </div>
            <ArticleList hiddenItems={hiddenItems} />
        </>
    );
}

export default Jili;

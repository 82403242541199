import React, { useState, useEffect, useContext } from "react"
import Popup from "reactjs-popup"
import { GlobalContext } from "./GlobalContextProvider"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faXmark } from "@fortawesome/free-solid-svg-icons";
// import { apk } from '../Footer';

const PopUpModal = () => {
  const [open, setOpen] = useState(false)
  const [secondOpen, setSecondOpen] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const { popupModalActive, setPopupModalActive } = useContext(GlobalContext)

  useEffect(() => {
    const hasModalBeenShown = sessionStorage.getItem("modalShown")
    const shouldShowButton = sessionStorage.getItem("showButton")

    if (!hasModalBeenShown) {
      setPopupModalActive(true)
      setOpen(true)
    }

    if (shouldShowButton) {
      setShowButton(true)
    }

    if (open || secondOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }

    const handleUrlChange = () => {}

    window.addEventListener("popstate", handleUrlChange)
    window.addEventListener("hashchange", handleUrlChange)

    return () => {
      window.removeEventListener("popstate", handleUrlChange)
      window.removeEventListener("hashchange", handleUrlChange)
      document.body.style.overflow = "auto"
    }
  }, [open, secondOpen])

  const handleLinkClick = (confirmAge = false) => {
    if (confirmAge) {
      sessionStorage.setItem("modalShown", "true")
      window.location.reload()
      const doNotShowAgain = document.getElementById("doNotShowAgain").checked
      if (doNotShowAgain) {
        localStorage.setItem("doNotShowAgain", "true")
      }
    } else {
      window.location.href = "https://www.google.com"
    }
  }

  // const handleDownload = () => {
  //   window.location.href = apk;
  //   setSecondOpen(false);
  //   setShowButton(true);
  //   sessionStorage.setItem('showButton', 'true');
  // };

  // const handleSecondPopupClose = () => {
  //   setSecondOpen(false);
  //   setShowButton(true);
  //   sessionStorage.setItem('showButton', 'true');
  // };

  // const handleLearnMoreClick = () => {
  //   setShowButton(true);
  //   sessionStorage.setItem('showButton', 'true');
  //   window.location.href = '/news/download-sg8-casino-mobile-app/';
  // };

  return (
    <>
      <Popup
        open={open}
        onClose={() => setOpen(false)}
        modal
        nested
        closeOnDocumentClick={false}
        contentStyle={{ zIndex: 9999999 }}
        overlayStyle={{ zIndex: 9999998 }}
      >
        {(close) => (
          <div className="popUpModal modalWithBlur">
            <div className="popUpContent">
              <img
                src="/images/pagcor.png"
                alt="PAGCOR logo"
                className="pagcorLogo"
              />{" "}
              <img
                src="/images/21yearsoldlogocolored.webp"
                alt="21 Years Old PAGCOR logo"
                className="pagcorLogo"
              />
              <div className="content">
                <h2>IMPORTANT NOTICE</h2>
                <p>
                  The following personalities are <b>not allowed</b> to register
                  and/or play in this online gaming website:
                </p>
                <ul>
                  <li>Persons under 21 years of age</li>
                  <li>
                    Persons who are government officials, government employees,
                    or members of the Armed Forces of the Philippines, including
                    the Army, Navy, Air Force, and Philippine National Police
                  </li>
                  <li>Persons who hold a Gaming Employment License (GEL)</li>
                  <li>
                    Persons listed in the PAGCOR National Database of Restricted
                    Persons (NDRP)
                  </li>
                </ul>
                <span className="note">
                  Funds or credits in the account of player who is found
                  ineligible to play shall mean forfeiture of said funds/credits
                  in favor of the Government.
                </span>
                {/* <span className='note'>SG8.Casino <b><a href='/terms-and-condition/' target="_blank">Terms and Conditions</a></b> and <b><a href='/privacy-policy/' target='_blank'>Privacy Policy</a></b></span> */}

                <label>
                  <input type="checkbox" id="doNotShowAgain" />
                  Don't show this again
                </label>
                <div className="popUp-Btn-area">
                  <button
                    onClick={() => handleLinkClick(true)}
                    className="popup-btn"
                  >
                    Accept
                  </button>
                  <button
                    onClick={() => handleLinkClick(false)}
                    className="popup-btn"
                  >
                    Exit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Popup>

      {/* <Popup open={secondOpen} onClose={handleSecondPopupClose} modal nested closeOnDocumentClick={false}>
        {close => (
          <div className="popUpModal modalWithBlur download-popup-message" style={{ backgroundImage: `url('/images/mobile-app-available.webp')` }}>
              <div className='download-btn-wrapper'>
                <div className=''>
                  <button onClick={handleDownload} className='yellow-btn download-btn-popup'>Download Now</button>
                  <a onClick={handleLearnMoreClick} className='download-page-btn'>Learn More</a>
                  <button onClick={handleSecondPopupClose} className='yellow-btn download-btn-close'><FontAwesomeIcon icon={faXmark} /></button>
                </div>
            </div>
          </div>
        )}
      </Popup>

      {showButton && (
        <div className="fixed-button">
          <div className="app-button">
              <a href={apk} style={{cursor: "pointer"}} target="_new" rel="noopener" className='download-btn-global'><img src="/images/download/download.png" alt="Download our App now!"/></a>
          </div>
        </div>
      )} */}
    </>
  )
}

export default PopUpModal

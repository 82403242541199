import React from "react"
import { promotionList } from "../pages/Promotion"

export default function BackButton(props) {
  const handleClick = (e) => {
    e.preventDefault()
    window.history.go(-1)
  }

  // Find the promotion item based on props.name
  const promotionItem = promotionList.find((item) => item.title === props.name)

  return (
    <>
      <div className="backPage">
        <p>
          <a href="/promotion/">Promotion</a> &#x3e;{" "}
          {promotionItem ? (
            <a className="active" href={promotionItem.promoClick}>
              {promotionItem.title}
            </a>
          ) : (
            <span className="active">{props.name}</span>
          )}
        </p>
        <button onClick={handleClick}>
          <img src="/images/back_button.svg" alt="Back" /> Back
        </button>
      </div>
    </>
  )
}

import React, { useEffect, useState } from 'react';
import { apiJSONURL } from './LiveCasino';
import $ from 'jquery';
import "../style/accountStyle.css";
import AccountSelection from "../snippets/AccountSelection";

function RequestReport() {
    const [showTable, setShowTable] = useState(false);  // State to manage table visibility

    useEffect(() => {
        $("input[name='fd'],input[name='td']").val(getCurrentDate());

        const storedUserName = localStorage.getItem('username');
        if (!storedUserName) {
            throw new Error("Username not found in localStorage");
        }

        $("#rp-searchBtn").click(function() {
               const token = encodeURIComponent(localStorage.getItem('encryptedUsername'));
                const fd = $("input[name='fd']").val();
                const td = $("input[name='td']").val();
                getRequestReport(token, storedUserName, fd, td);
        });

        function getRequestReport(token, username, fd, td) {
            setShowTable(true);  // Show the table when search button is clicked
            
            $("#rp-reportList").html("<td colspan='6'>Loading...</td>");
            $("#rp-searchBtn").attr("disabled", "disabled");
            $.post(apiJSONURL, $("#rp-requestForm").serialize() + "&cmd=getRequestReport&u=" + token, function(d2) {
                const result2 = $.parseJSON(d2);
                let html = "";

                if ($.isEmptyObject(result2)) {
                    html += "<tr class='reportResult'>";
                    html += "<td colspan='6'>No record found.</td>";
                    html += "</tr>";
                } else {
                    $.each(result2, function(k, v) {
                        const datetime = v[0];
                        const id = v[1];
                        const type = v[2];
                        const bank = v[3];
                        const oprBank = v[4];
                        const amount = v[5];
                        const status = v[6];
                        const ip = v[7];
                        const processorMode = v[8];

                        html += "<tr class='reportResult'>";
                        html += "<td>" + datetime + "</td>";
                        html += "<td>" + type + "<br/>" + id + "</td>";
                        html += "<td>" + bank + "</td>";
                        html += "<td>" + oprBank + "</td>";
                        html += "<td>" + amount + "</td>";
                        if (status === 1 || status === 4 || status === 5) {
                            html += "<td class='success'>Success</td>";
                        } else if (status === 0 || status === 6) {
                            html += "<td class='failed'>Failed</td>";
                        } else if (status === -1 && oprBank === "GCASH" & type === "DEPOSIT") {
                            html += `<td class='pending'>Pending <button type='button' class='cancelBtn' data-username='${username}' data-id='${id}'>Cancel</button></td>`;
                        } else if (status === -1 & type === "DEPOSIT" && (processorMode === 8 || processorMode === 12 || processorMode === 3 || processorMode === 15 || processorMode === 21 || processorMode === 18)) {
                            html += `<td class='pending'>Pending <button type='button' class='cancelBtn' data-username='${username}' data-id='${id}'>Cancel</button></td>`;
                        } else if (status === -1) {
                            html += "<td class='pending'>Pending</td>";
                        }
                        html += "</tr>";
                    });
                }

                $("#rp-reportList").html(html);
                $("#rp-searchBtn").removeAttr("disabled");
            });
        }

        function cancelPendingDeposit(username, id) {
            $.post(apiJSONURL, { cmd: "cancelPendingDeposit", u: username, id: id }, function(d) {
                const result = $.parseJSON(d);
                if (result.s === "in") {
                    $.post(apiJSONURL, { cmd: "getToken", u: storedUserName }, function(d2) {
                        const result2 = $.parseJSON(d2);
                        const token = result2.token;
                        const fd = $("input[name='fd']").val();
                        const td = $("input[name='td']").val();
                        getRequestReport(token, fd, td);
                    });
                } else {
                    console.error("Failed to cancel pending deposit:", result.message);
                }
            });
        }

        $(document).on('click', '.cancelBtn', function() {
            const username = $(this).data('username');
            const id = $(this).data('id');
            cancelPendingDeposit(username, id);
        });

        function getCurrentDate() {
            const d = new Date();
            const month = d.getMonth() + 1;
            const day = d.getDate();

            const output = d.getFullYear() + '-' +
                (month < 10 ? '0' : '') + month + '-' +
                (day < 10 ? '0' : '') + day;

            return output;
        }

        return () => {
            $("#rp-searchBtn").off("click");
            $(document).off('click', '.cancelBtn');
        };
    }, []);

    return (
        <>
            <div className="account-content report-page">
                <AccountSelection />
                <div className="account-container tier-info">
                    <div className="report-info">
                        <h2>Funds Request Report</h2>
                        <div className="title-line"></div>
                        <div>
                            <p className="referral-text">View your consolidated report within the selected date range. Report reflects data recorded from 6AM (GMT+8) on the selected date to 6AM (GMT+8) the following day.</p>
                        </div>
                    </div>
                    <div className="table-fields">
                        <form id="rp-requestForm">
                            <div className="from-to-type-container">
                                <div className="field-row">
                                    <label>From:</label>
                                    <input type="date" name="fd" />
                                </div>
                                <div className="field-row">
                                    <label>To:</label>
                                    <input type="date" name="td" />
                                </div>
                                <div className="field-row">
                                    <label>Type:</label>
                                    <select name="status">
                                        <option value="-1">Pending</option>
                                        <option value="1">Success</option>
                                        <option value="0">Failed</option>
                                        <option value="-2">All</option>
                                    </select>
                                </div>
                                <div className="field-row">
                                    <button id="rp-searchBtn" className="yellow-btn" type="button">
                                        <i className="fa fa-search"></i>SEARCH
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {showTable && (
                        <div className="summary">
                            <h3>Summary</h3>
                            <div>
                                <table>
                                    <thead id="tableHeading">
                                        <tr>
                                            <th>Date</th>
                                            <th>Type of Ref. No.</th>
                                            <th>Bank</th>
                                            <th>SG8 Bank</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody id="rp-reportList"></tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="main-content-wrapper"></div>
        </>
    );
}

export default RequestReport;

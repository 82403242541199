import React, { useState, useContext } from 'react';
import { GlobalContext } from '../snippets/GlobalContextProvider';
import { checkSchedule } from '../snippets/checkSchedule';
export default function GameTime() {
    const {activePopup, setActivePopup} = useContext(GlobalContext);
    const { gameTime, setGameTime } = useContext(GlobalContext);

    const handleClose = () => {
        setGameTime(false);
        setActivePopup(null); 
      };

      const handleRedirect = () => {
        setGameTime(false);
        setActivePopup(null); 
        window.location.href = checkSchedule("2024-12-01T00:00:00") ? "/slots/pgsoft/" : "/slots/fachai/";
      };
      let provider = checkSchedule("2024-12-01T00:00:00") ?'PGSoft': 'Fa Chai'
    return (
        <div className="modal trigger-modal">
            <div className="trigger-main-container rolling-commission">
            <button className='verification-close modal-close' onClick={handleClose}>✖</button>
                <div className='trigger-image'>
                <img className='desktop' src={checkSchedule("2024-12-01T00:00:00") ?'/images/promotion/sg8-monthly-roll-out-pgsoft-popup.webp': '/images/rollout-pop-fc.webp'} alt='Get up to .25% rolling commission!'/>
                <img className='mobile' src={checkSchedule("2024-12-01T00:00:00") ?'/images/promotion/sg8-monthly-roll-out-pgsoft-popup.webp': '/images/rollout-pop-fc.webp'} alt='Get up to .25% rolling commission!'/>
                </div>
                <div className="modal-content">
                    <h2>Want to earn up to .25% commission? </h2>
                <p>Play any <b>{provider} Game</b> and get your rolling commissions now!</p>
           
                <button className='yellow-btn' onClick={handleRedirect}>Click here to start earning!</button>

                </div>
            </div>
        </div>
    );
}
import React, { useState, useEffect } from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom"
import SlickHomebanner from "./snippets/MainSlider"
import PaymentMethod from "./snippets/PaymentMethod"
import FeaturedGamesList from "./snippets/FeaturedGameList"
import ProviderListHome from "./snippets/ProviderListHome"
import { launchGame, AlertModal } from "./pages/LiveCasino"
import { Helmet } from "react-helmet"
import TopGameList from "./snippets/TopGameList"
import Schedule from "./snippets/Scheduler"
import PromotionFeatured from "./snippets/PromotionFeatured"
import GoldenNestJackpoSection from "./snippets/GoldenNestSection"

export const currentPath = window.location.pathname
export const currentDomain = window.location.origin

const Accordion = ({ title, previewContent, fullContent }) => {
  const [isOpen, setIsOpen] = useState(true) // Default to open
  const [showFullContent, setShowFullContent] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
    if (!isOpen) {
      setShowFullContent(false) // Reset content display when accordion is closed
    }
  }

  const toggleContentDisplay = () => {
    setShowFullContent(!showFullContent)
  }

  return (
    <div className="accordion-item">
      <div className="accordion-header" onClick={toggleAccordion}>
        <h2>{title}</h2>
      </div>
      {isOpen && (
        <div className="accordion-content">
          <div>
            {previewContent}
            {/* Hidden content is still in the DOM, but visually hidden using max-height */}
            <div
              style={{
                maxHeight: showFullContent ? "none" : "0",
                overflow: "hidden",
                transition: "max-height 0.5s ease-out",
              }}
            >
              {fullContent}
            </div>
          </div>
          <button onClick={toggleContentDisplay}>
            {showFullContent ? "Read Less" : "Read More"}
          </button>
        </div>
      )}
    </div>
  )
}

function App() {
  const [loading, setLoading] = useState(true)
  const sliderCurrentPage = "all"
  const bannerCurrentPage = "all"

  const [launching, setLaunching] = useState(false)
  const [currentFrame, setCurrentFrame] = useState(19)
  const [animationComplete, setAnimationComplete] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertOpen, setAlertOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(
    /Android|iPhone|iPad|iPod|Windows Phone/i.test(navigator.userAgent)
  )
  console.log(isMobile)
  const showAlert = (message) => {
    setAlertMessage(message)
    setAlertOpen(true)
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 70)
  }, [])

  // usePageTracking();

  const previewContent = (
    <>
      <p>
        In 2024, online casino gaming continues to thrive in the Philippines,
        and SG8 Casino stands out as the top platform for Filipino players. What
        sets us apart is our combination of trusted gameplay, user-friendly
        interfaces, and local payment options like GCash. As Filipino players
        increasingly seek platforms that offer a seamless blend of entertainment
        and security, SG8 Casino delivers on all fronts.
      </p>
      <p>
        Players are drawn to us not just for our wide range of games, but also
        for our commitment to fair play and licensed operations. In the
        Philippines, where online gambling regulations are crucial, having a
        platform that aligns with{" "}
        <strong>PAGCOR (Philippine Amusement and Gaming Corporation)</strong>{" "}
        guidelines ensures peace of mind.
      </p>
      <h2>Trusted Casino in the Philippines</h2>
      <p>
        Security is a top priority for Filipino players when choosing an online
        casino. In a landscape where the risk of fraud and data breaches is
        ever-present, SG8 Casino stands out as a trusted site. We use SSL
        encryption to protect our player data and offer secure payment gateways
        like <strong>GCash, PayMaya, and bank transfers,</strong> ensuring
        smooth transactions.
      </p>
      <p>
        We are one of the few platforms that consistently maintains player trust
        through transparent policies and timely payouts. The casino's license
        from PAGCOR further solidifies its standing as a legitimate and safe
        online platform, giving players confidence that their money and personal
        information are protected.
      </p>
      <h2>JILI Slots at SG8 Casino</h2>
      <p>
        When it comes to slot games, JILI slots have become a firm favorite
        among players at SG8 Casino. With vibrant themes, engaging gameplay, and
        the chance for big wins, these games attract both casual and seasoned
        players.
      </p>
    </>
  )

  const fullContent = (
    <>
      <p>
        The appeal of JILI slots lies in their{" "}
        <strong>high Return to Player (RTP) percentages</strong>, ensuring that
        players get better value for their money. Popular games like{" "}
        <a href="/jili-games/super-ace/" className="article-internal-link">
          Super Ace
        </a>{" "}
        and{" "}
        <a href="/jili-games/golden-empire/" className="article-internal-link">
          Golden Empire
        </a>{" "}
        are crowd favorites, offering players the chance to win big while
        enjoying immersive graphics and engaging bonus rounds.
      </p>
      <h2>Play the Best High-RTP Slots at SG8 Casino</h2>
      <p>
        One of the reasons players gravitate toward SG8 Casino is our selection
        of high-RTP slots. RTP, or Return to Player, is a percentage that
        indicates how much a player can expect to win back over time. At SG8,
        players can find slots with RTPs of over 96%, including popular titles
        like{" "}
        <a href="/jili-games/fortune-gems/" className="article-internal-link">
          Fortune Gems
        </a>{" "}
        and{" "}
        <a
          href="/jili-games/win-crazy-777-jili/"
          className="article-internal-link"
        >
          Crazy 777
        </a>
        .
      </p>
      <p>
        These high-RTP slots offer players better odds, making us the top
        destination for those who want to maximize their gaming experience. By
        focusing on games that provide a high payout potential, we ensures that
        players stay engaged and feel rewarded.
      </p>
      <h2>GCash at SG8 Casino for Secure Transactions</h2>
      <p>
        One of the standout features of SG8 Casino is our seamless integration
        with <strong>GCash</strong>, one of the Philippines' most popular
        e-wallets. For Filipino players, being able to use GCash means quick and
        secure deposits and withdrawals without the hassle of traditional
        banking methods.
      </p>
      <h2>Tips for Maximizing Your Wins at SG8 Casino</h2>
      <p>
        For players looking to maximize their chances of winning on our
        platform, here are a few strategies that can help:
      </p>
      <ul>
        <li>
          <strong>Choose High RTP Games:</strong> As mentioned, playing games
          with higher RTP percentages gives you better odds over time.
        </li>
        <li>
          <strong>Take Advantage of Promotions:</strong> We frequently offers
          deposit bonuses and cashback promotions. Be sure to check the{" "}
          <a href="/promotion/" className="article-internal-link">
            promotion page
          </a>{" "}
          regularly to take advantage of these offers.
        </li>
        <li>
          <strong>Manage Your Bankroll:</strong> Set a budget for each gaming
          session and stick to it. This ensures that you don't overspend and can
          enjoy playing without financial stress.
        </li>
      </ul>
      <p>
        Our platform emerges as the best online casino for Filipino players in
        2024 due to its focus on security, trusted payment options, and an
        excellent selection of high-RTP games like{" "}
        <a href="/slots/jili/" className="article-internal-link">
          JILI slots
        </a>
        . Our seamless integration with GCash further enhances the user
        experience, making it easier than ever for our local players to enjoy
        their favorite casino games.
      </p>
      <p>
        SG8 Casino not only provides endless entertainment but also ensures that
        our players have the best possible chance of success. We focus on
        players satisfaction, transparency, and security.
      </p>
      <p>
        <i>
          We improve our products and advertising by using Microsoft Clarity to
          see how you use our website. By using our site, you agree that we and
          Microsoft can collect and use this data. Our{" "}
          <a href="/privacy-policy">privacy statement</a> has more details.
        </i>
      </p>
    </>
  )

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>SG8 Casino - Best Online Casino for Filipinos in 2024</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Looking for the best online casino in 2024? SG8 Casino offers high-RTP slots, secure payments via GCash, and trusted gameplay for Filipino players."
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <AlertModal
        open={alertOpen}
        close={() => setAlertOpen(false)}
        message={alertMessage}
      />
      <div>
        <div className="main-wrapper">
          <div className="main-content-area">
            <SlickHomebanner
              sliderCurrentPage={sliderCurrentPage}
              bannerCurrentPage={bannerCurrentPage}
            />
            <div>
              <div className="progresive-jackpot">
                <GoldenNestJackpoSection />
              </div>
              <PromotionFeatured />
            </div>
            <div className="featured-slots-container">
              <div className="slots-headlines-container">
                <div className="h2-text-style">
                  <span>Spin Now!</span> <h2>Top Games</h2>
                </div>
                <div className="ProviderListHome"></div>
                <div className="c2a-wrapper">
                  <a href="/slots/top-games/" className="yellow-btn">
                    View More
                  </a>
                </div>
              </div>

              <div
                className="hottest-gamelist"
                style={{ paddingRight: "12px" }}
              >
                <TopGameList limit={12} />
              </div>
            </div>
            <div className="featured-slots-container">
              <div className="slots-headlines-container">
                <div className="h2-text-style">
                  <span>Bet Now!</span> <h2>Hot Live Casino</h2>
                </div>
                <div className="c2a-wrapper">
                  <a href="/live-casino/" className="yellow-btn">
                    View More
                  </a>
                </div>
              </div>
              <div className="hotLDGames">
                <div className="gameLD">
                  <img
                    src="/images/evolution-baccarat.webp"
                    alt="evolution games baccarat"
                  />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "25000",
                        isMobile ? true : false,
                        "en",
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert
                      )
                    }
                  >
                    Play Now
                  </button>
                </div>
                <div className="gameLD">
                  <img
                    src="/images/evolution-crazy-time.webp"
                    alt="Evolution Games Crazy Time"
                  />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "25000",
                        isMobile ? true : false,
                        "en",
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert
                      )
                    }
                  >
                    Play Now
                  </button>
                </div>
                <div className="gameLD">
                  <img
                    src="/images/evolution-baccarat-first-person.webp"
                    alt="Evolution Game First Person Baccarat"
                  />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "25000",
                        isMobile ? true : false,
                        "en",
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert
                      )
                    }
                  >
                    Play Now
                  </button>
                </div>
                <div className="gameLD">
                  <img
                    src="/images/sa-gaming-baccarat.webp"
                    alt="SA Gaming on SG8 Casino"
                  />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "36001",
                        isMobile ? true : false,
                        "en",
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert
                      )
                    }
                  >
                    Play Now
                  </button>
                </div>
                <div className="gameLD">
                  <img
                    src="/images/afb-casino-baccarat.webp"
                    alt="AFB Casino Live on SG8 Casino"
                  />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "74001",
                        isMobile ? true : false,
                        "en",
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert
                      )
                    }
                  >
                    Play Now
                  </button>
                </div>
              </div>
            </div>
            <div className="featured-slots-container">
              <div className="service-selector">
                <div className="services-selector-box">
                  <img
                    src="/images/slot-selector.webp"
                    alt="Best Online Slot in the Philippines at SG8 Casino | JILI Games"
                  />
                  <span>Online Slot</span>
                  <a href="/slots/jili/" className="yellow-btn">
                    Play Now
                  </a>
                </div>
                <div className="services-selector-box">
                  <img
                    src="/images/ld-selector.webp"
                    alt="Real Person Live Casino Live at SG8 Casino"
                  />
                  <span>Live Casino</span>
                  <a href="/live-casino/" className="yellow-btn">
                    Play Now
                  </a>
                </div>
                <div className="services-selector-box calumpit-filter">
                  <img
                    src="/images/sprotsbook-selector.webp"
                    alt="Live Betting Sportsbook at SG8 Casino"
                  />
                  <span>Sportsbook</span>
                  <a href="/sportsbook/" className="yellow-btn">
                    Bet Now
                  </a>
                </div>
              </div>
            </div>
            <ProviderListHome />
          </div>
        </div>
        <div className="main-content-wrapper">
          <div className="page-content-section">
            <Accordion
              title="Why SG8 Casino is the Best Online Casino for Filipinos in 2024"
              previewContent={previewContent}
              fullContent={fullContent}
            />
            <PaymentMethod />
          </div>
        </div>
      </div>
    </>
  )
}

export default App

function AccountSelection() {
  const currentPath = window.location.pathname
  return (
    <div className="account-navigation">
      <div className="fix-container">
        <a
          className={`item-request ${
            currentPath === "/account/request-report/" ? "active" : ""
          }`}
          href="/account/request-report/"
        >
          Report Usage
        </a>
        <a
          className={`item-game ${
            currentPath === "/account/game-report/" ? "active" : ""
          }`}
          href="/account/game-report/"
        >
          Game Report
        </a>
        <a
          className={`item-refer ${
            currentPath === "/account/referral/" ? "active" : ""
          }`}
          href="/account/referral/"
        >
          Referral
        </a>
      </div>
    </div>
  )
}

export default AccountSelection

import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark, faCommentDots } from "@fortawesome/free-solid-svg-icons"
import ContactInfo from "./ContactInfo"
const LiveChat = () => {
  const [showPopup, setShowPopup] = useState(false)
  const [showQuestions, setShowQuestions] = useState(false)
  const [selectedQuestion, setSelectedQuestion] = useState(null)

  const togglePopup = () => {
    setShowPopup(!showPopup)
    setShowQuestions(false) // Reset questions visibility when popup is toggled
    setSelectedQuestion(null) // Reset selected question when popup is toggled
  }

  const toggleQuestions = () => {
    setShowQuestions(!showQuestions)
    setSelectedQuestion(null) // Reset selected question when questions are toggled
  }

  const selectQuestion = (index) => {
    setSelectedQuestion(index)
  }

  const goBack = () => {
    setSelectedQuestion(null)
  }

  const closeQuestions = () => {
    setShowQuestions(false)
  }

  const questions = [
    "How to register?",
    "How to reset my password?",
    "How to deposit funds?",
    "How to withdraw funds?",
    "What games are available?",
    "What are the promotions?",
  ]

  const answers = [
    <div className="answer-container">
      <p className="Answer-Heading">Welcome to SG8 Casino</p>
      <p className="Answer-Content">
        To be eligible, you must be at least 21 years old and not employed as a
        government official or in any government agency.
      </p>
      <p className="Answer-Content">
        Additionally, you should not be a member of the Armed Forces of the
        Philippines or the Philippine National Police.
      </p>
    </div>,
    <div className="answer-container">
      <p className="Answer-Heading">To change password:</p>
      <p className="Answer-Content">
        Click forgot password enter your registered number to receive an OTP.
      </p>
      <p className="Answer-Content">For more assistance contact SG8 Support</p>
      <div className="answer-contact-info">
        <a
          href="https://telegram.me/sg8official_ph"
          target="_new"
          rel="noopener"
        >
          {" "}
          <img
            src="/images/telegram.webp"
            alt="Reach out via telegram on sg8.casino!"
          />
        </a>
        <a href="viber://chat?number=639171042422" target="_new" rel="noopener">
          {" "}
          <img
            src="/images/viber.webp"
            alt="Reach out via telegram on sg8.casino!"
          />
        </a>
        <a href="http://m.me/sg8casinoofficial" target="_new" rel="noopener">
          <img
            src="/images/messenger.webp"
            title="Connect with us"
            alt="Reach out via Messenger on sg8.casino!"
          />
        </a>
      </div>
    </div>,
    <div className="answer-container">
      <p className="Answer-Heading">How to Deposit?</p>
      <ol>
        <li>Click the Deposit Button.</li>
        <li>Select Payment Method.</li>
        <li>Enter Amount.</li>
        <li>Choose Bonus (Optional).</li>
        <li>Click Submit.</li>
      </ol>
      <p className="Answer-Content">For more assistance contact SG8 Support</p>
      <div className="answer-contact-info">
        <a
          href="https://telegram.me/sg8official_ph"
          target="_new"
          rel="noopener"
        >
          {" "}
          <img
            src="/images/telegram.webp"
            alt="Reach out via telegram on sg8.casino!"
          />
        </a>
        <a href="viber://chat?number=639171042422" target="_new" rel="noopener">
          {" "}
          <img
            src="/images/viber.webp"
            alt="Reach out via telegram on sg8.casino!"
          />
        </a>
        <a href="http://m.me/sg8casinoofficial" target="_new" rel="noopener">
          <img
            src="/images/messenger.webp"
            title="Connect with us"
            alt="Reach out via Messenger on sg8.casino!"
          />
        </a>
      </div>
    </div>,
    <div className="answer-container">
      <p className="Answer-Heading">How to Withdraw?</p>
      <ol>
        <li>Click the Withdraw Button.</li>
        <li>Choose Bank Account/E-Wallet.</li>
        <li>Enter Amount.</li>
        <li>Click Submit.</li>
      </ol>
      <p className="Answer-Content">For more assistance contact SG8 Support</p>
      <div className="answer-contact-info">
        <a
          href="https://telegram.me/sg8official_ph"
          target="_new"
          rel="noopener"
        >
          {" "}
          <img
            src="/images/telegram.webp"
            alt="Reach out via telegram on sg8.casino!"
          />
        </a>
        <a href="viber://chat?number=639171042422" target="_new" rel="noopener">
          {" "}
          <img
            src="/images/viber.webp"
            alt="Reach out via telegram on sg8.casino!"
          />
        </a>
        <a href="http://m.me/sg8casinoofficial" target="_new" rel="noopener">
          <img
            src="/images/messenger.webp"
            title="Connect with us"
            alt="Reach out via Messenger on sg8.casino!"
          />
        </a>
      </div>
    </div>,
    <div className="answer-container">
      <p className="Answer-Heading">Play With Us</p>
      <p className="Answer-Content">
        We offer a wide variety of games including{" "}
        <a href="/slots/">Online Slots</a>,{" "}
        <a href="/live-casino/">Live Casino</a>, and{" "}
        <a href="/sportsbook/">Sports Betting</a>.
      </p>
      <p className="Answer-Content">
        Our top providers are <a href="/slots/jili/">JILI Games</a> & Evolution
        Games
      </p>
    </div>,
    <div className="answer-container">
      <p className="Answer-Heading">Promotions</p>
      <ol>
        <li>
          <a href="/promotion/cash-back-bonus/">6% Weekly CashBack</a>
        </li>
        <li>
          <a href="/promotion/deposit-bonus/">150% Deposit Bonus </a>
        </li>
        <li>
          <a href="/promotion/referral-bonus/">10% Friend Referral Bonus</a>
        </li>
        <li>
          <a href="/promotion/payday-bonus/">Payday Bonus</a>
        </li>
        <li>
          <a href="/promotion/birthday-bonus/">Birthday Bonus Get 500 PHP</a>
        </li>
      </ol>
      <a href="/promotion/">
        <button href="" className="btn-question-view-all">
          View All
        </button>
      </a>
      <p className="Answer-Content">For more assistance contact SG8 Support</p>
      <div className="answer-contact-info">
        <a
          href="https://telegram.me/sg8official_ph"
          target="_new"
          rel="noopener"
        >
          {" "}
          <img
            src="/images/telegram.webp"
            alt="Reach out via telegram on sg8.casino!"
          />
        </a>
        <a href="viber://chat?number=639186531060" target="_new" rel="noopener">
          {" "}
          <img
            src="/images/viber.webp"
            alt="Reach out via telegram on sg8.casino!"
          />
        </a>
        <a href="http://m.me/sg8casinoofficial" target="_new" rel="noopener">
          <img
            src="/images/messenger.webp"
            title="Connect with us"
            alt="Reach out via Messenger on sg8.casino!"
          />
        </a>
      </div>
    </div>,
  ]

  return (
    <div>
      <div className="icon-wrapper">
        <div className="icon-faq" onClick={togglePopup}>
          <FontAwesomeIcon icon={faCommentDots} />
        </div>
        <div onClick={toggleQuestions} className="faq-link">
          <div className="tooltip">Hello, Ka Otcho!</div>
        </div>
      </div>
      {showPopup && (
        <div className="overlay-sms">
          <div className="popup">
            <div className="popup-content">
              <span className="close" onClick={togglePopup}>
                <FontAwesomeIcon icon={faXmark} />
              </span>
              <section>
                <div className="footer-flexbox">
                  <p className="footer-headline-text">Contact Information</p>
                  <div className="contact-section mobile-cs">
                    <a href="tel:+639171042422">
                      <img
                        src="/images/phone.webp"
                        title="Connect with us"
                        alt="Reach out via phone or email on sg8.casino!"
                      />
                    </a>
                    <a
                      href="https://telegram.me/sg8official_ph"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/telegram.webp"
                        title="Connect with us"
                        alt="Reach out via telegram on sg8.casino!"
                      />
                    </a>
                    <a href="viber://chat?number=+639171042422">
                      <img
                        src="/images/viber.webp"
                        title="Connect with us"
                        alt="Reach out via viber on sg8.casino!"
                      />
                    </a>
                    <a
                      href="http://m.me/sg8casinoofficial"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/messenger.webp"
                        title="Connect with us"
                        alt="Reach out via Messenger on sg8.casino!"
                      />
                    </a>
                  </div>
                  <div className="contact-section desktop-cs">
                    <ContactInfo />
                  </div>
                </div>
                <div className="footer-flexbox">
                  <p className="footer-headline-text social-media">
                    Social Media
                  </p>
                  <div className="social-section">
                    <a
                      href="https://www.tiktok.com/@sg8_official"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/icons/tiktok.webp"
                        title="Connect with us"
                        alt="Reach out via Tiktok on sg8.casino!"
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/sg8casinoofficial/"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/icons/facebook.webp"
                        title="Connect with us"
                        alt="Reach out via Facebook on sg8.casino!"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/sg8_casino/"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/icons/instagram-2.webp"
                        title="Connect with us"
                        alt="Reach out via Instagram on sg8.casino!"
                      />
                    </a>
                    <a
                      href="https://twitter.com/SG8Official_PH"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/icons/twitter.png"
                        title="Connect with us"
                        alt="Reach out via Twitter on sg8.casino!"
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UClWcUi9HjzqY18a69SjSliw"
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/icons/youtube.webp"
                        title="Connect with us"
                        alt="Reach out via YouTube on sg8.casino!"
                      />
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
      {showQuestions && (
        <div className="Question-Popup">
          <div className="faq-popup">
            <div className="faq-content">
              <span className="close close-question" onClick={closeQuestions}>
                <FontAwesomeIcon icon={faXmark} />
              </span>
              <section>
                <div className="Question-Wrapper">
                  {selectedQuestion === null ? (
                    <>
                      <p className="faqChatBot-text">How can we help you?</p>
                      <ul>
                        {questions.map((question, index) => (
                          <li key={index} onClick={() => selectQuestion(index)}>
                            {question}
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <div className="Answer-Popup">
                      {answers[selectedQuestion]}
                      <button onClick={goBack}>Back</button>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default LiveChat

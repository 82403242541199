import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import {currentPath, currentDomain} from "../../App";

export default function PromotionPage() {
  const title = "Welcome Bonus";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("WelcomeBonus");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("WelcomeBonus");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Play JILI on SG8 Casino | Get Free 300 PHP Credit on Registration</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Join SG8 Casino and play JILI games! REGISTER NOW to claim your FREE 100 PHP credit. Start winning big today!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />
        <h2>
          Welcome Bonus, ₱30 Bonus Credits
        </h2>

        <h2>General Mechanics:</h2>
        <ol>
          <li>This promotion is open to all newly registered player who are <span className="promotion-span-underline-text">21 years old</span> and above.</li>
          <li>This bonus is only available to <span className="promotion-span-underline-text">new players who have registered an account</span> with SG8 for the  first time.</li>
          <li>Upon successful registration, players will automatically receive a <span className="promotion-span-underline-text">Php 30 Welcome Bonus</span> credited to their account.</li>
          <li>The Php 30 bonus itself <span className="promotion-span-underline-text">cannot be withdrawn</span>, but any winnings you earn from using this bonus can be withdrawn after you meet an 7x turnover requirement.</li>
          <li>This bonus can be use on all <span className="promotion-span-underline-text">approved slot games only</span>. This applies to all providers on SG8.</li>
          <li>This bonus can be claimed once by each player.</li>
          <li>The minimal <span className="promotion-span-underline-text">withdrawal is Php100</span>.</li>
        </ol>
        <h2>Terms and conditions:</h2>
        <ol>
          <li>
            All prizes and other marketing costs shall be shouldered by the
            Service Provider – SG8.
          </li>
          <li>General SG8.Casino terms and conditions apply.</li>
          <li>
            SG8.Casino reserves the right to take back any bonuses claimed by
            players in the event that the terms and conditions of the promotion
            are found to have been breached by the player, and/or there is
            reasonable suspicion of foul play.
          </li>
          <li>
            SG8.Casino reserves the right to modify or cancel the promotion at
            any time without prior notice and subject for PAGCOR’s approval.
          </li>
          <li>
            In case of any irregularities or disputes, SG8.Casino’s decision
            will be final. Any issues or concerns should be raised with the
            customer support team, and they will be resolved promptly and
            fairly.
          </li>
        </ol>
      </div>
    </>
  );
}

import React from "react"

// Function to shuffle an array
function shuffleArray(array) {
  let shuffledArray = array.slice()
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]
  }
  return shuffledArray
}

export const jiliDemoList = [
  {
    id: 1,
    gameimg: "/images/superace.webp",
    gametitle: "Super Ace",
    rating: "Ratings : 4.5",
    href: "/jili-games/super-ace/",
  },
  {
    id: 2,
    gameimg: "/images/boxing-king.webp",
    gametitle: "Boxing King",
    rating: "Ratings : 4.7",
    href: "/jili-games/boxing-king/",
  },
  {
    id: 3,
    gameimg: "/images/fortune-gems.webp",
    gametitle: "Fortune Gems",
    rating: "Ratings : 4.6",
    href: "/jili-games/fortune-gems/",
  },
  {
    id: 4,
    gameimg: "/images/money-coming.webp",
    gametitle: "Money Coming",
    rating: "Ratings : 4.4",
    href: "/jili-games/money-coming/",
  },
  {
    id: 5,
    gameimg: "/images/color-game.webp",
    gametitle: "Color Game",
    rating: "Ratings : 4.5",
    href: "/jili-games/color-game/",
  },
  {
    id: 6,
    gameimg: "/images/golden-empire.webp",
    gametitle: "Golden Empire",
    rating: "Ratings : 4.6",
    href: "/jili-games/golden-empire/",
  },
  {
    id: 7,
    gameimg: "/images/mega-ace.webp",
    gametitle: "Mega Ace",
    rating: "Ratings : 4.5",
    href: "/jili-games/mega-ace/",
  },
]

const JiliGamesDemo = ({ hideGames = [] }) => {
  const filteredList = shuffleArray(jiliDemoList)
    .filter((game) => !hideGames.includes(game.gametitle)) // Filter out hidden games
    .slice(0, 5)

  const jiliGamesDemo = filteredList.map((data) => (
    <div key={data.id} className="article-box-games">
      <img src={data.gameimg} alt={`JILI ${data.gametitle}`} />
      <div className="article-game-info">
        <h2>{data.gametitle}</h2>
        <p>{data.rating}</p>
        <a href={data.href}>Game Info</a>
      </div>
    </div>
  ))

  return (
    <>
      <div className="GamesDemo">{jiliGamesDemo}</div>
      <div className="article-view-all-btn">
        <a href="/jili-games/top-jili-games/" className="yellow-btn">
          View All Games
        </a>
      </div>
    </>
  )
}

export default JiliGamesDemo

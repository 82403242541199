import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import Popup from 'reactjs-popup';
import PaymentMethod from './../snippets/PaymentMethod';
import {currentPath, currentDomain} from '../App';

export const tokenURL = process.env.REACT_APP_TOKEN_URL;
export const wlpLaunchURL = process.env.REACT_APP_WLP_LAUNCH_URL;
export const httpsLaunchURL = process.env.REACT_APP_HTTPS_LAUNCH_URL;
export const apiJSONURL = process.env.REACT_APP_API_JSON_URL;
export const totalFrames = 77; // The last frame number

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const launchGame = async (
  url,
  gameId,
  isMobile,
  lang,
  transfer,
  isHttps,
  setLaunching,
  setCurrentFrame,
  setAnimationComplete,
  showAlert
) => {
  let mobile = isMobile ? 1 : 0;
  let username = localStorage.getItem("username");
  let launchURL = url;

  setLaunching(true);
  setCurrentFrame(19);
  setAnimationComplete(false);

  if (document.body.classList.contains("isLoggedIn")) {
    try {
      // const tokenResponse = await axios.post(
      //   apiJSONURL,
      //   `cmd=getToken&u=${username}`
      // );
      //const tokenResult = tokenResponse.data;
      const tokenResult = encodeURIComponent(localStorage.getItem('encryptedUsername'));
      username = tokenResult;

      const gameTokenResponse = await axios.post(
        tokenURL,
        `u=${username}&g=${gameId}&t=${transfer}&m=${mobile}`
      );
      const gameTokenResult = gameTokenResponse.data;
      const sessionToken = gameTokenResult.token;

      if (gameTokenResult.e) {
        showAlert(gameTokenResult.emsg);
        setLaunching(false);
        return;
      }

      launchURL =
        isHttps && !gameId.startsWith("33")
          ? `${httpsLaunchURL}?s=${sessionToken}&l=${lang}`
          : `${wlpLaunchURL}?s=${sessionToken}&l=${lang}`;

      if (isMobile) {
        launchURL += `&m=1&t=1&h=${encodeURIComponent(
          window.location.protocol +
            "//" +
            window.location.hostname +
            window.location.pathname
        )}`;
      }
      if (gameId.startsWith("47")) {
        launchURL += "&I=0";
      }
      if (gameId.startsWith("58") && !isMobile) {
        launchURL += `&h=${encodeURIComponent(
          window.location.protocol +
            "//" +
            window.location.hostname +
            window.location.pathname
        )}`;
      }

      if (!gameId.startsWith("25") && !gameId.startsWith("62")) {
        handleRecentGamesCookie(gameId);
      }

      if (document.querySelector(".gameIframe")?.style.display === "block") {
        document.querySelector(".gameIframe").src = "";
      }

      if (!launchURL) {
        showAlert("Please register and login to play the game.");
      } else {
        handleGameLaunch(launchURL, isMobile, gameId, lang, showAlert);
      }
      setLaunching(false);
    } catch (error) {
      console.error(error);
      setLaunching(false);
    }
  } else {
    if (!username && !launchURL) {
      showAlert("Please register and login to play the game.");
    } else {
      handleGameLaunch(launchURL, isMobile, gameId, lang, showAlert);
    }
  }
};

const handleGameLaunch = (launchURL, isMobile, gameId, lang, showAlert) => {
  if (!isMobile) {
    const win = window.open(launchURL, "popup", "width=1024,height=768");
    if (!win) {
      showAlert("Windows Blocked! Please allow popup to play the game.");
    }
  } else {
    if (gameId.startsWith("62")) {
      const win = window.open(launchURL, "popup", "width=1024,height=768");
      if (!win) {
        showAlert("Windows Blocked! Please allow popup to play the game.");
      }
    } else {
      window.top.location.href = launchURL;
    }
  }
};

const handleRecentGamesCookie = (gameId) => {
  const cookieName = "recentGames";
  let cookies = getCookie(cookieName);
  if (!cookies) {
    setCookie(cookieName, gameId, 30);
  } else {
    if (cookies.includes(gameId)) {
      cookies = cookies.replace(`,${gameId}`, "").replace(`${gameId},`, "");
      cookies = `${gameId},${cookies}`;
    } else {
      cookies = `${gameId},${cookies}`;
      const cookiesArray = cookies.split(",");
      if (cookiesArray.length > 5) {
        cookies = cookiesArray.slice(0, 5).join(",");
      }
    }
    setCookie(cookieName, cookies, 30);
  }
};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

const LiveCasino = () => {
  const [launching, setLaunching] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(19);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const showAlert = (message) => {
    setAlertMessage(message);
    setAlertOpen(true);
  };

  useEffect(() => {
    let interval;
    if (launching) {
      interval = setInterval(() => {
        setCurrentFrame((prevFrame) => {
          if (prevFrame < totalFrames) {
            return prevFrame + 1;
          } else {
            setAnimationComplete(true);
            clearInterval(interval);
            return totalFrames;
          }
        });
      }, 25);
    }
    return () => clearInterval(interval);
  }, [launching]);

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Best Online Live Casino in the Philippines 2024 | SG8 Casino</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Experience the best online live casino in the Philippines in 2024 with SG8 Casino. Play Evolution Games – SA Gaming – AFB Gaming"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <div className="page-headlines-container">
        <div className="pageHeaderText">
          <h1>Ultimate Live Casino</h1>
          <p>Evolution Games – SA Gaming – AFB Gaming</p>
        </div>
      </div>

      <div className="liveCasino">
        <Game
          imgSrc="/images/livedealer/live_dealer01.jpg"
          imgSrc1="/images/livedealer/lcmodel-2.png"
          imgSrc2="/images/livedealer/evolution.png"
          imgBg="/images/livedealer/bg-1.jpg"
          altText="live casino powered by evolution"
          gameId="25000"
          gameName="Evolution"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
        />
        <Game
          imgSrc="/images/livedealer/live_dealer02.jpg"
          imgSrc1="/images/livedealer/lcmodel-3.png"
          imgSrc2="/images/livedealer/sa_gaming.png"
          altText="live casino powered by SA Gaming"
          imgBg="/images/livedealer/bg-2.jpg"
          gameId="36001"
          gameName="SA Gaming"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
        />
        <Game
          imgSrc="/images/livedealer/live_dealer03.jpg"
          imgSrc1="/images/livedealer/lcmodel-1.png"
          imgSrc2="/images/livedealer/afb.png"
          imgBg="/images/livedealer/bg-3.jpg"
          altText="live casino powered by AFB"
          gameId="74001"
          gameName="AFB"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
        />
      </div>

      <div className="main-content-wrapper">
        <div className="text-content-area">
          <h2>Welcome to SG8 Casino Philippines: Gaming Excellence</h2>
          <p>Welcome to SG8 Casino Philippines, your premier destination for online gaming. Dive into thrilling entertainment and lucrative opportunities with our diverse selection of games. Backed by PAGCOR's seal of approval, SG8 Casino guarantees a top-notch gaming experience that will keep you coming back for more. We prioritize your safety and security, with robust measures and advanced encryption technology to keep your information confidential. Our dedicated support team is available 24/7 to assist with any questions or concerns.</p>
          <h2>Thrill of Live Casino and Sports Betting</h2>
          <p>Experience the excitement of our Live Casino, powered by Evolution Gaming. Enjoy classic table games like Baccarat and Blackjack, or engage with live dealers in games like Roulette and Crazy Time. Our high-definition live streaming technology brings the action to you, no matter where you are. We cater to both casual players and high rollers with a wide range of betting limits. Our comprehensive sportsbook, powered by Pinnacle, offers a wide range of betting options for major league matchups and international tournaments. With real-time updates and live betting options, you won't miss a moment of the action. Our intuitive interface makes it easy to navigate and place your bets quickly and securely.</p>
          <h2>Explore Online Slots and Exclusive Promotions</h2>
          <p>Explore our vast selection of online slots, from classic fruit machines to modern video slots with cutting-edge graphics and innovative features. Discover exclusive titles like JILI Slot 777 and popular games from leading providers like RTG. Our regularly updated game library ensures you'll always find something new and exciting. Take advantage of our exclusive promotions and bonuses, designed to enhance your gaming experience and maximize your chances of winning big. Whether you're a new player looking to boost your bankroll or a loyal customer seeking rewards, SG8 Casino has something for everyone. Enjoy generous deposit bonuses, free spins, cashback offers, and more. Our VIP program offers even more perks and rewards. Join us today and experience the thrill of winning big at SG8 Casino, where the fun never stops!</p>
          <PaymentMethod/>
          <div></div>
        </div>
      </div>

      <AlertModal open={alertOpen} close={() => setAlertOpen(false)} message={alertMessage} />
    </>
  );
};

const Game = ({
  imgSrc,
  imgSrc1,
  imgSrc2,
  imgBg,
  altText,
  gameId,
  gameName,
  launchGame,
  launching,
  currentFrame,
  animationComplete,
  setLaunching,
  setCurrentFrame,
  setAnimationComplete,
  showAlert,
}) => {
  const imageRef = useRef(null);
  const isLoggedIn = document.body.classList.contains("isLoggedIn");

  return (
    <>
      <div className="liveCasinoTest">
        {launching && isLoggedIn && (
          <div className="overlay">
            <div className="loadingBar">
              <div className={`spinner ${animationComplete ? "pop" : ""}`}>
                <img
                  ref={imageRef}
                  src={`/images/frameLoading/sg8logo_${String(
                    currentFrame
                  ).padStart(5, "0")}.png`}
                  alt="Loading..."
                />
              </div>
            </div>
          </div>
        )}
        <div className="liveContainer">
          <img className="liveCasinoLogo" src={imgSrc2} alt={altText} />
          <img className="charMain" src={imgSrc} alt={altText} />
          <div className="liveCasinoBtn">
            <div
              className="hidden-xs yellow-btn"
              onClick={() =>
                launchGame(
                  "",
                  gameId,
                  false,
                  "en",
                  0,
                  true,
                  setLaunching,
                  setCurrentFrame,
                  setAnimationComplete,
                  showAlert
                )
              }
            >
              Play Now
            </div>
            <div
              className="visible-xs yellow-btn lcBtn"
              onClick={() =>
                launchGame(
                  "",
                  gameId,
                  true,
                  "en",
                  0,
                  true,
                  setLaunching,
                  setCurrentFrame,
                  setAnimationComplete,
                  showAlert
                )
              }
            >
              Play Now
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const AlertModal = ({ open, close, message }) => (
  <Popup open={open} closeOnDocumentClick onClose={close} modal nested>
    <div className="popupError" style={{ backgroundImage: `url('/images/registration-form-bg.jpg')` }}>
      <img src="/images/live-dealer-mobile-icon.webp" alt="" />
      <div className="content">{message}</div>
      <div className="actions">
        <button className="yellow-btn" onClick={close}>OK</button>
      </div>
    </div>
  </Popup>
);

export default LiveCasino;

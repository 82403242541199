import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import Popup from "reactjs-popup"
const tokenURL = process.env.REACT_APP_TOKEN_URL
const wlpLaunchURL = process.env.REACT_APP_HTTPS_LAUNCH_URL
const apiJSONURL = process.env.REACT_APP_API_JSON_URL

const JiliTopGames = () => {
  const [games, setGames] = useState([])
  const [loading, setLoading] = useState(true)
  const [launching, setLaunching] = useState(false)
  const [currentFrame, setCurrentFrame] = useState(19)
  const [animationComplete, setAnimationComplete] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupMessage, setPopupMessage] = useState("")
  const totalFrames = 77 // The last frame number

  const imageRef = useRef(null)

  useEffect(() => {
    const featuredGamesId = ["60030", "60054", "60040", "60050"]
    const featuredGamesName = [
      "Super Ace",
      "Mega Ace",
      "Boxing King",
      "Fortune Gems",
    ]

    const loadFeaturedGames = () => {
      const featuredGames = featuredGamesId.map((gameId, index) => {
        const gameName = featuredGamesName[index]
        const gameImg =
          gameId === "72052" || gameId === "72096" || gameId === "72059"
            ? `https://sg8.casino/json/resources/images/WLP/${gameId.substring(
                0,
                2
              )}/${gameId}.jpg`
            : `https://sg8.casino/json/resources/images/WLP/${gameId.substring(
                0,
                2
              )}/200x200/${gameId}.jpg`
        return {
          gameId,
          gameName,
          gameImg,
        }
      })
      setGames(featuredGames)
      setLoading(false)
    }

    loadFeaturedGames()
  }, [])

  useEffect(() => {
    let interval
    if (loading || launching) {
      interval = setInterval(() => {
        setCurrentFrame((prevFrame) => {
          if (prevFrame < totalFrames) {
            return prevFrame + 1
          } else {
            setAnimationComplete(true)
            clearInterval(interval)
            return totalFrames
          }
        })
      }, 30) // Adjust the interval to 30 milliseconds for 2x speed
    }
    return () => clearInterval(interval)
  }, [loading, launching])

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent)
  }

  const launchGame = async (gameId) => {
    if (!document.body.classList.contains("isLoggedIn")) {
      setPopupMessage("Please register and login to play the game.")
      setIsPopupOpen(true)
      return
    }

    if (launching) return // Prevent multiple launches
    setLaunching(true)
    setCurrentFrame(19) // Reset the frame
    setAnimationComplete(false) // Reset animation complete
    if (imageRef.current) {
      imageRef.current.classList.remove("pop")
    }

    try {
      const username = localStorage.getItem("username")
      const encryptedUsernameResponse = await axios.post(
        apiJSONURL,
        `cmd=getToken&u=${username}`
      )
      const encryptedUsername = encryptedUsernameResponse.data.token

      const mobileParam = isMobileDevice() ? "&m=1" : ""
      const tokenResponse = await axios.get(
        `${tokenURL}?u=${encryptedUsername}&g=${gameId}&t=transfer${mobileParam}`
      )
      const sessionToken = tokenResponse.data.token

      const lang = "en"
      const url = `${wlpLaunchURL}?s=${sessionToken}&l=${lang}${mobileParam}`
      console.log("Launching game with URL:", url)

      const launchGameAfterAnimation = () => {
        const popupWindow = window.open(
          url,
          "_blank",
          "toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=600"
        )

        if (popupWindow) {
          popupWindow.focus()
        } else {
          setPopupMessage("Please disable your pop-up blocker and try again.")
          setIsPopupOpen(true)
        }

        setLaunching(false)
      }

      if (animationComplete) {
        launchGameAfterAnimation()
      } else {
        setTimeout(() => {
          launchGameAfterAnimation()
        }, (totalFrames - currentFrame) * 30) // Remaining animation time
      }
    } catch (error) {
      setPopupMessage("Failed to launch game.")
      setIsPopupOpen(true)
      setLaunching(false)
    }
  }

  if (loading) {
    return (
      <section className="loadingGames">
        <img
          src="/images/sg8.gif"
          style={{ width: "200px" }}
          alt="Loading..."
        />
      </section>
    )
  }

  const isLoggedIn = document.body.classList.contains("isLoggedIn")
  return (
    <div className="featuredListWrapper">
      {launching && isLoggedIn && (
        <div className="overlay">
          <div className="loadingBar">
            <div className={`spinner ${animationComplete ? "pop" : ""}`}>
              <img
                ref={imageRef}
                src={`/images/frameLoading/sg8logo_${String(
                  currentFrame
                ).padStart(5, "0")}.png`}
                alt="Loading animation"
              />
            </div>
          </div>
        </div>
      )}
      {games.length > 0 ? (
        games.map((game, index) => (
          <div key={index} className="gameWrapper">
            <div className="game" onClick={() => launchGame(game.gameId)}>
              <div className="gameImg">
                <img alt={`${game.gameName}`} src={game.gameImg} />
              </div>
              <div className="gameDetails">
                <div className="gameName">{game.gameName}</div>
                <button className="yellow-btn">Play Now</button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <section className="loadingGames">
          <div>
            <img
              src="/images/sg8-icon.webp"
              style={{ width: "60px" }}
              alt="SG8 Icon"
            />
            <p>No featured games found.</p>
          </div>
        </section>
      )}
      <Popup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        modal
        nested
      >
        <div
          className="popupError"
          style={{ backgroundImage: `url('/images/registration-form-bg.jpg')` }}
        >
          <img src="/images/promo-mobile-icon.webp" alt="" />
          <div className="content">{popupMessage}</div>
          <div className="actions">
            <button
              className="yellow-btn"
              onClick={() => setIsPopupOpen(false)}
            >
              OK
            </button>
          </div>
        </div>
      </Popup>
    </div>
  )
}

export default JiliTopGames

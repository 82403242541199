import React, { useState, useEffect } from 'react';
import { apiJSONURL } from '../pages/LiveCasino';

const CreatePassword = () => {
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (errorMessage || successMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage, successMessage]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const password = document.querySelector('#password').value;
    const confirmPassword = document.querySelector('#confirmpassword').value;

    const passwordError = validatePassword(password);
    if (passwordError) {
      setErrorMessage(passwordError);
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    const encryptedUsername = localStorage.getItem('encryptedUsername');
    
    const formData = new FormData();
    formData.append('cmd', 'changePassword');
    formData.append('u', encryptedUsername);
    formData.append('pwd', password);

    try {
      const response = await fetch(apiJSONURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          cmd: 'changePassword',
          u: encryptedUsername,
          pwd: password
        })
      });

      const data = await response.json();

      if (data.s) {
        setSuccessMessage('Password created successfully');
      } else {
        setErrorMessage('Error creating password');
      }
    } catch (error) {
      setErrorMessage('Error creating password');
    }
  };

  const validatePassword = (password) => {
    if (password.length < 6 || password.length > 8) {
      return 'Password needs to be between 6 - 8 characters.';
    }
    if (!/[a-z]/.test(password) || !/[A-Z]/.test(password)) {
      return '[Strong Password Requirement] Password must contain both uppercase and lowercase characters.';
    }
    if (!/\d/.test(password)) {
      return '[Strong Password Requirement] Password must contain at least one number (eg: 0-9).';
    }
    return null;
  };

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <div className="verificationform">
          <div className="page-h2-text-style" bis_skin_checked="1">
            <span>Create Password</span>
          </div>
          <div className="formrow">
            <label htmlFor="password">Password</label>
            <input 
              type="password" 
              name="password" 
              id="password" 
              maxLength="100" 
              placeholder="Password"
              onChange={handleInputChange}
            />
          </div>
          <div className="formrow">
            <label htmlFor="confirmpassword">Confirm Password</label>
            <input 
              type="password" 
              name="confirmpassword" 
              id="confirmpassword" 
              maxLength="100" 
              placeholder="Confirm Password" 
              onChange={handleInputChange}
            />
          </div>
        </div>
        {errorMessage && <p className="emsg">{errorMessage}</p>}
        {successMessage && <p className="smsg">{successMessage}</p>}
        <div className="formrow">
          <button id="verifyBtn" type="submit">Create Password</button>
        </div>
      </form>
    </div>
  );
}

export default CreatePassword;
import React, { useState, useEffect } from "react"
import Slider from "react-slick"

import PromotionOneCountdown from "./PromotionOneCountdown"
import PromotionTwoCountdown from "./PromotionTwoCountdown"
import PromotionThreeCountdown from "./PromotionThreeCountdown"
import PromoGcash from "./PromoGcash"
import PromoBlackFriday from "./PromoBlackFriday"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default function PromotionFeatured() {
  const [isSliderActive, setIsSliderActive] = useState(window.innerWidth >= 840)

  useEffect(() => {
    const handleResize = () => {
      setIsSliderActive(window.innerWidth >= 840)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: true,
    draggable: true,
  }

  return (
    <div className="promotion-featured">
      {isSliderActive ? (
        <Slider {...sliderSettings}>
          <div>
            <PromotionTwoCountdown />
          </div>
          <div>
            <PromoGcash />
          </div>
          <div>
            <PromotionOneCountdown />
          </div>
          <div>
            <PromotionThreeCountdown />
          </div>
        </Slider>
      ) : (
        <div className="promotion-static">
          <div>
            <PromotionTwoCountdown />
          </div>
          <div>
            <PromoGcash />
          </div>
          <div>
            <PromotionOneCountdown />
          </div>
          <div>
            <PromotionThreeCountdown />
          </div>
        </div>
      )}
    </div>
  )
}

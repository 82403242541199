import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import {currentPath, currentDomain} from "../../App";

export default function MonthlyRollOut() {
  const title = "Monthly Roll Out";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("MonthlyRollOut");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("MonthlyRollOut");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SG8 Monthly Roll Out Promo</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="SG8 Monthly Roll Out Promotion"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />
        <h2>
          SG8 Monthly Roll Out
        </h2>
        <p>October 1, 2024 to December 31, 2024</p>
        <div className="table-main">
        <table>
          <thead>
              <th>
                VIP Tier
              </th>
              <th>
                Rolling Commission (%)
              </th>
          </thead>
          <tbody>
            <tr>
              <td>Bronze</td>
              <td>0.1</td>
            </tr>
            <tr>
              <td>Silver</td>
              <td>0.15</td>
            </tr>
            <tr>
              <td>Gold</td>
              <td>0.2</td>
            </tr>
            <tr>
              <td>Platinum</td>
              <td>0.25</td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2>General Mechanics:</h2>
        <ol>
          <li>
            Every month, these are the specific game provider will participate for the promotion each month
            <div className="table-main">
            <table>
              <thead>
                  <th>
                    Game Provider
                  </th>
                  <th>
                    Month
                  </th>
                  <th>
                    Approved Slot Games and Arcade Games
                  </th>
              </thead>
              <tbody>
                <tr>
                  <td>JILI</td>
                  <td>October</td>
                  <td>Approved Slot Games and Arcade Games</td>
                </tr>
                <tr>
                  <td>FC</td>
                  <td>November</td>
                  <td>Approved Slot Games and Arcade Games</td>
                </tr>
                <tr>
                  <td>PGSOFT</td>
                  <td>December</td>
                  <td>Approved Slot Games</td>
                </tr>
              </tbody>
            </table>
            </div>
          </li>
          <li>
            All SG8 registered players are eligible to participate
          </li>
          <li>
            Players will earn a rolling commission based on their turnover from playing on the specific providers each month (See table above for the providers )<br/>
            Commissions will be based on total turnover from the 1st day of each month, 06:00 AM to the 1st of the next month, 05:59 AM<br/>
            e.g. 1 October 2024 (06:00 AM) to 01 November 2024 (05:59 AM)<br/>
          </li>
          <li>
            The accumulated commission will be credited into the player’s account on the 8th of the next month at 2:00 PM.<br/>
            <b>Sample Computation</b><br/>
            <i style={{color: '#898989'}}>Between 1 October 2024 (06:00 AM) to 1 November 2024 (05:59 AM)</i><br/>
            Total Turnover: Php 100,000<br/>
            <i style={{color: '#898989'}}>Assuming Player Status: Bronze VIP</i><br/>
            <b>Accumulated Commission Calculation:</b><br/>
            Php 100,000 × 0.1% = Php 100<br/>
            <b>Turnover Requirement:</b><br/>
            Php 100 × 8 = Php 800<br/>
            Player will be credited Php 100 commission directly to his/her SG8 wallet on 8 November 2024, 2:00 PM.
          </li>
          <li>
            All accumulated commissions are subject to 8x turnover requirement
          </li>
        </ol>
        <h2>Terms and conditions:</h2>
        <ol>
          <li>All prizes and other marketing costs shall be shouldered by the Service Provider — SG8</li>
          <li>SG8.Casino reserves the right to take back any bonuses claimed by players in the event that the terms and conditions of the promotion are found to have been breached by the player, and/or there is reasonable suspicion of foul play.</li>
          <li>SG8.Casino reserves the right to modify or cancel the promotion at any time without prior notice and subject for PAGCOR's approval.</li>
          <li>In case of any irregularities or disputes, SG8.Casino's decision will be final. Any issues or concerns should be raised with the customer support team, and they will be resolved promptly and fairly.</li>
          <li>All terms and conditions for this marketing promotion apply.</li>
        </ol>
      </div>
    </>
  );
}

import ProviderSlider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useState, useEffect } from "react"

const SlotProviderList = ({ initialProvider, showSlider = true }) => {
  // State to keep track of the active slide
  const [activeSlide, setActiveSlide] = useState(0)

  // Array of provider details with a hidden property
  const providers = [
    {
      id: "pp",
      logo: "/images/slots/small-logo/pp.webp",
      name: "Pragmatic Play",
      href: "/slots/pragmatic/",
      hidden: false,
    },
    {
      id: "rtg",
      logo: "/images/slots/small-logo/rtg-logo-small.webp",
      name: "Real Time Gaming",
      href: "/slots/rtg/",
      hidden: false,
    },
    {
      id: "spadegaming",
      logo: "/images/slots/small-logo/spadegaming-logo-small.webp",
      name: "Spade Gaming",
      href: "/slots/spadegaming/",
      hidden: false,
    },
    {
      id: "jili",
      logo: "/images/slots/jili-logo.webp",
      name: "JILI",
      href: "/slots/jili/",
      hidden: false,
    },
    {
      id: "playstar",
      logo: "/images/slots/small-logo/playstar-logo-small.webp",
      name: "Playstar",
      href: "/slots/playstar/",
      hidden: false,
    },
    {
      id: "bng",
      logo: "/images/slots/bng-logo.webp",
      name: "Booongo",
      href: "/slots/bng/",
      hidden: false,
    },
    {
      id: "playson",
      logo: "/images/slots/small-logo/playson-logo-small.webp",
      name: "Playson",
      href: "/slots/playson/",
      hidden: false,
    },
    {
      id: "netent",
      logo: "/images/slots/small-logo/netent-logo-small.webp",
      name: "NetEnt",
      href: "/slots/netent/",
      hidden: false,
    },
    {
      id: "redtiger",
      logo: "/images/slots/small-logo/red-tiger-logo-small.webp",
      name: "Red Tiger",
      href: "/slots/redtiger/",
      hidden: false,
    },
    {
      id: "nextspin",
      logo: "/images/slots/small-logo/nextspin-logo-small.webp",
      name: "Nextspin",
      href: "/slots/nextspin/",
      hidden: false,
    },
    {
      id: "nolimitcity",
      logo: "/images/slots/small-logo/no-limit-city-logo-small.webp",
      name: "NoLimit City",
      href: "/slots/nlc/",
      hidden: false,
    },
    {
      id: "pgsoft",
      logo: "/images/slots/small-logo/pg-soft-logo-small.webp",
      name: "PG Soft",
      href: "/slots/pgsoft/",
      hidden: false,
    },
    {
      id: "jdb",
      logo: "/images/slots/small-logo/jdb-logo-small.webp",
      name: "JDB",
      href: "/slots/jdb/",
      hidden: false,
    },
    {
      id: "cq9",
      logo: "/images/slots/small-logo/cq9-logo-small.webp",
      name: "CQ9",
      href: "/slots/cq9/",
      hidden: false,
    },
    {
      id: "wazdan",
      logo: "/images/slots/small-logo/wazdan-logo-small.webp",
      name: "Wazdan",
      href: "/slots/wazdan/",
      hidden: false,
    },
    {
      id: "btg",
      logo: "/images/slots/small-logo/btg-logo-small.webp",
      name: "Big Time Gaming",
      href: "/slots/btg/",
      hidden: false,
    },
    {
      id: "tpg",
      logo: "/images/slots/small-logo/tpg-logo-small.webp",
      name: "Triple Profits Gaming",
      href: "/slots/tpg/",
      hidden: false,
    },
    {
      id: "fachai",
      logo: "/images/slots/small-logo/fachai-logo-small.webp",
      name: "FA Chai",
      href: "/slots/fachai/",
      hidden: false,
    },
    {
      id: "yggdrasil",
      logo: "/images/slots/small-logo/ygg-logo-small.webp",
      name: "Yggdrasil",
      href: "/slots/ygg/",
      hidden: false,
    },
    {
      id: "elbet",
      logo: "/images/slots/small-logo/elbet-logo-small.webp",
      name: "Elbet",
      href: "/slots/elbet/",
      hidden: false,
    },
    {
      id: "habanero",
      logo: "/images/slots/small-logo/habanero-logo-small.webp",
      name: "Habanero",
      href: "/slots/habanero/",
      hidden: false,
    },
    // Add other providers here...
  ]

  // Filter out hidden providers
  const visibleProviders = providers.filter((provider) => !provider.hidden)

  // Find the index of the initial provider
  const initialIndex = visibleProviders.findIndex(
    (p) => p.id === initialProvider
  )

  const settings = {
    infinite: true,
    autoplay: false,
    centerMode: true, // Enable center mode
    centerPadding: "70px", // Adjust padding to ensure slides are centered properly
    slidesToShow: 7, // Adjust this number based on your design, better if it's odd
    slidesToScroll: 5,
    arrows: true,
    initialSlide: initialIndex,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  useEffect(() => {
    setActiveSlide(initialIndex)
  }, [initialIndex])

  return (
    <>
      {showSlider && (
        <div className="slot-provider-container">
          <ProviderSlider {...settings}>
            {visibleProviders.map((provider, index) => (
              <div
                className={`slick-provider ${
                  index === activeSlide ? "active" : ""
                }`}
                key={provider.id}
              >
                <a href={provider.href}>
                  <img
                    src={provider.logo}
                    alt={`${provider.name} Logo`}
                    loading="lazy"
                  />
                </a>
              </div>
            ))}
          </ProviderSlider>
        </div>
      )}
      <div className="provider-list-view-all-btn">
        <a href="/slots/" className="yellow-btn providerListBtn">
          View all providers
        </a>
      </div>
    </>
  )
}

export default SlotProviderList

import React from "react"
import Popup from "reactjs-popup"

export default function ContactInfo() {
  return (
    <>
      <Popup
        trigger={
          <a>
            <img
              src="/images/phone.webp"
              title="Connect with us"
              alt="Reach out via phone or email on sg8.casino!"
            />
          </a>
        }
        modal
        nested
      >
        {(close) => (
          <div className="qrModal">
            <button className="close" onClick={close}>
              &times;
            </button>
            <div className="content">
              <img src="/images/qrcode/telephone_qr_code.png" alt="" />
              <div className="icon">
                <img src="/images/qrcode/phone.png" alt="" />
              </div>
            </div>
            <div className="sub-content">
              <p>
                <a href="tel:+639171042422">+639171042422</a>
              </p>
            </div>
          </div>
        )}
      </Popup>
      <Popup
        trigger={
          <a>
            <img
              src="/images/telegram.webp"
              title="Connect with us"
              alt="Reach out via telegram on sg8.casino!"
            />
          </a>
        }
        modal
        nested
      >
        {(close) => (
          <div className="qrModal">
            <button className="close" onClick={close}>
              &times;
            </button>
            <div className="content">
              <img src="/images/qrcode/telegram_qr_code.png" alt="" />
              <div className="icon">
                <img src="/images/qrcode/telegram-icon.png" alt="" />
              </div>
            </div>
            <div className="sub-content">
              <p>
                <a
                  href="https://telegram.me/sg8official_ph"
                  className="yellow-btn"
                >
                  OPEN APP
                </a>
              </p>
            </div>
          </div>
        )}
      </Popup>
      <Popup
        trigger={
          <a>
            <img
              src="/images/viber.webp"
              title="Connect with us"
              alt="Reach out via viber on sg8.casino!"
            />
          </a>
        }
        modal
        nested
      >
        {(close) => (
          <div className="qrModal">
            <button className="close" onClick={close}>
              &times;
            </button>
            <div className="content">
              <img src="/images/qrcode/viber_qr_code.png" alt="" />
              <div className="icon">
                <img src="/images/qrcode/viber.png" alt="" />
              </div>
            </div>
            <div className="sub-content">
              <p>
                <a href="viber://add?number=+639171042422">+639171042422</a>
              </p>
            </div>
          </div>
        )}
      </Popup>
      <Popup
        trigger={
          <a>
            <img
              src="/images/messenger.webp"
              title="Connect with us"
              alt="Reach out via Messenger on sg8.casino!"
            />
          </a>
        }
        modal
        nested
      >
        {(close) => (
          <div className="qrModal">
            <button className="close" onClick={close}>
              &times;
            </button>
            <div className="content">
              <img src="/images/qrcode/messenger_qr_code.png" alt="" />
              <div className="icon">
                <img src="/images/messenger.webp" alt="" />
              </div>
            </div>
            <div className="sub-content">
              <p>
                <a href="http://m.me/sg8casinoofficial">SG8 Casino</a>
              </p>
            </div>
          </div>
        )}
      </Popup>
    </>
  )
}

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {currentPath, currentDomain} from "../App";
import JiliTopGames from "../snippets/JiliFeaturedTopGames";
import ArticleList from "../snippets/ArticleList";
import JiliGamesDemo from "../snippets/JiliGamesDemo";


function JiliXSG8() {
    const hiddenItems = [0];

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SG8 Casino - Get Free ₱30 JILI Slot Bonus - Play Now & Win</title>
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta
                    name="description"
                    content="Ready ka na bang manalo? Get your free JILI Slot bonus sa SG8 Casino. Spin now and hit big jackpots!"
                />
                <link rel="canonical" href={`${currentDomain}${currentPath}`} />
            </Helmet>
            <div className="main-wrapper">
               <div className="hero-article">
                    <img src="/images/jili-x-sg8-official.webp" alt="jiliko" className="desktop-view"/>
                    <img src="/images/mobile-jili-x-sg8-official.webp" alt="jilibet" className="mobile-view"/>
               </div>
            </div>
            <div className="main-content-article">
                <h1>JILI Games at SG8 Casino</h1>
                <h2 className="article-label-text">Your Ultimate Gaming Experience Awaits Here!</h2>
                <p>Are you ready to dive into a world of thrilling gaming? SG8 Casino invites you to explore the dynamic universe of JILI Games, now prominently featured on our platform. Whether you're on desktop or mobile, prepare for a gaming experience filled with thrill and huge potential winnings.</p>
                <p>JILI Games have transformed SG8 Casino's Gaming Experience into a hub of exceptional online gaming action. These expertly designed games immerse you in exciting online slot adventures. JILI Slots stands out in the digital gaming world, offering unmatched excitement for all players. Whether you're experienced or new, JILI Slots guarantees a thrilling experience, making them the top choice in online gaming.</p>
                <div className="">
                    <JiliGamesDemo/>
                </div>
                <h2>Why SG8 Casino is the Top Choice for JILI Games</h2>
                <p>At SG8 Casino, we are dedicated to providing a peerless gaming experience through our <a href="/slots/jili/" className="article-internal-link">JILI Games</a> collection. We offer a variety of online casino games where everyone has an equal chance to win exciting prizes. Our platform ensures a thrilling and rewarding experience, seamlessly working across all devices for smooth gameplay anywhere. This makes our platform the perfect choice for online casino fans.</p>
                <p>SG8 Casino is a great choice for online gaming, especially for playing JILI Games. While many platforms offer casino games, SG8 Casino stands out with its exciting promotions and easy-to-use interface. Here, you can play your favorite JILI Games and enjoy a gaming experience that sets us apart from the rest. This is the best place to enjoy JILI Games and win. </p>
                <p>Our JILI Games utilize a certified Random Number Generator (RNG) to ensure a fair and secure gaming experience. Start your adventure claim PHP30 by registering to SG8 Casino. Secure your account and explore our promotions, like our <a href="/promotion/deposit-bonus/" className="article-internal-link">150% first deposit bonus</a> with up to PHP3888.</p>
                <p>We ensure smooth and easy transactions with convenient payment options like GCash, PayMaya, PayCools, FortunePay, and QR code scanning. With these easy payment options, you can manage your funds effortlessly, so you can focus on playing and winning.</p>
                <p className="article-highlight-text">What are you waiting for? Sign up now and enjoy lots of bonuses!</p>
                <div className="article-thumbnail">
                    <img src="/images/mobile-jili.webp" alt="jili best online casino games" className="article-img-item"/>
                    <img src="/images/jiligamesselection.webp" alt="jili no.1" className="article-img-item"/>
                    <img src="/images/jilixgcash.webp" alt="jili games free" className="article-img-item"/>
                </div>
                <h2>Explore a Variety of our Slots Games</h2>
                <p>Our JILI Slots at SG8 Casino offer a broad spectrum of exciting themes that transport you to various realms. Discover treasures with Jili Caisen, or enjoy high-energy games like Super Ace, Mega Ace, and Boxing King. Play classic favorites like Crazy 777, Fortune Gems, and Money Coming, or dive into vibrant gameplay with Color Games. </p>
                <p className="article-highlight-text">With over 200+ games to choose from, SG8 Casino ensures an thrilling experience for every player.</p>
                <div className="jili-top-games-wrapper">
                    <JiliTopGames />
                    <div>
                        <h2>Best JILI Games You Must Try</h2>
                        <p> <a href="/jili-games/super-ace/" className="article-internal-link">Super Ace</a> offers a thrilling experience with its fast-paced action and dynamic features. Perfect for those who love excitement and quick wins, Super Ace will keep you on the edge of your seat with every spin. Its stunning graphics make every moment exciting, making it a favorite for players.</p>
                        <p> <a href="/jili-games/mega-ace/" className="article-internal-link">Mega Ace</a> is a fun online casino game with vibrant design and exciting gameplay. It offers high payouts and lots of bonuses. Key features include bonus rounds, free spins, and jackpots. Perfect for new players, Mega Ace promises fun and every spin.</p>
                        <p> <a href="/jili-games/boxing-king/" className="article-internal-link">Boxing King</a> combines the thrill of boxing with the excitement of slot gaming. This game has a unique theme and exciting features that make you feel like a champion every time you play. Players enjoy knockout bonuses and powerful payline combinations, making it a thrilling and rewarding experience.</p>
                        <p> <a href="/jili-games/fortune-gems/" className="article-internal-link">Fortune Gems</a> is an exciting online slot game with colorful gemstones and smooth gameplay. It has great graphics and lots of bonuses. Highlights are bonus rounds, multipliers, and free spins, making each spin thrilling. Perfect for both new and experienced players, Fortune Gems offers an adventure with every spin.</p>
                    </div>
                </div>
                <ArticleList hiddenItems={hiddenItems} />
            </div>
        </>
    );
}

export default JiliXSG8;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {currentPath, currentDomain} from "../../App";
import ArticleList from "../../snippets/ArticleList";

export const articleGamesList = [
    {
        id: 0,
        ArticleGameImg: "/images/superace.webp",
        ArticleImgAlt: "superace88",
        ArticleGameTitle: "Super Ace",
        ArticleGameDescription: "Super Ace is an exciting game that mixes the fun of card games with slot machines. With lots of special features, it’s a game that keeps things interesting and rewarding.",
        ArticleLink: "/jili-games/superace/"
    },
    {
        id: 1,
        ArticleGameImg: "/images/fortune-gems.webp",
        ArticleImgAlt: "jilibet",
        ArticleGameTitle: "Fortune Gems",
        ArticleGameDescription: "Try the dazzling world of Fortune Gems, where exciting features make for an engaging experience. Players love the game’s unique bonus mechanics and the chance to win with sparkling gems.",
        ArticleLink: "/slots/jili/"
    },
    {
        id: 2,
        ArticleGameImg: "/images/boxing-king.webp",
        ArticleImgAlt: "jiliko",
        ArticleGameTitle: "Boxing King",
        ArticleGameDescription: "Step into the ring with Boxing King, a game that brings the excitement of boxing to the casino world. With action-packed gameplay and thrilling bonus rounds, it’s a knockout favorite among players.",
        ArticleLink: "/slots/jili/"

    },
    {
        id: 3,
        ArticleGameImg: "/images/golden-empire.webp",
        ArticleImgAlt: "jili no 1",
        ArticleGameTitle: "Golden Empire",
        ArticleGameDescription: "This slot game takes you on a journey to explore the treasures of a forgotten empire. With captivating visuals and exciting bonus rounds, Golden Empire keeps players coming back for more.",
        ArticleLink: "/slots/jili/"

    },
    {
        id: 4,
        ArticleGameImg: "/images/money-coming.webp",
        ArticleImgAlt: "jiliko",
        ArticleGameTitle: "Money Coming",
        ArticleGameDescription: "Money Coming is all about quick thrills and wins. With each spin, you can see your winnings pile up, making it an exciting choice for those who crave instant rewards.",
        ArticleLink: "/slots/jili/"

    },
    {
        id: 5,
        ArticleGameImg: "/images/jili-caishen.webp",
        ArticleImgAlt: "jili philippines",
        ArticleGameTitle: "JILI Caishen",
        ArticleGameDescription: "JILI Caishen brings the blessings of the Chinese god of wealth directly to players. This game offers exciting bonus rounds and plenty of opportunities for winnings.",
        ArticleLink: "/slots/jili/"

    },
    {
        id: 6,
        ArticleGameImg: "/images/crazy-777.webp",
        ArticleImgAlt: "jilibet",
        ArticleGameTitle: "Crazy 777",
        ArticleGameDescription: "Enjoy the classic casino feel with Crazy 777, a slot game that combines traditional elements with modern twists. Its straightforward gameplay and rewarding payouts make it a favorite among players.",
        ArticleLink: "/slots/jili/"

    },
    {
        id: 7,
        ArticleGameImg: "/images/mega-ace.webp",
        ArticleImgAlt: "jilibet",
        ArticleGameTitle: "Mega Ace",
        ArticleGameDescription: "Mega Ace offers a larger-than-life casino experience with its vibrant graphics and exciting features. This game is all about winnings, offering players multiple chances to hit the jackpot.",
        ArticleLink: "/slots/jili/"

    },
]

function WhyFilipinoLoveJili() {
    const hiddenItems = [4];
    const articleGamesBlock = articleGamesList.map((data) => (
        <div className="article-games-flexbox" key={data.id}>
            <img src={data.ArticleGameImg} alt={`SG8 ${data.ArticleImgAlt}`} className=""/>
            <h2>{data.ArticleGameTitle}</h2>
            <p>{data.ArticleGameDescription}</p>
            <a href={data.ArticleLink}>Play Now</a>
        </div>
    ));

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>JILI Games No.1 Choice for Filipino Online Casino Players</title>
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta
                    name="description"
                    content="At SG8 online casino, we offer the best JILI games to all our players. Play Now and have a chance to WIN big!"
                />
                <link rel="canonical" href={`${currentDomain}${currentPath}`} />
            </Helmet>
            <div className="main-wrapper">
               <div className="hero-article">
                    
               </div>
            </div>
            <div className="main-wrapper">
                <div className="hero-article">
                    <img src="/images/filipino-love-jili.webp" className="desktop-view" alt="jiliko"/>
                    <img src="/images/filipino-love-jili-mobile.webp" className="mobile-view" alt="jilibet"/>
                </div>
            </div>
            <div className="main-content-article">
                <h1>Why Filipino Players Love JILI Games</h1>
                <p>If you’re into online casinos and looking for excitement, <a href="/jili-games/" className="article-internal-link">JILI</a> is a great choice. It offers a wide variety of fun games, bonuses, and a safe, reliable gaming experience. Whether you enjoy slots or card games, JILI provides something for everyone, making it a top pick in the Philippines.</p>
                <h2>What Makes JILI Games Special?</h2>
                <p>They offer a wide range of games, from classic slots to new card games. Whether you like spinning reels or card games, JILI has something for you. The games have awesome graphics and sound effects that make them fun to play.</p>
                <div className="article-column">
                    <img src="/images/mobile-jili-x-sg8-official.webp" alt="jili no.1" className="article-img-promotion"/>
                    <div className="article-column-right column-right-1">
                        <p class="listTitle">Easy to Use</p>
                        <p>The platform is super easy to use. Whether you’re on a computer or mobile device, navigating the site is smooth. You can play your favorite games anytime, anywhere, without any hassle.</p>
                        <p class="listTitle">Exciting Bonuses</p>
                        <p>JILI keeps players happy with great bonuses and promotions to boost your chances of winning and make gaming more fun.</p>
                        <p class="listTitle">Safety is a Priority</p>
                        <p>They use top-notch technology to protect your personal and financial info, so you can play with peace of mind. Plus, all games are fair, giving everyone a real chance to win. JILI offers a fun, trustworthy, and rewarding online casino experience.</p>
                    </div>
                </div>
                <h2 className="h2-article-center">Here are the Top 8 JILI Games:</h2>
                <div className="article-games-block">
                    {articleGamesBlock}
                </div>
                <h2>Exciting Bonuses and Promotions</h2>
                <p>JILI isn’t just about great games — it also offers exciting bonuses and promotions to keep the fun going. Players can enjoy free spins on their favorite slots, giving them more chances to win without spending extra money. With its mix of games and fantastic bonuses, JILI ensures that every player has a thrilling and rewarding experience.</p>
            </div>
            <ArticleList hiddenItems={hiddenItems} />
        </>
    );
}

export default WhyFilipinoLoveJili;

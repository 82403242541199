import React, { useState } from "react";

export default function BeerPong({ toggleModal, isOpen }) {

    return (
             <div className={`game-modal ${isOpen ? "show" : ""}`}>
            <button className='modal-close' onClick={toggleModal}>✖</button>
            <div className="game-modal-content prizes">
                <h2>Raffle</h2>
                Prizes: 
                <br/>
                        <table>
                        <tr>
                        <th>Position</th>
                        <th>Prize</th>
                        </tr>
                        <tr>
                        <td>3rd</td>
                         <td> SG8 tote bag <br/> Php 500 Skinny Mikes Voucher <br/> Php 888 SG8 Credit <br/> SG8 Jersey </td>
                        <td><img src="/images/promotion/3rdprize.webp" /></td>
                        </tr>
                        <tr>
                        <td>2nd</td>
                         <td> SG8 tote bag <br/> Php 1000 Skinny Mikes Voucher <br/> Php 1,388 SG8 Credit <br/> SG8 Jersey </td>
                        <td><img src="/images/promotion/2ndprize.webp" /></td>
                        </tr>
                        <tr>
                        <td>1st</td>
                         <td> SG8 tote bag <br/> Php 1,500 Skinny Mikes Voucher <br/> Php 1,888 SG8 Credit <br/> NBA Jersey <br/> SG8 Umbrella </td>
                        <td><img src="/images/promotion/1stprize.webp" /></td>
                        </tr>
                        </table>
            </div>
        </div>
    );
}
import React, { useState, useEffect } from "react"
import BackButton from "../../snippets/BackButton"
import MainSlider from "../../snippets/MainSlider"
import { Helmet } from "react-helmet"
import { currentPath, currentDomain } from "../../App"

export default function ProgressiveJackpot() {
  const title = "Progressive Jackpot"
  const [sliderCurrentPage, setSliderCurrentPage] = useState("GoldenNest")
  const [bannerCurrentPage, setBannerCurrentPage] = useState("GoldenNest")
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 70)
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SG8 Grand Jackpot | Panalo Ka Dito!</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Experience the thrill and excitement at SG8 Grand Jackpot. Panalo Ka Dito! – Your winning moment awaits!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />
        <h2>Goldent Nest Grand Jackpot</h2>
        <p>Panalo ka dito! Spin, Win and Let the Fortune Begin!</p>
        <h2>General Mechanics:</h2>
        <p>
          <strong>Base Jackpot Seed:</strong>
        </p>
        <p>
          ⦁ PHP 1,000 (Once the jackpot is won, it resets to the base seed of
          PHP 1,000 and begins to grow again.)
        </p>
        <p>
          <strong>Qualified Players:</strong> All registered SG8 players
          wagering bets on the qualified games.
        </p>
        <p>
          <strong>Qualification:</strong> Players qualify by betting on{" "}
          <strong>ANY </strong>
          games on the <strong>ELIGIBLE GAME PROVIDERS</strong>.
        </p>
        <h2>Eligible Approved Providers:</h2>
        <ol className="eligible-provider">
          <li>
            <a href="/slots/jili/">
              <img src="/images/icons/JILI.png" alt="" />
            </a>
          </li>
          <li>
            <a href="/slots/bng/">
              <img src="/images/icons/BNG.webp" alt="" />
            </a>
          </li>
          <li>
            <a href="/slots/fachai/">
              <img src="/images/icons/fachai.webp" alt="" />
            </a>
          </li>
          <li>
            <a href="/slots/pgsoft/">
              <img src="/images/icons/PGSOFT.webp" alt="" />
            </a>
          </li>
          <li>
            <a href="/slots/pragmatic/">
              <img src="/images/icons/PRAGMATIC.webp" alt="" />
            </a>
          </li>
        </ol>
        <h2>Progressive Contribution</h2>
        <ol>
          <li>
            <strong>0.5% of every bet on the qualified games</strong> will be
            added to the jackpot seed.
            <p>
              ⦁ For example, if players collectively Bet PHP 100 per bet, 0.5%
              of that (PHP 0.50) will be added to the jackpot seed.
            </p>
          </li>
          <li>
            The jackpot progressively increases daily as long as no one wins.
          </li>
          <li>
            The jackpot can be <strong>randomly triggered</strong> by any bet
            made in the qualified games.
          </li>
          <li>
            The jackpot seed of 0.5% will be fully shouldered by SG8 as the
            sponsoring provider SP-SG8.
          </li>
        </ol>
        <h2>Prize Distribution</h2>
        <ol>
          <li>
            When the jackpot is triggered, the entire prize seed is awarded to
            one lucky player.
          </li>
          <li>
            The prize is credited directly to the winner’s account, and they
            will be notified in their profile account.
          </li>
        </ol>
        <h2>Winner Announcement</h2>
        <ol>
          <li>
            The winner will be announced and notified after the jackpot is
            triggered.
          </li>
        </ol>
        <h2>Jackpot Prize seed example</h2>
        <ol>
          <p>
            <strong>Day 1:</strong>
          </p>
          <li>
            <p>Total Bers: PHP 100,000.</p>
          </li>
          <li>Contribution to Jackpot: 0.5% of PHP 100,000 = PHP 500.</li>
          <li>
            Jackpot seed: PHP 1,000 (base) + PHP 500 (contributions) = PHP
            1,500.
          </li>
        </ol>
        <ol start={1}>
          <p>
            <strong> Day 2 (if not won):</strong>
          </p>
          <li>
            <p>Total Bets: PHP 150,000.</p>
          </li>
          <li>Contribution to Jackpot: 0.5% of PHP 150,000 = PHP 750.</li>
          <li>
            Jackpot seed: PHP 1,500 (previous day) + PHP 750 (contributions) =
            PHP 2,250.
          </li>
        </ol>
        <h2>Terms and conditions</h2>
        <ol>
          <li>
            SG8 fully covers the 0.5% contribution from every wager on qualified
            games to the jackpot seed, as well as all marketing cost.
          </li>
          <li>
            SP reserves the right to take back any bonuses claimed by players in
            the event that the terms and conditions of the promotion are found
            to have been breached by the player, and/or there is reasonable
            suspicion of foul play.
          </li>
          <li>
            SP has the right to modify or cancel the promotion without prior
            notice and is subject to PAGCOR’s approval.
          </li>
          <li>
            SP retains the authority to disqualify a participant from the
            promotion upon observing instances of counter or arbitrage betting.
          </li>
          <li>All terms and conditions for this marketing promotion apply.</li>
        </ol>
      </div>
    </>
  )
}

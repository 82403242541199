import React, { useState, useEffect, useContext } from "react"
import { GlobalContext } from "../snippets/GlobalContextProvider"
import { useTriggerTracker } from "../snippets/TriggerTracker"

const JiliTriggers = () => {
  const { activePopup, setActivePopup } = useContext(GlobalContext)
  const promoApi = process.env.REACT_APP_LEADERBOARD
  const { nJGames } = useContext(GlobalContext)
  const { voucherCode, setVoucherCode } = useContext(GlobalContext)
  const { hasTriggerBeenShown, setTrigger } = useTriggerTracker()
  const [isActive, setIsActive] = useState(false) // New state for active class

  const handleJili = () => {
    setActivePopup("jiliHappyHour")
  }

  useEffect(() => {
    const username = localStorage.getItem("username")

    if (username) {
      const apiUrl = `${promoApi}?cmd=getJiliVoucher&u=${username}`

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          if (data.voucherId) {
            localStorage.setItem("jilihappyhour", "true")
            setVoucherCode(data.voucherId)
            setIsActive(true) // Set active class
          } else {
            localStorage.removeItem("jilihappyhour")
            setIsActive(false) // Remove active class
          }
          console.log(voucherCode)
        })
        .catch((error) => {
          console.error("Error fetching Jili Happy Hour Voucher:", error)
        })
    } else {
      console.error("No username found in local storage.")
    }
  }, [])

  // Non-Jili Trigger
  useEffect(() => {
    if (localStorage.getItem("nJGames") > 2 && activePopup === null) {
      if (!hasTriggerBeenShown("jiliHappyHour")) {
        setActivePopup("nonFeatured")
        setTrigger("jiliHappyHour")
      }
    }
  }, [nJGames])

  const handleClick = () => {
    handleJili()
  }

  return (
    <div className="jilihh-wrapper">
      <div className={`jilihh-container ${isActive ? "active" : ""}`}>
        <a style={{ cursor: "pointer" }} onClick={handleClick}>
          <span id="roCommission">20 Free Spins</span>
        </a>
      </div>
    </div>
  )
}

export default JiliTriggers

import React, { useState } from "react"
import { Helmet } from "react-helmet"
import PaymentMethod from "./../snippets/PaymentMethod"
import { currentPath, currentDomain } from "../App"

export const Seo = (
  <>
    <h2>Welcome to SG8 Casino Philippines: Gaming Excellence</h2>
    <p>
      Welcome to SG8 Casino Philippines, your premier destination for online
      gaming. Dive into thrilling entertainment and lucrative opportunities with
      our diverse selection of games. Backed by PAGCOR's seal of approval, SG8
      Casino guarantees a top-notch gaming experience that will keep you coming
      back for more. We prioritize your safety and security, with robust
      measures and advanced encryption technology to keep your information
      confidential. Our dedicated support team is available 24/7 to assist with
      any questions or concerns.
    </p>
    <h2>Thrill of Live Casino and Sports Betting</h2>
    <p>
      Experience the excitement of our Live Casino, powered by Evolution Gaming.
      Enjoy classic table games like Baccarat and Blackjack, or engage with live
      dealers in games like Roulette and Crazy Time. Our high-definition live
      streaming technology brings the action to you, no matter where you are. We
      cater to both casual players and high rollers with a wide range of betting
      limits. Our comprehensive sportsbook, powered by Pinnacle, offers a wide
      range of betting options for major league matchups and international
      tournaments. With real-time updates and live betting options, you won't
      miss a moment of the action. Our intuitive interface makes it easy to
      navigate and place your bets quickly and securely.
    </p>
    <h2>Explore Online Slots and Exclusive Promotions</h2>
    <p>
      Explore our vast selection of online slots, from classic fruit machines to
      modern video slots with cutting-edge graphics and innovative features.
      Discover exclusive titles like JILI Slot 777 and popular games from
      leading providers like RTG. Our regularly updated game library ensures
      you'll always find something new and exciting. Take advantage of our
      exclusive promotions and bonuses, designed to enhance your gaming
      experience and maximize your chances of winning big. Whether you're a new
      player looking to boost your bankroll or a loyal customer seeking rewards,
      SG8 Casino has something for everyone. Enjoy generous deposit bonuses,
      free spins, cashback offers, and more. Our VIP program offers even more
      perks and rewards. Join us today and experience the thrill of winning big
      at SG8 Casino, where the fun never stops!
    </p>
    <PaymentMethod />
  </>
)

export const BannerNotice = (
  <>
    <div className="banner-text">Gift codes are claimed! Check back soon!</div>
  </>
)

export const promotionList = [
  // {
  //   id: 11,
  //   title: "Black Friday Sale",
  //   desc: "Black Friday Sale",
  //   alt: "Black Friday Sale",
  //   imgSmall: "/images/black-friday-sale-sg8casino-mobile.webp",
  //   imgLarge: "/images/black-friday-sale-sg8casino-mobile.webp",
  //   promoClick: "/promotion/black-friday-sale/",
  //   priority: 0,
  // },
  {
    id: 0,
    title: "JILI 24/7 Spin Frenzy",
    desc: "Get 20 free spins",
    alt: "JILI 24/7 Spin Frenzy",
    imgSmall: "/images/promotion/sg8-jili24-7-frenzy-mobile.webp",
    imgLarge: "/images/promotion/sg8-jili24-7-frenzy-mobile.webp",
    promoClick: "/promotion/jili-247-spin-frenzy/",
    priority: 1,
  },
  {
    id: 1,
    title: "SG8 Monthly Roll Out",
    desc: "Up to .25% Rolling Commission Every Month",
    alt: "SG8 Monthly Roll Out",
    imgSmall: "/images/promotion/sg8-monthly-roll-out-pgsoft-mobile.webp",
    imgLarge: "/images/promotion/sg8-monthly-roll-out-pgsoft-mobile.webp",
    promoClick: "/promotion/monthly-rollout/",
    priority: 1,
  },
  {
    id: 2,
    title: "SG8 | FortunePay Exclusive Deal",
    desc: "Up To 15% Bonus Using FortunePay",
    alt: "Fortunepay Bonus",
    imgSmall: "/images/promotion/sg8-fortunepay-15-bonus-at-sg8-mobile.webp",
    imgLarge: "/images/promotion/sg8-fortunepay-15-bonus-at-sg8-mobile.webp",
    promoClick: "/promotion/fortunepay/",
    priority: 2,
  },
  {
    id: 3,
    title: "Bet & Win",
    desc: "1,000 Winners Every Week",
    alt: "Bet & Win",
    imgSmall: "/images/promotion/bet&win-desktop-1024x544.webp",
    imgLarge: "/images/promotion/bet&win-desktop-1024x544.webp",
    promoClick: "/promotion/bet-and-win/",
    priority: 3,
  },
  {
    id: 4,
    title: "Deposit Bonus",
    desc: "Up to 25% bonus on Deposit",
    alt: "Deposit Bonus",
    imgSmall: "/images/promotion/deposit-bonus-at-sg8-mobile.webp",
    imgLarge: "/images/promotion/deposit-bonus-at-sg8-mobile.webp",
    promoClick: "/promotion/deposit-bonus/",
    priority: 4,
  },
  {
    id: 5,
    title: "Welcome Bonus",
    desc: "Free P30 Credits",
    alt: "Welcome Bonus",
    imgSmall: "/images/promotion/welcome-bonus-30php-at-sg8-mobile.webp",
    imgLarge: "/images/promotion/welcome-bonus-30php-at-sg8-mobile.webp",
    promoClick: "/promotion/welcome-bonus/",
    priority: 5,
  },
  {
    id: 6,
    title: "Daily Cashback",
    desc: "Offer Cashback Up to 6%",
    alt: "Cashback Bonus",
    imgSmall: "/images/promotion/cashback-at-sg8-mobile-1.webp",
    imgLarge: "/images/promotion/cashback-at-sg8-mobile-1.webp",
    promoClick: "/promotion/cash-back-bonus/",
    priority: 6,
  },
  {
    id: 7,
    title: "Payday Bonus",
    desc: "Payday Bonus at SG8 Casino",
    alt: "Payday Bonus",
    imgSmall: "/images/promotion/payday-bonus-at-sg8-mobile.webp",
    imgLarge: "/images/promotion/payday-bonus-at-sg8-mobile.webp",
    promoClick: "/promotion/payday-bonus/",
    priority: 7,
  },
  {
    id: 8,
    title: "Holiyay Special Deals",
    desc: "Get up to PHP 1,500",
    alt: "Holiyay Special Deals",
    imgSmall: "/images/promotion/holiyay-special-deal-at-sg8-mobile.webp",
    imgLarge: "/images/promotion/holiyay-special-deal-at-sg8.webp",
    promoClick: "/promotion/holiyay/",
    priority: 8,
  },
  {
    id: 9,
    title: "Birthday Bonus",
    desc: "Come and Celebrate your Birthday",
    alt: "Birthday Bonus",
    imgSmall: "/images/sg8-birthday-bonus-special-mobile.webp",
    imgLarge: "/images/sg8-birthday-bonus-special-mobile.webp",
    promoClick: "/promotion/birthday-bonus/",
    priority: 9,
  },
  {
    id: 10,
    title: "Referral Bonus",
    desc: "Referral Up to 10%",
    alt: "Referral Bonus",
    imgSmall: "/images/refer-a-friend-at-sg8-mobile.webp",
    imgLarge: "/images/refer-a-friend-at-sg8-mobile.webp",
    promoClick: "/promotion/referral-bonus/",
    priority: 10,
  },
  {
    id: 11,
    title: "Progressive Jackpot",
    desc: "Spin and Win",
    alt: "Progressive Jackpot",
    imgSmall: "/images/goldenNestJackpot-mobile.webp",
    imgLarge: "/images/goldenNestJackpot-mobile.webp",
    promoClick: "/promotion/progressive-jackpot/",
    priority: 0,
  },
  // {
  //   id: 6,
  //   title: "Loyalty Card",
  //   desc: "SG8 Loyalty Card",
  //   alt: "Loyalty Card",
  //   imgSmall: "/images/promotion/loyalty-card-sg8-mobile.jpg",
  //   imgLarge: "/images/promotion/loyalty-card-sg8-mobile.jpg",
  //   promoClick: "/promotion/loyalty-card/",
  //   priority: 9,
  //   classname: "sg8-calumpit-promo"
  // },
]

function Promotion() {
  const [visibleId, setVisibleId] = useState(null)
  let appver
  const activePage = window.location.pathname
  if (activePage === "/app-promotion/" || activePage === "/app-promotion") {
    appver = true
  } else {
    appver = false
  }

  // Sort promotions by priority, highest first
  const sortedPromotionList = [...promotionList].sort(
    (a, b) => a.priority - b.priority
  )

  const listItems = sortedPromotionList.map((data) => (
    <li className={`promoBox ${data.classname || ""}`} key={data.id}>
      <div className="banner-wrapper">
        <img
          className="promotionBannerMobile"
          src={data.imgLarge}
          alt={data.alt}
        />
        <img
          className="promotionBanner"
          src={data.imgSmall}
          alt={data.alt}
          style={
            data.alt === "JILI 24/7 Spin Frenzy"
              ? { filter: "brightness(50%)" }
              : null
          }
        />
        {data.alt === "JILI 24/7 Spin Frenzy" ? BannerNotice : null}
      </div>
      <div className="promotionContent">
        <p className="promotion-title">{data.title}</p>
        <p className="promotion-info">{data.desc}</p>
        <div className="promolink-container">
          <a className="yellow-btn" href={data.promoClick}>
            T&C
          </a>
        </div>
      </div>
    </li>
  ))

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>
          SG8 Casino Promotions | 25% Deposit Bonus & Up to 6% Cashback
        </title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Unlock amazing promotions at SG8 Casino with a 25% deposit bonus and up to 6% cashback. Maximize your gaming potential and start winning today!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <div className="main-wrapper">
        <div className="main-content-area">
          <div className="page-headlines-container">
            <div className="pageHeaderText">
              <h1>SG8 Casino Promotions</h1>
              <p>Maximize your gaming potential and start winning today!</p>
            </div>
          </div>
          <div className="promotionContentarea">
            <ul className="promotionWrapper">{listItems}</ul>
          </div>
        </div>
      </div>

      <div className="main-content-wrapper">
        <div className="text-content-area">
          {appver !== true ? Seo : null}

          <div></div>
        </div>
      </div>
    </>
  )
}

export default Promotion

import React from 'react';
import { checkSchedule } from '../snippets/checkSchedule';
export default function DailyNotif({ handleClose, handleRedirect }) {
    let provider = checkSchedule("2024-12-01T00:00:00") ?'PGSoft': 'Fa Chai'
    
    return (
        <div className="modal trigger-modal">
            <div className="trigger-main-container rolling-commission">
            <button className='modal-close' onClick={handleClose}>✖</button>
            <div className='trigger-image'>
                <img className='desktop' src= {checkSchedule("2024-12-01T00:00:00") ?'/images/promotion/sg8-monthly-roll-out-pgsoft-popup.webp': '/images/rollout-pop-fc.webp'} alt='Get up to .25% rolling commission!'/>
                <img className='mobile' src={checkSchedule("2024-12-01T00:00:00") ?'/images/promotion/sg8-monthly-roll-out-pgsoft-popup.webp': '/images/rollout-pop-fc.webp'} alt='Get up to .25% rolling commission!'/>
            </div>
                
                <div className="modal-content">
                    <h2>Earn Up To <b>.25% Rolling Commission</b></h2>
                    <p>Play any <b>{provider}</b> Game to start earning!</p>
                    <button className="yellow-btn" onClick={handleRedirect}>
                    Click here to play
                    </button>
                </div>
            </div>
        </div>
    );
}
import React, { useState } from "react";

export default function BeerPong({ toggleModal, isOpen }) {

    return (
             <div className={`game-modal ${isOpen ? "show" : ""}`}>
            <button className='modal-close' onClick={toggleModal}>✖</button>
            <div className="game-modal-content">
                <h2>Beer Pong</h2>
                <ol type="1">
                    <li>To play for the first time, all registered players of SG8 casino at Skinny Mike are entitled to play. </li>
                    <li>For subsequent rounds, players must deposit into their registered SG8 account. Deposit amounts will follow PAGCOR regulations.</li>
                    <li>Participants will have to show proof of registration/deposit to Skinny Mike staff present to be given the ball</li>
                    <li>Each participant will get 3 tries.
                    <br/>BONUS: If bet on sports, given 3 additional tries (ie. total 6 tries) </li>
                    <li>Cups will be arranged in a typical beer-pong fashion. Each row represents different prizes
                        <br/>
                        <ol>
                            <li>First row: Free Beer </li>
                            <li>Second row: 5 raffle tickets </li>
                            <li>Third to fourth row: Spin the Wheel </li>
                        </ol>
                    </li>
                    <li>Prizes will be provided for each try
                    <br/>
                        <ol>
                            <li>- i.e. if all 3 balls can land into a cup, player will receive the 3 respective prizes  </li>
                        </ol> 
                    </li>
                </ol>
            </div>
        </div>
    );
}
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import ProviderListHome from "../../snippets/ProviderListHome";
import PaymentMethod from "../../snippets/PaymentMethod";
import { currentPath, currentDomain } from "../../App";

export const slotsBox = [
  {
    id: "rtg",
    bgImg: "/images/slots/hero/rtg-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/rtg-logo-small.webp",
    btn: "/slots/rtg/",
    newGame: false,
    topGame: true,
    hidden: false,
    order: 0,
  },
  {
    id: "spades",
    bgImg: "/images/slots/hero/spadegaming-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/spadegaming-logo-small.webp",
    btn: "/slots/spadegaming/",
    newGame: false,
    topGame: false,
    hidden: false,
  },
  {
    id: "jili",
    bgImg: "/images/slots/hero/jili-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/jili-logo-small.webp",
    btn: "/slots/jili/",
    newGame: false,
    topGame: true,
    hidden: false,
  },
  {
    id: "bng",
    bgImg: "/images/slots/hero/bng-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/bng-logo-small.webp",
    btn: "/slots/bng/",
    newGame: false,
    topGame: false,
    hidden: false,
    order: 0,
  },
  {
    id: "playstar",
    bgImg: "/images/slots/hero/playstar-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/playstar-logo-small.webp",
    btn: "/slots/playstar/",
    newGame: false,
    topGame: false,
    hidden: false,
    order: 0,
  },
  {
    id: "playson",
    bgImg: "/images/slots/hero/playson-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/playson-logo-small.webp",
    btn: "/slots/playson/",
    newGame: false,
    topGame: false,
    hidden: false,
    order: 0,
  },
  {
    id: "netent",
    bgImg: "/images/slots/hero/netent-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/netent-logo-small.webp",
    btn: "/slots/netent/",
    newGame: false,
    topGame: false,
    hidden: false,
    order: 0,
  },
  {
    id: "nextspin",
    bgImg: "/images/slots/hero/nextspin-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/nextspin-logo-small.webp",
    btn: "/slots/nextspin/",
    newGame: false,
    topGame: false,
    hidden: false,
    order: 0,
  },
  {
    id: "habanero",
    bgImg: "/images/slots/hero/habanero-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/habanero-logo-small.webp",
    btn: "/slots/habanero/",
    newGame: true,
    topGame: false,
    hidden: false,
    order: 2,
  },
  {
    id: "nlc",
    bgImg: "/images/slots/hero/nlc-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/no-limit-city-logo-small.webp",
    btn: "/slots/nlc/",
    newGame: false,
    topGame: false,
    hidden: false,
    order: 0,
  },
  {
    id: "fachai",
    bgImg: "/images/slots/hero/fachai-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/fachai-logo-small.webp",
    btn: "/slots/fachai/",
    newGame: false,
    topGame: false,
    hidden: false,
    order: 0,
  },
  {
    id: "btg",
    bgImg: "/images/slots/hero/btg-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/btg-logo-small.webp",
    btn: "/slots/btg/",
    newGame: false,
    topGame: false,
    hidden: false,
    order: 0,
  },
  {
    id: "elbet",
    bgImg: "/images/slots/hero/elbet-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/elbet-logo-small.webp",
    btn: "/slots/elbet/",
    newGame: true,
    topGame: false,
    hidden: false,
    order: 3,
  },
  {
    id: "pgsoft",
    bgImg: "/images/slots/hero/pgsoft-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/pg-soft-logo-small.webp",
    btn: "/slots/pgsoft/",
    newGame: false,
    topGame: false,
    hidden: false,
    order: 0,
  },
  {
    id: "ygg",
    bgImg: "/images/slots/hero/ygg-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/ygg-logo-small.webp",
    btn: "/slots/ygg/",
    newGame: false,
    topGame: false,
    hidden: false,
    order: 0,
  },
  {
    id: "tpg",
    bgImg: "/images/slots/hero/tpg-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/tpg-logo-small.webp",
    btn: "/slots/tpg/",
    newGame: false,
    topGame: false,
    hidden: false,
    order: 0,
  },
  {
    id: "redtiger",
    bgImg: "/images/slots/hero/redtiger-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/red-tiger-logo-small.webp",
    btn: "/slots/redtiger/",
    newGame: false,
    topGame: false,
    hidden: false,
    order: 0,
  },
  {
    id: "cq9",
    bgImg: "/images/slots/hero/cq9-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/cq9-logo-small.webp",
    btn: "/slots/cq9/",
    newGame: false,
    topGame: false,
    hidden: false,
    order: 0,
  },
  {
    id: "wazdan",
    bgImg: "/images/slots/hero/wazdan-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/wazdan-logo-small.webp",
    btn: "/slots/wazdan/",
    newGame: false,
    topGame: false,
    hidden: false,
    order: 0,
  },
  {
    id: "jdb",
    bgImg: "/images/slots/hero/jdb-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/jdb-logo-small.webp",
    btn: "/slots/jdb/",
    newGame: false,
    topGame: false,
    hidden: false,
    order: 0,
  },
  {
    id: "pragmatic",
    bgImg: "/images/slots/hero/pragmatic-play-at-sg8-mobile.webp",
    logoImg: "/images/slots/small-logo/pp.webp",
    btn: "/slots/pragmatic/",
    newGame: true,
    topGame: false,
    hidden: false,
    order: 1,
  },
];

function Slots() {
  const [sliderCurrentPage, setSliderCurrentPage] = useState("all");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("all");

  // Sort the slotsBox array to put top games first, then new games by order, then alphabetically by 'id'
  slotsBox.sort((a, b) => {
    if (a.newGame !== b.newGame) {
      return a.newGame ? -1 : 1;
    }
    if (a.topGame !== b.topGame) {
      return a.topGame ? -1 : 1;
    }
    if (a.newGame && b.newGame) {
      return a.order - b.order;
    }
    return a.id.localeCompare(b.id);
  });

  // Filter out hidden providers before mapping
  const slotItem = slotsBox
    .filter((data) => !data.hidden)
    .map((data) => (
      <div
        className="slotsBox"
        style={{ backgroundImage: `url(${data.bgImg})` }}
        key={data.id}
      >
        {data.topGame && <div className="top-label">Top</div>}
        {data.newGame && <div className="new-label">New</div>}
        <img src={data.logoImg} alt={`${data.id} logo`} />
        <a href={data.btn} className="yellow-btn slots-selector-btn">
          Play Now
        </a>
      </div>
    ));

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SG8 Casino Online Slots | Best Slot Games 2024</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Discover the best online slot games of 2024 at SG8 Casino. Enjoy high-quality slots, innovative gameplay, and thrilling wins. Join now and start playing!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="main-wrapper">
        <div className="main-content-area">
          <MainSlider
            sliderCurrentPage={sliderCurrentPage}
            bannerCurrentPage={bannerCurrentPage}
          />
          <ProviderListHome />
          <div className="slotsProviderWrapper">{slotItem}</div>
        </div>
      </div>
      <div className="main-content-wrapper">
        <div className="text-content-area">
          <h2>Online Slots at SG8 Casino</h2>
          <p>
            Experience the best online slots at SG8 Casino, where Filipino
            players can enjoy a secure and fair gaming environment regulated by
            PAGCOR. Our extensive collection of online slots offers a thrilling
            gaming experience from the comfort of your home. Enjoy seamless
            gameplay, high-quality graphics, and the chance to win big with
            every spin. SG8 Casino is dedicated to providing a top-tier gaming
            experience that keeps you entertained and engaged.
          </p>
          <h2>Play JILI Games, RTG Slots & More</h2>
          <p>
            SG8 Casino features a wide range of slots from over 20 top
            providers, including popular games from JILI, RTG, PG Soft, Fa Chai,
            and NetEnt. These providers are known for their engaging gameplay
            and stunning graphics, making every gaming session exciting. Explore
            our selection of games from these providers and many more to
            discover new favorites. Whether you prefer classic themes,
            innovative features, or high payout potentials, there's something
            for everyone at SG8 Casino. Our platform ensures that you have
            access to the latest and most popular slots in the industry.
          </p>
          <h2>Exclusive Promotions and Bonuses</h2>
          <p>
            Maximize your wins with our exclusive promotions and bonuses. SG8
            Casino offers a variety of incentives designed to enhance your
            online slot experience, giving you more chances to win. From welcome
            bonuses to daily promotions and loyalty rewards, we provide
            opportunities to boost your gameplay and increase your chances of
            hitting the jackpot. Join SG8 Casino today, take advantage of our
            special offers, and start your online slot adventure. Your next big
            win is just a spin away! Enjoy the thrill of online slots with SG8
            Casino's rewarding and exciting promotions.
          </p>
          <PaymentMethod />
        </div>
      </div>
    </>
  );
}

export default Slots;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {currentPath, currentDomain} from "../App";
import ArticleList from "../snippets/ArticleList";
import JiliGamesDemo from "../snippets/JiliGamesDemo";

export const guideList = [
    {
        id: 0,
        ListTitle: "Register an Account",
        ListDescription: "Visit our official website & sign up for an account (Must have a fully verified phone number).",
    },
    {
        id: 1,
        ListTitle: "Verify Your Identity",
        ListDescription: "Security and trust are our top priorities. To ensure a safe gaming environment, complete the KYC process (Send a selfie holding your valid ID).",
    },
    {
        id: 2,
        ListTitle: "Wait for Approval",
        ListDescription: "Our team will review your KYC and notify once your account is verified.",
    },
    {
        id: 3,
        ListTitle: "Start Playing at SG8",
        ListDescription: "With your account verified, you can start playing now. Browse the wide variety of JILI games available at SG8 Casino.",
    },
]


function How2PlaySG8Casino() {
    const hiddenItems = [1];
    const stepsGuideList = guideList.map((data) => (
        <div className="guideList" key={data.id}>
            <p className="listTitle">{data.ListTitle}</p>
            <p className="listDescription">{data.ListDescription}</p>
        </div>
    ));
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>How to Play JILI Games on SG8 Casino - Start Winning Now!</title>
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta
                    name="description"
                    content="Join SG8 Casino today and dive into the world of JILI Games. Start playing now and see if you can hit the jackpot!"
                />
                <link rel="canonical" href={`${currentDomain}${currentPath}`} />
            </Helmet>
            <div className="main-wrapper">
               <div className="hero-article">
                    
               </div>
            </div>
            <div className="main-content-article">
                <h1>How to play JILI on SG8 Casino</h1>
                <h2 className="article-label-text">Start Winning Today!</h2>
                <p>Play JILI games on SG8 Casino with top-rated online casino games, easy payment methods, and great bonuses. SG8 Casino offers a wide range of JILI Games with high-quality graphics and exciting gameplay. Our platform is user-friendly, making it easy for both new and experienced players to navigate. Enjoy our fantastic bonuses, including welcome bonus and cashback deals, to boost your gaming experience. </p>
                <p className="article-highlight-text">Start playing JILI Games at SG8 Casino and increase your chances of winning.</p>
                <h2>Step-by-Step Guide to Playing at SG8 Casino</h2>
                <div className="article-column">
                    <img src="/images/promotion/welcome-bonus-30php-at-sg8-mobile.webp" alt="jili no.1" className="article-img-promotion"/>
                    <div className="article-column-right column-right-1">{stepsGuideList}</div>
                </div>
                <h2>Compliance Standards</h2>
                <p>Players must be at least 21 years old to participate. Government employees, officials, and anyone working for a government agency cannot play.</p>
                <p>Additionally, you must have a valid ID and complete our Know Your Customer (KYC) verification to start playing. We are committed to promoting <a href="/responsible-gaming/" className="article-internal-link">responsible gaming</a> to ensure a safe and enjoyable experience for all players. Verify your mobile number to access all features and promotions.</p>
                <JiliGamesDemo/>
                <h2 className="h2-headline">Why Choose SG8 Casino?</h2>
                <div className="article-column">
                    <div className="article-column-right">
                        <h2>Top-Rated Games</h2>
                        <p>At SG8 Casino, our online casino games are top-rated and among the best in the market. We offer a wide selection of online slots games that provide thrilling experiences and great rewards.</p>
                        <h2>Easy Payment Methods</h2>
                        <p>SG8 Casino offers easy payment methods to ensure a seamless and convenient gaming experience. Players can choose from secure options like credit cards, e-wallets, and bank transfers for quick deposits and withdrawals.</p>
                        <h2>Exciting Promotions at SG8 Casino</h2>
                        <p>Receive a 30 PHP Welcome Bonus after registration and a 150% Deposit Bonus on your first deposit. Earn 6% Cashback on your losses and up to 10% Referral Bonus by inviting friends to join.</p>
                        <h2>Licensed by PAGCOR</h2>
                        <p>SG8 Casino, licensed by PAGCOR, guarantees a safe and regulated gaming environment. Our PAGCOR license guarantees that we adhere to the highest standards of fairness and security.</p>
                        <h2>Philippines Top Online Casino</h2>
                        <p>Top online casino in the philippines, our platform is easy to use delivering a smooth and thrilling gaming experience. We cater to your preferences and needs, ensuring top-notch entertainment and satisfaction every time you play.</p>
                        <p className="article-highlight-text">Join SG8 Casino today and dive into the exciting world of JILI games on a fully licensed and secure platform.</p>
                    </div>
                    <img src="/images/jiligamespagcor.webp" alt="jili pagcor" className="article-img-item"/>
                </div>
            </div>
            <ArticleList hiddenItems={hiddenItems} />
        </>
    );
}

export default How2PlaySG8Casino;

import React, { useContext } from 'react';
import { GlobalContext } from '../snippets/GlobalContextProvider';
import JiliCountdown from '../snippets/JiliCountdown';

export default function JiliHappyHourModal({ voucherCode }) {
    const { activePopup, setActivePopup } = useContext(GlobalContext);

    const handleClose = () => {
        setActivePopup(null);
    };

    const handleRedirect = () => {
        setActivePopup(null);
        window.location.href = "/slots/jili/";
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(voucherCode);
    };

    const isQualified = localStorage.getItem('jilihappyhour');

      
    const handleRedirectTnC = () => {
        setActivePopup(null);
        window.location.href = "/promotion/jili-247-spin-frenzy/";
      };

    return (
        <div className="modal trigger-modal">
            <div className="trigger-main-container jilihappyhour">
                <button className="verification-close modal-close" onClick={handleClose}>✖</button>
                <div className="modal-content">
                    <h2>JILI 24/7 Spin Frenzy</h2>
                    {isQualified ? (
                        <>
                            <span className="voucher-label"><b>Redeem your 20 free spins</b></span>
                            <div className="voucher-container">
                                <p className="voucher-code">{voucherCode}</p>
                                <button className="copy" onClick={handleCopy}>Copy</button>
                            </div>
                        </>
                    ) : (
                        <div className="notqualified-happyhour">Reach 500x Spin on <b>JILI</b> to get <b>20 Free Spins</b>!</div>
                    )}
                    <button className="yellow-btn" onClick={handleRedirect}>Play Now!</button>
                    <h3>Guide on how to use the JILI Gift Code:</h3>
                    <ol>
                        <li>I-click ang “Settings” button.</li>
                        <li>Hanapin at piliin ang “Gift Box” icon.</li>
                        <li>Ilagay ang inyong gift code at i-click ang “Confirm.”</li>
                        <li>Claim your <b>20 FREE spins</b> at i-enjoy ang Ultimate Gaming Experience!</li>
                    </ol>
                    <img className="guideimage desktop-view" src="/images/steps.webp" alt="Guide Steps" />
                    <img className="guideimage mobile-view" src="/images/steps-mobile.webp" alt="Guide Steps Mobile" />
                    <a href="/promotion/jili-247-spin-frenzy/" className="view-full-tnc">View Full Terms and Condition</a>
                </div>
            </div>
        </div>
    );
}


import React from 'react'

export default function PageNotFound() {
  return (
    <>
        <main className='pageNotFound'>
           <div>
                <h1>404 PAGE NOT FOUND</h1>
                <h5>The page you are looking for may have been moved, deleted or possibly never existed.</h5>
                <img src="/images/SG8-logo.png" alt="" />
                <div class="notFoundBtn">
                  <a href="/" className="yellow-btn">Home</a>
                </div>
           </div>
        </main>
    </>
  )
}

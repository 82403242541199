import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import {currentPath, currentDomain} from "../../App";

export default function Holiyay() {
  const title = "HoliYAY Deals";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("Holiyay");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("Holiyay");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SG8 Casino HoliYAY Special Deals | Get Up to PHP 1,500</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Unwrap the joy of gaming this festive season with SG8 Casino! Our exclusive HoliYAY Special Deal offers you a chance to boost your play with up to PHP 1,500. Dive into a world of thrilling games and big wins, all wrapped up with holiday cheer. "
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />

        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />
        <h2>Holiyay Special Deals</h2>
        <h2>General Mechanics: </h2>
        <div className="table-main">
          <table>
            <thead>
              <th>Total Accumulated Deposit</th>
              <th>Free Credits (₱)</th>
            </thead>
            <tbody>
              <tr>
                <td>1,000 - 4,999</td>
                <td>₱10</td>
              </tr>
              <tr>
                <td>5,000 - 9,999</td>
                <td>₱55</td>
              </tr>
              <tr>
                <td>10,000 - 19,999</td>
                <td>₱120</td>
              </tr>
              <tr>
                <td>20,000 - 49,999</td>
                <td>₱260</td>
              </tr>
              <tr>
                <td>50,000 - 99,999</td>
                <td>₱700</td>
              </tr>
              <tr>
                <td>100,000 and above</td>
                <td>₱1,500</td>
              </tr>
            </tbody>
          </table>
        </div>
        <ol>
          <li>Players who deposit can receive a bonus on the specified claiming date, with the bonus value determined by their total deposits during the designated Holiday period.</li>
          <li>This promotion is open to all new and old registered players of SG8.</li>
          <li>Players can only claim one free credit bonus per promotional period. Crediting of prize will happen at said holiday</li>
        </ol>
        <div className="table-main">
          <table>
            <thead>
              <th>Holiday</th>
              <th>Promotion Period</th>
              <th>Claiming Date</th>
            </thead>
            <tbody>
              <tr>
                <td>National Heroes Day</td>
                <td>August 11 - August 25</td>
                <td>August 26</td>
              </tr>
              <tr>
                <td>Bonifacio Day</td>
                <td>November 15 - November 29</td>
                <td>November 30</td>
              </tr>
              <tr>
                <td>Rizal Day</td>
                <td>December 15 - December 29</td>
                <td>December 30</td>
              </tr>
            </tbody>
          </table>
        </div>
        <ol start="4">
          <li>Only the total deposits accumulated within the specified covered period is eligible for the credit bonus.</li>
          <li>The bonus amount must undergo a 1x rollover requirement</li>
          <li>Before withdrawing, players must fulfill the rollover requirement</li>
        </ol>
        <h2>Terms and conditions:</h2>
        <ol>
            <li>All prizes and other marketing costs shall be shouldered by the Service Provider - SG8</li>
            <li>SP reserves the right to take back any bonuses claimed by players in the event that the terms and conditions of the promotion are found to have been breached by the player, and/or there is reasonable suspicion of foul play</li>
            <li>SP reserves the right to modify or cancel the promotion at any time without prior notice and subject for PAGCOR's approval</li>
            <li>SP retains the authority to disqualify a participant from the promotion upon observing instances of counter or arbitrage betting</li>
        </ol>
      </div>
    </>
  );
}

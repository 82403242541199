import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from "react-helmet";
import {currentPath, currentDomain} from '../../App';
import CouponRedeem from '../../snippets/CouponRedeem';
import BeerPong from '../../Modal/BeerPong';
import SpinTheWheel from '../../Modal/SpinTheWheel';
import Darts from '../../Modal/Darts';
import Raffle from '../../Modal/Raffle';
import { GlobalContext } from '../../snippets/GlobalContextProvider';

function SkinnyMikes() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check localStorage for 'isLoggedIn'
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    if (loggedInStatus === 'true') {
      setIsLoggedIn(true);
    }
  }, []);
const {activeModal,setActiveModal} = useContext(GlobalContext);
const [isOpen, setIsOpen] = useState(false);

const toggleModal = () => {
    setIsOpen(!isOpen);
  };


  const handleImageClick = (game) => {
    setActiveModal(game);
    setIsOpen(!isOpen);
    console.log(game);
  };
  
  const handleRegisterClick = () => {
  console.log('Button clicked!');

  const regBtnElement = document.querySelector('.reg-btn');
    if (regBtnElement) {
      regBtnElement.click();
    }
  };


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Skinny Mikes x SG8 | Top Online Casino 2024</title> 
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta name="description" content="Explore the top online slot games of 2024 with SG8 Casino. Enjoy premium slots and exciting gameplay. Play today"/>
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      {activeModal === 'beerPong' && <BeerPong toggleModal={toggleModal} isOpen={isOpen}/>}
      {activeModal === 'spinTheWheel' && <SpinTheWheel toggleModal={toggleModal} isOpen={isOpen}/>}
      {activeModal === 'darts' && <Darts toggleModal={toggleModal} isOpen={isOpen}/>}
      {activeModal === 'raffle' && <Raffle toggleModal={toggleModal} isOpen={isOpen}/>}
      <div className="main-wrapper">
    <div className='skinny-mikes-promotion' style={{backgroundImage: 'url("/images/sm-bg.webp")', width: '100%', backgroundSize: 'cover', backgroundPosition: 'center', marginTop: '-50px', paddingTop: '50px', paddingBottom: '50px'}}>
        <img className='logo' src='/images/promotion/skinnymikesxsg8-logo.png'/>
        <div className='coupon-area'  style={{backgroundImage: 'url("/images/mainbg.webp")', backgroundSize: 'cover'}}>
          <img className='sm-title' src='/images/ugne.webp'/>
          {isLoggedIn ? (
            <CouponRedeem />
          ) : (
            <button className='coupon-redeem-button register-btn' onClick={handleRegisterClick}>
              Register
            </button>
          )}
        </div>
        {/* Game mechanics */}
        <div className='game-mechanics-container'>
          <div className='game-mechanics-box' onClick={() => handleImageClick("beerPong")}>
            <img className='sm-games' src='/images/beer-pong.webp'/>
            <span>Beer Pong</span>
          </div>
          <div className='game-mechanics-box' onClick={() => handleImageClick("spinTheWheel")}>
            <img className='sm-games' src='/images/roullette.webp'/>
            <span>Spin The wheel</span>
          </div>
          <div className='game-mechanics-box' onClick={() => handleImageClick("darts")}>
            <img className='sm-games' src='/images/darts.webp'/>
            <span>Darts</span>
          </div>
        </div>
          <div className='prizes' onClick={() => handleImageClick("raffle")}>
          Raffle Prizes
          </div>
      </div>
      </div>
    </>
  );
}

export default SkinnyMikes;

import React, { useState, useContext } from 'react';
import JiliCountdown from '../snippets/JiliCountdown';
import { GlobalContext } from '../snippets/GlobalContextProvider';

export default function JiliHappyHourGame() {
    const {nJGames, setNJGames} = useContext(GlobalContext);
    const {activePopup, setActivePopup} = useContext(GlobalContext);

    const handleClose = () => {
        localStorage.setItem("nJGames", 0);
        setNJGames(0);
        setActivePopup(null); 
      };

      const handleRedirect = () => {
        localStorage.setItem("nJGames", 0);
        setNJGames(0);
        setActivePopup(null); 
        window.location.href = "/slots/jili/";
      };
    return (
        <div className="modal trigger-modal">
            <div className="trigger-main-container jilihappyhour">
              <button className='verification-close modal-close' onClick={handleClose}>✖</button>
                <div className="modal-content">
                  <h2>Join the JILI 24/7 Spin Frenzy!</h2>
                  <p>Reach <b>500x Spin </b>on <b>JILI!</b> to get <b>20 Free Spins</b></p>
                  <button className="yellow-btn" onClick={handleRedirect}>Play Now!</button>
                    <h3>Guide on how to use the JILI Gift Code:</h3>
                    <ol>
                    <li>I-click ang “Settings” button.</li>
                        <li>Hanapin at piliin ang “Gift Box” icon.</li>
                        <li>Ilagay ang inyong gift code at i-click ang “Confirm.”</li>
                        <li>Claim your <b>20 FREE spins</b> at i-enjoy ang Ultimate Gaming Experience!</li>
                    </ol>
                    <img className="guideimage desktop-view" src="/images/steps.webp" alt="Guide Steps" />
                    <img className="guideimage mobile-view" src="/images/steps-mobile.webp" alt="Guide Steps Mobile" />
                    <a href="/promotion/jili-247-spin-frenzy/" className="view-full-tnc">View Full Terms and Condition</a>
                </div>
            </div>
        </div>
    );
}
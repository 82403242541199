import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import {currentPath, currentDomain} from "../../App";

export default function FortunePayBonus() {
  const title = "Monthly Roll Out";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("FortunePay");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("FortunePay");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SG8 Fortune Pay Exclusive Deal</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="SG8 Fortune Pay Exclusive Deal"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />
        <h2>
          SG8 Fortune Pay Exclusive Deal
        </h2>
        <p>October 7 to December 31, 2024</p>
        <div className="table-main">
        <table>
          <thead>
              <th>
                Deposit
              </th>
              <th>
                Voucher
              </th>
              <th>
                Rewards Percentage
              </th>
          </thead>
          <tbody>
            <tr>
              <td>Php 800</td>
              <td>Php 100</td>
              <td>Php 12.5%</td>
            </tr>
            <tr>
              <td>Php 1,600</td>
              <td>Php 240</td>
              <td>Php 15%</td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2>General Mechanics:</h2>
        <ol>
          <li>
            This promotion will be available via Fortune Pay’s app and the SG8 website
          </li>
          <li>
            Open to all new and old registered players of SG8.
          </li>
          <li>
            Valid only if the player deposits exclusively through Fortune Pay and with a specific amount per reward. 
          </li>
          <li>
            This promotion is only valid once and will automatically be credited to the player’s account.
          </li>
          <li>
            To withdraw, a 5x turnover is required, and can only withdraw via Fortune Pay.
          </li>
          <li>
            Bonus computation and Turnover requirements example:<br/>
                <b>Deposit:</b> Php 1,600<br/>
                <b>Bonus:</b> Php 1,600 + Php 240 = Php 1,840 <br/>
                <b>Turnover Requirements:</b> (Php 1,600 + Php 240) x 5 = Php 9,200
          </li>
          <li>
            Before withdrawing, players must fulfill the turnover and withdrawal requirements. Otherwise, the bonus cannot be claimed.
          </li>
          <li>
            The promotion will run until December 31, 2024 only
          </li>
        </ol>
        <h2>Terms and conditions:</h2>
        <ol>
          <li>All prizes and other marketing costs shall be shouldered by the Service Provider.</li>
          <li>SP and Fortune Pay have an agreement to manage and run the promotion together. This means both parties are aligned on how they handle participant information and any promotion-related questions.</li>
          <li>SP reserves the right to take back any bonuses claimed by players in the event that the terms and conditions of the promotion are found to have been breached by the player, and/or there is reasonable suspicion of foul play.</li>
          <li>SP has the right to modify or cancel the promotion without prior notice and is subject to PAGCOR’s approval.</li>
          <li>SP retains the authority to disqualify a participant from the promotion upon observing instances of counter or arbitrage betting.</li>
          <li>All terms and conditions for this marketing promotion apply.</li>
          <li>This promo is not in conjunction with any other promo</li>
        </ol>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import {currentPath, currentDomain} from "../../App";

export default function PromotionPage() {
  const title = "Loyalty Card";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("LoyaltyCard");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("LoyaltyCard");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SG8 Casino Loyalty Card | Up To Php880 Free Credits</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="SG8 Casino Loyalty Card! Earn up to Php880 in free credits as you play your favorite games. Join now and enjoy our top promotions and bonuses."
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage sg8-calumpit-promo">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />
        <h2>
          SG8 Loyalty Card Terms & Conditions: {" "}
          <span className="highlight">Max of Php 3,888</span>
        </h2>
        <ol>
          <li>The SG8 Loyalty Card Program is exclusively available to SG8 Casino patrons who are 21 years of age or older.</li>
          <li>To participate, individuals must register as a member of SG8 Casino and accept these Terms & Conditions.</li>
          <li>Members will earn one stamp for each deposit made in the following denominations: <strong>P200, P300, P400, P500, P700, and P1000.</strong></li>
          <li>Deposits can be made both online and/or in the outlet to be eligible for a stamp.</li>
          <li>
            A maximum of 10 stamps can be collected on any single loyalty card.
          </li>
          <li>Collection of stamps do not need to be in a chronological order</li>
          <li>Once a member collects all 10 stamps within the promotional period, they are entitled to claim free credits worth <strong>P880</strong>.</li>
          <li>There will be a 1x rollover requirement before any withdrawal can be done</li>
        </ol>
        <div className="table-main">
          <table>
            <thead>
                <tr>
                    <th>Deposit</th>
                    <th>Deposit Amount</th>
                    <th>Turnover Requirement</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>Php 200</td>
                    <td rowspan="10">
                        <p class="multiplier">1x</p>
                    </td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>Php 200</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>Php 200</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>Php 300</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>Php 300</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>Php 400</td>
                </tr>
                <tr>
                    <td>7</td>
                    <td>Php 400</td>
                </tr>
                <tr>
                    <td>8</td>
                    <td>Php 500</td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>Php 700</td>
                </tr>
                <tr>
                    <td>10</td>
                    <td>Php 1000</td>
                </tr>
                <tr>
                    <td rowspan="2"><strong>Bonus</strong></td>
                    <td><strong>Bonus Amount</strong></td>
                    <td><strong>Turnover Requirement</strong></td>
                </tr>
                <tr>
                    <td>Php 880</td>
                    <td>1x</td>
                </tr>
            </tbody>
        </table>

        </div>
        
        <ol  start={9}>
          <li>
           The completed loyalty card must be presented to the cashier desk at SG8 Jackpot to redeem the reward.
          </li>
          <li>
            The loyalty card program is valid until <strong>31st July 2024</strong>. All stamps must be collected and rewards claimed within this timeframe.
          </li>
          <li>
            Free credits can only be claimed if individual did not play on RTG games.
          </li>
          <li>Stamps are non-transferable and may not be exchanged for cash or other substitutes.</li>
          <li>
            SG8 is not responsible for lost, stolen, or damaged loyalty cards.
          </li>
          <li>Patrons should report lost or stolen cards to SG8 Jackpot customer service immediately for potential replacement at the discretion of SG8 management.</li>
          <li>Participation in the SG8 Loyalty Card Program authorises SG8 to collect and utilise personal information for program management purposes.</li>
          <li>Personal information will be processed in accordance with SG8 ‘s Privacy Policy, available at SG8’s Privacy Policy page.</li>
          <li>SG8 reserves the right to change these terms and conditions at any time without prior notice.</li>
          <li>The most current version of these terms will be available on the SG8 website: <a href="https://sg8.casino">https://sg8.casino</a></li>
          <li>In the event of a dispute, the decision of SG8 management shall be final and binding.</li>
          <li>For further information or inquiries regarding the SG8 Loyalty Card Program, please contact our customer service team.</li>
        </ol>
      
      </div>
    </>
  );
}

import React from 'react';

export default function Maintenance() {
    const handleLogout = () => {
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('username');
        localStorage.removeItem('rememberMe');
        window.location.href = "/";
    };

    return (
        <div className="modal maintenance-container">
            <div className="modal-main-container">
                <div className='logo'>
                    <img src='images/sg8-casino-logo-1.webp' alt='SG8 Logo'/>
                </div>
                <div className="modal-content">
                    <h2>Action Required</h2>
                    <p>For optimal performance and security, you are required to<br/><b>log out of your account and re-log in.</b><br/>If you encounter any issues, please contact our support team.</p>
                    <button className='yellow-btn' onClick={handleLogout}>Logout</button>
                </div>
            </div>
        </div>
    );
}
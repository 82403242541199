import React from "react"
import TermsAndCondition from "../pages/footer/TermsAndCondition"

const LoginPromptModal = ({ onAgree }) => {
  return (
    <div className="loginprompt modal">
      <div className="modal-main-container">
        <div className="modal-content">
          <TermsAndCondition />
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button className="agree-button yellow-btn" onClick={onAgree}>
              I agree to the Terms and Condition
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPromptModal

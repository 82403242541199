import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import ReactDOM from "react-dom/client"
import App from "./App"
import Promotion from "./pages/Promotion"
import LiveCasino from "./pages/LiveCasino"
import Sports from "./pages/Sports"
import Dashboard from "./pages/Dashboard"
import Deposit from "./pages/Deposit"
import Withdraw from "./pages/Withdraw"
import RequestReport from "./pages/RequestReport"
import GameReport from "./pages/GameReport"
import Verification from "./api/Verification"
import Vip from "./pages/Vip"
import Rtg from "./pages/slots/RTG"
import Spadegaming from "./pages/slots/Spadegaming"
import Bng from "./pages/slots/Bng"
import Jili from "./pages/slots/Jili"
import Playson from "./pages/slots/Playson"
import Netent from "./pages/slots/Netent"
import Redtiger from "./pages/slots/Redtiger"
import Nextspin from "./pages/slots/Nextspin"
import Nlc from "./pages/slots/Nlc"
import Pgsoft from "./pages/slots/Pgsoft"
import Jdb from "./pages/slots/Jdb"
import Cq9 from "./pages/slots/Cq9"
import Playstar from "./pages/slots/Playstar"
import Wazdan from "./pages/slots/Wazdan"
import Btg from "./pages/slots/Btg"
import Tpg from "./pages/slots/Tpg"
import Fachai from "./pages/slots/Fachai"
import Ygg from "./pages/slots/Ygg"
import Elbet from "./pages/slots/Elbet"
import Habanero from "./pages/slots/Habanero"
import PromotionPage from "./pages/promotion/DepositBonus"
import PaydayBonus from "./pages/promotion/PaydayBonus"
import BirthdayBonus from "./pages/promotion/BirthdayBonus"
import ReferralBonus from "./pages/promotion/ReferralBonus"
import CashBackBonus from "./pages/promotion/CashBackBonus"
import WelcomeBonus from "./pages/promotion/WelcomeBonus"
import MonthlyRollOut from "./pages/promotion/MonthlyRollOut"
import JiliHappyHour from "./pages/promotion/JiliHappyHour"
import PageNotFound from "./pages/PageNotFound"
import AboutUs from "./pages/footer/AboutUs"
import Header from "./Header"
import Footer from "./Footer"
import Logout from "./snippets/LogoutButton"
import Faq from "./pages/footer/Faq"
import PrivacyAndPolicy from "./pages/footer/PrivacyAndPolicy"
import TermsAndCondition from "./pages/footer/TermsAndCondition"
import ResponsibleGaming from "./pages/footer/ResponsibleGaming"
import SelfExclusion from "./pages/footer/SelfExclusion"
import Slots from "./pages/slots/Slots"
import LiveChat from "./snippets/LiveChat"
import PopUpModal from "./snippets/PopUpModal"
import "./style/style.css"
import "./style/accountStyle.css"
import "./style/chat.css"
import Blog from "./pages/Blog/Blog"
import LatestPromotions from "./pages/Blog/blog_promotion/LatestPromotions"
import LoyaltyCard from "./pages/promotion/LoyaltyCard"
import DownloadMobileApp from "./pages/Blog/blog_promotion/DownloadMobileApp"
import Pragmatic from "./pages/slots/Pragmatic"
import Referral from "./snippets/Referral"
import Novo from "./Novo"
import JiliXSG8 from "./pages/JiliSg8"
import How2PlaySG8Casino from "./pages/How2PlaySG8Casino"
import JiliBestCasinoGames from "./pages/JiliBestCasinoGames"
import TopJiliGames from "./pages/TopJiliGames"
import Holiyay from "./pages/promotion/Holiyay"
import WhyFilipinoLoveJili from "./pages/JILISEO/WhyFilipinoLoveJili"
import MaximizeWinningatJili from "./pages/JILISEO/MaximizeWinningsJili"
import WhatisJILIGames from "./pages/JILISEO/WhatisJILIGames"
import PlayJILIwithGcash from "./pages/JILISEO/PlayJILIwithGcash"
import TypesofJILIGames from "./pages/JILISEO/4TypesofJILIGames"
import SuperAceJILI from "./pages/JILISEO/SuperaceJILI"
import JILIFREE100 from "./pages/JILISEO/JILIFREE100"
import JILIVolatility from "./pages/JILISEO/JILISlotsVolatility"
import BetAndWin from "./pages/promotion/BetAndWin"
import SuperAce from "./pages/JILISEO/GamesArticle/SuperAce"
import FortuneGems from "./pages/JILISEO/GamesArticle/FortuneGems"
import GoldenEmpire from "./pages/JILISEO/GamesArticle/GoldenEmpire"
import BoxingKing from "./pages/JILISEO/GamesArticle/BoxingKing"
import MegaAce from "./pages/JILISEO/GamesArticle/MegaAce"
import MoneyComing from "./pages/JILISEO/GamesArticle/MoneyComing"
import ColorGame from "./pages/JILISEO/GamesArticle/ColorGame"
import Top10JiliSlot from "./pages/JILISEO/Top10JILISLots"
import JiliVsPragmaticPlay from "./pages/JILISEO/JILIVSPP"
import JiliSlotsCasinoFilipino from "./pages/JILISEO/JiliSlotsCasinoFilipino"
import WinJILICrazy777 from "./pages/JILISEO/WinJILICrazy777"
import JiliSignUpBonus from "./pages/JILISEO/JILISignupBonus"
import WhatAreJiliSlots from "./pages/JILISEO/WhatAreJiliSlots"
import HowtoWinJiliSlot777 from "./pages/JILISEO/HowToWinJiliSlot777"
import BestJILISlottoPlay from "./pages/JILISEO/BestJiliSlotsToPlay"
import SuperAceJiliSlotFreeSpins from "./pages/JILISEO/SuperAceJiliSlotFreeSpins"
import HowToWinSuperAceJili from "./pages/JILISEO/HowToWinSuperAceJili"
import SuperAceXMoneyComing from "./pages/JILISEO/SuperAceXMoneyComing"
import JiliFree30PHP from "./pages/JILISEO/JILIFREE30"
import TopJiliSlotGame from "./pages/JILISEO/TopJiliSlotGame"
import FortunePayBonus from "./pages/promotion/FortunePayBonus"
import TopGames from "./pages/slots/TopGames"
import Cention from "./Cention.jsx"
import SkinnyMikes from "./pages/promotion/SkinnyMikes"
// import TestPage from "./snippets/TestPage";
//Global Context Provider
import { GlobalContextProvider } from "./snippets/GlobalContextProvider"
import PopularJiliSlots from "./pages/JILISEO/PopularJiliSLots.jsx"
import WinBigJiliSlotFree30 from "./pages/JILISEO/WinBigJiliSlotFree30.jsx"
import Coupon from "./pages/promotion/Coupon.jsx"
import BlackFridaySlots from "./snippets/BlackFriday.jsx"
import AppPromotion from "./pages/AppPromotion.jsx"
import BalloonComponent from "./snippets/BalloonComponent.jsx"
// import GoldenNestPage from "./snippets/GoldenNestPage.jsx"
import PromoBlackFriday from "./snippets/PromoBlackFriday.jsx"
import BlackFridayPage from "./pages/promotion/BlackFridayPage.jsx"
import ProgressiveJackpot from "./pages/promotion/ProgressiveJackpot.jsx"
export const routes = [
  { path: "/", element: <App /> },
  // { path: "/test-page", element: <TestPage /> },
  // { path: "/golden-nest/", element: <GoldenNestPage /> },
  { path: "/app-promotion/", element: <AppPromotion /> },
  { path: "/promotion/", element: <Promotion /> },
  { path: "/live-casino/", element: <LiveCasino /> },
  { path: "/sportsbook/", element: <Sports /> },
  { path: "/account/dashboard/", element: <Dashboard /> },
  { path: "/account/deposit/", element: <Deposit /> },
  { path: "/account/withdraw/", element: <Withdraw /> },
  { path: "/account/request-report/", element: <RequestReport /> },
  { path: "/account/game-report/", element: <GameReport /> },
  { path: "account/verification/", element: <Verification /> },
  { path: "/slots/", element: <Slots /> },
  { path: "/slots/spadegaming/", element: <Spadegaming /> },
  { path: "/slots/rtg/", element: <Rtg /> },
  { path: "/slots/bng/", element: <Bng /> },
  { path: "/slots/jili/", element: <Jili /> },
  { path: "/slots/playson/", element: <Playson /> },
  { path: "/slots/netent/", element: <Netent /> },
  { path: "/slots/redtiger/", element: <Redtiger /> },
  { path: "/slots/nextspin/", element: <Nextspin /> },
  { path: "/slots/nlc/", element: <Nlc /> },
  { path: "/slots/pgsoft/", element: <Pgsoft /> },
  { path: "/slots/jdb/", element: <Jdb /> },
  { path: "/slots/cq9/", element: <Cq9 /> },
  { path: "/slots/playstar/", element: <Playstar /> },
  { path: "/slots/wazdan/", element: <Wazdan /> },
  { path: "/slots/btg/", element: <Btg /> },
  { path: "/slots/tpg/", element: <Tpg /> },
  { path: "slots/fachai/", element: <Fachai /> },
  { path: "/slots/ygg/", element: <Ygg /> },
  { path: "/slots/elbet/", element: <Elbet /> },
  { path: "/slots/habanero/", element: <Habanero /> },
  { path: "/slots/pragmatic/", element: <Pragmatic /> },
  { path: "/slots/top-games/", element: <TopGames /> },
  { path: "/vip/", element: <Vip /> },
  { path: "/promotion/bet-and-win/", element: <BetAndWin /> },
  { path: "/promotion/deposit-bonus/", element: <PromotionPage /> },
  { path: "/promotion/holiyay/", element: <Holiyay /> },
  { path: "/promotion/payday-bonus/", element: <PaydayBonus /> },
  { path: "/promotion/birthday-bonus/", element: <BirthdayBonus /> },
  { path: "/promotion/referral-bonus/", element: <ReferralBonus /> },
  { path: "/promotion/cash-back-bonus/", element: <CashBackBonus /> },
  { path: "/promotion/loyalty-card/", element: <LoyaltyCard /> },
  { path: "/promotion/welcome-bonus/", element: <WelcomeBonus /> },
  { path: "/promotion/monthly-rollout/", element: <MonthlyRollOut /> },
  { path: "/promotion/progressive-jackpot/", element: <ProgressiveJackpot /> },
  { path: "/promotion/jili-247-spin-frenzy/", element: <JiliHappyHour /> },
  { path: "/promotion/fortunepay/", element: <FortunePayBonus /> },
  { path: "/promotion/skinny-mikes/", element: <SkinnyMikes /> },
  { path: "/coupon", element: <Coupon /> },
  { path: "*", element: <PageNotFound /> },
  { path: "/about-us/", element: <AboutUs /> },
  { path: "/faq/", element: <Faq /> },
  { path: "/privacy-policy/", element: <PrivacyAndPolicy /> },
  { path: "/terms-and-condition/", element: <TermsAndCondition /> },
  { path: "/responsible-gaming/", element: <ResponsibleGaming /> },
  { path: "/self-exclusion/", element: <SelfExclusion /> },
  { path: "/blog/", element: <Blog /> },
  { path: "/latest-promotion/", element: <LatestPromotions /> },
  { path: "/news/", element: <Blog /> },
  { path: "/news/latest-promotion/", element: <LatestPromotions /> },
  {
    path: "/news/download-sg8-casino-mobile-app/",
    element: <DownloadMobileApp />,
  },
  { path: "account/referral/", element: <Referral /> },
  { path: "/jili-games/", element: <JiliXSG8 /> },
  {
    path: "/jili-games/how-to-play-at-sg8casino/",
    element: <How2PlaySG8Casino />,
  },
  {
    path: "/jili-games/jili-best-casino-games/",
    element: <JiliBestCasinoGames />,
  },
  { path: "/jili-games/top-jili-games/", element: <TopJiliGames /> },
  {
    path: "/jili-games/why-filipino-love-jili-games/",
    element: <WhyFilipinoLoveJili />,
  },
  {
    path: "/jili-games/maximize-winnings-by-playing-jili-slots/",
    element: <MaximizeWinningatJili />,
  },
  { path: "/jili-games/what-is-jili-games/", element: <WhatisJILIGames /> },
  { path: "/jili-games/play-jili-with-gcash/", element: <PlayJILIwithGcash /> },
  { path: "/jili-games/types-of-jili-games/", element: <TypesofJILIGames /> },
  { path: "/jili-games/superace/", element: <SuperAceJILI /> },
  {
    path: "/jili-games/jili-free-30-bonus-sg8-casino/",
    element: <JILIFREE100 />,
  },
  { path: "/jili-games/volatility-jili-slots/", element: <JILIVolatility /> },
  { path: "/jili-games/super-ace/", element: <SuperAce /> },
  { path: "/jili-games/fortune-gems/", element: <FortuneGems /> },
  { path: "/jili-games/golden-empire/", element: <GoldenEmpire /> },
  { path: "/jili-games/boxing-king/", element: <BoxingKing /> },
  { path: "/jili-games/mega-ace/", element: <MegaAce /> },
  { path: "/jili-games/money-coming/", element: <MoneyComing /> },
  { path: "/jili-games/color-game/", element: <ColorGame /> },
  { path: "/jili-games/best-jili-games-2024/", element: <Top10JiliSlot /> },
  {
    path: "/jili-games/jili-pragmatic-play-bonuses-for-filipinos/",
    element: <JiliVsPragmaticPlay />,
  },
  {
    path: "/jili-games/jili-slots-dominate-filipino-casino-scene/",
    element: <JiliSlotsCasinoFilipino />,
  },
  { path: "/jili-games/win-crazy-777-jili/", element: <WinJILICrazy777 /> },
  {
    path: "/jili-games/best-jili-casino-sign-up-bonuses-2024/",
    element: <JiliSignUpBonus />,
  },
  { path: "/jili-games/what-are-jili-slots/", element: <WhatAreJiliSlots /> },
  {
    path: "/jili-games/how-to-win-at-jili-slot-777/",
    element: <HowtoWinJiliSlot777 />,
  },
  {
    path: "/jili-games/best-jili-slots-to-play-2024/",
    element: <BestJILISlottoPlay />,
  },
  {
    path: "/jili-games/super-ace-jili-slot-free-spins/",
    element: <SuperAceJiliSlotFreeSpins />,
  },
  {
    path: "/jili-games/how-to-win-super-ace-jili-slot/",
    element: <HowToWinSuperAceJili />,
  },
  {
    path: "/jili-games/high-rtp-jili-slots-super-ace-money-coming/",
    element: <SuperAceXMoneyComing />,
  },
  {
    path: "/jili-games/sg8-casino-php30-free-sign-up-bonus-jili-slots/",
    element: <JiliFree30PHP />,
  },
  {
    path: "/jili-games/top-jili-slot-games-sg8-casino/",
    element: <TopJiliSlotGame />,
  },
  {
    path: "/jili-games/free-play-jili-slots-online/",
    element: <PopularJiliSlots />,
  },
  {
    path: "/jili-games/win-big-jili-slots/",
    element: <WinBigJiliSlotFree30 />,
  },
  { path: "/black-friday-promo/", element: <BlackFridaySlots /> },
  { path: "/promotion/black-friday-sale/", element: <BlackFridayPage /> },
]
const doNotShowAgain = localStorage.getItem("doNotShowAgain")
const root = ReactDOM.createRoot(document.getElementById("root"))
let appver
const activePage = window.location.pathname
if (activePage === "/app-promotion/" || activePage === "/app-promotion") {
  appver = true
} else {
  appver = false
}

root.render(
  <>
    <GlobalContextProvider>
      {appver != true && <Header />}
      <div id="content" className="site-content">
        <div
          id="primary"
          className="content-area"
          style={{
            width: "100%",
            backgroundSize: "100%",
          }}
        >
          <main id="main" className="site-main">
            <Logout />
            {/* <LiveChat/> */}
            <Router>
              <Routes>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))}
              </Routes>
            </Router>
          </main>
        </div>
      </div>
      {/* <Novo /> */}
      {/* <Cention/> */}
      {!doNotShowAgain && <PopUpModal />}
      {appver != true && <Footer />}
    </GlobalContextProvider>
  </>
)

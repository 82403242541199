import React, { useState } from "react"

export default function PromoGcash() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.isLoggedIn === "true"
  )

  const handleClick = (e) => {
    if (isLoggedIn) {
      window.location.href = "/account/deposit/"
    } else {
      const regButton = document.querySelector(".reg-btn")
      if (regButton) {
        regButton.click()
      } else {
      }
    }
  }

  return (
    <a href="#" onClick={handleClick} className="countdown-link">
      <div className="promogcash  promotion-featured-item"></div>
    </a>
  )
}

import { useState, useEffect } from "react"

// Helper to get the current date in YYYY-MM-DD format
const getCurrentDate = () => {
  const today = new Date()
  return today.toISOString().split("T")[0]
}

// Custom Hook to manage the trigger tracker
export const useTriggerTracker = () => {
  const [triggerTracker, setTriggerTracker] = useState(() => {
    // Initialize the triggerTracker from localStorage
    const storedTracker = JSON.parse(localStorage.getItem("triggerTracker"))
    const currentDate = getCurrentDate()

    // If no tracker or the date is old, create a new tracker for today
    if (!storedTracker || storedTracker.date !== currentDate) {
      localStorage.setItem("uniqueGames", 0)
      localStorage.setItem("nJGames", 0)
      return { date: currentDate }
    }
    return storedTracker
  })

  // Save any changes to the tracker back to localStorage
  useEffect(() => {
    localStorage.setItem("triggerTracker", JSON.stringify(triggerTracker))
  }, [triggerTracker])

  // Function to check if a trigger has been shown today
  const hasTriggerBeenShown = (trigger) => {
    return triggerTracker[trigger] === true
  }

  // Function to set a trigger as shown
  const setTrigger = (trigger) => {
    setTriggerTracker((prevTracker) => ({
      ...prevTracker,
      [trigger]: true,
    }))
  }

  // Function to reset triggers (for testing purposes or manual resets)
  const resetTriggers = () => {
    setTriggerTracker({ date: getCurrentDate() })
  }

  return {
    hasTriggerBeenShown,
    setTrigger,
    resetTriggers,
  }
}

import React, { useState, useEffect } from "react"
export default function PromotionTwoCountdown() {
  const targetDate = "2024-12-01T00:00:00"
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  function calculateTimeLeft() {
    const difference = new Date(targetDate) - new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  return (
    <a href="/promotion/jili-247-spin-frenzy/" className="countdown-link">
      <div className="promotion-featured-two promotion-featured-item">
        <div className="countdown-container">
          {timeLeft.days !== undefined ? (
            <div>
              <div className="time">
                <div className="time-unit time-unit-separator">
                  <span className="time-number">{timeLeft.days}</span>
                  <span className="time-label">Days</span>
                </div>
                <div className="time-unit time-unit-separator">
                  <span className="time-number">{timeLeft.hours}</span>
                  <span className="time-label">Hr</span>
                </div>
                <div className="time-unit time-unit-separator">
                  <span className="time-number">{timeLeft.minutes}</span>
                  <span className="time-label">Min</span>
                </div>
                <div className="time-unit">
                  <span className="time-number">{timeLeft.seconds}</span>
                  <span className="time-label">Sec</span>
                </div>
              </div>
              <span className="promotion-subtext">
                <b>2nd Wave is Coming!</b>
              </span>
            </div>
          ) : (
            <div className="banner-text">
              Gift codes are claimed! Check back soon!
            </div>
          )}
        </div>
      </div>
    </a>
  )
}

import React, { useEffect, useState } from "react"
import LogoutFunction from "./LogoutFunction"

function LogoutButton() {
  const [currentActive, setCurrentActive] = useState(0)
  const [isBodyLoggedIn, setIsBodyLoggedIn] = useState(false)
  const duration = 3000

  const newsItems = [
    {
      id: 0,
      content: `We've updated our security system. If you have any issues, <b>try logging out and back into your SG8 account.</b>`,
    },
    {
      id: 1,
      content:
        "Please be informed that sg8.casino is the sole authorized website for SG8 casino.",
    },
    {
      id: 2,
      content:
        "JILI, now available at SG8 Casino! Embark on an unforgettable gaming adventure and win incredible prizes!",
    },
    {
      id: 3,
      content:
        "Unlock Your Rewards: Up to 25% bonus on Deposit, Max of Php 3,888!",
    },
    {
      id: 4,
      content:
        "Experience the thrill and excitement at <strong>SG8 Grand Jackpot</strong>. Panalo Ka Dito!",
      link: "/promotion/progressive-jackpot/",
    },
    {
      id: 5,
      content:
        "Earn a  0.25% rolling commission every month! Enjoy reliable rewards as part of our ongoing commitment to your success.",
      link: "/promotion/monthly-rollout/",
    },
  ]

  const totalNews = newsItems.length

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentActive((prevActive) => (prevActive + 1) % totalNews)
    }, duration)

    return () => clearInterval(intervalId)
  }, [totalNews, duration])

  useEffect(() => {
    setIsBodyLoggedIn(document.body.classList.contains("isLoggedIn"))
  }, [])

  return (
    <div className="header-news-logout">
      <div className="news-container">
        <div className="news-heading">News:</div>
        {newsItems.map((item, index) => (
          <div key={item.id}>
            <a
              href={item.link}
              className={`news-single ${
                index === currentActive ? "active" : ""
              }`}
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          </div>
        ))}
      </div>
      {isBodyLoggedIn && <LogoutFunction />}
    </div>
  )
}

export default LogoutButton

import React, { useState, useEffect } from "react"
import { apk } from "../Footer"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { checkSchedule } from "./checkSchedule"

export const settings = {
  dots: true,
  infinite: true,
  speed: 200,
  fade: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: false,
  centerPadding: "0",
  customPaging: (i) => (
    <button
      style={{
        width: "20px",
        height: "1px",
        padding: "2px",
        backgroundColor: "#ffffff65",
      }}
    />
  ),
  dotsClass: "slick-dots custom-dots",
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        fade: true,
        centerPadding: "10px",
      },
    },
    {
      breakpoint: 840,
      settings: {
        dots: false,
      },
    },
  ],
}

const MainSlider = ({ sliderCurrentPage, bannerCurrentPage }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.isLoggedIn)
  const slides = [
    {
      id: "GoldenNest",
      src: "/images/goldennestjackpot.webp",
      mobileSrc: "/images/promotion/goldenNestJackpot-feature.webp",
      alt: "",
      description: "",
      buttonText: "",
      href: "/promotion/progressive-jackpot/",
      position: "left",
      hideContent: true,
      showOnPages: ["GoldenNest"],
      disableOnPages: [],
      overlay: false,
      hideButton: true,
      textColor: "#fff",
      hidden: false,
    },
    {
      id: "JiliHappyHour",
      src: "/images/promotion/sg8-jili24-7-frenzy-desktop.webp",
      mobileSrc: "/images/promotion/sg8-jili24-7-frenzy-mobile.webp",
      alt: "JILI 24/7 Spin Frenzy",
      description: "JILI 24/7 Spin Frenzy",
      buttonText: "LEARN MORE",
      href: "/promotion/jili-247-spin-frenzy/",
      position: "left",
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: false,
      textColor: "#fff",
      hidden: false,
    },
    {
      id: "MonthlyRollOut",
      src: checkSchedule("2024-12-01T00:00:00")
        ? "/images/promotion/sg8-monthly-roll-out-pgsoft.webp"
        : "/images/monthly-roll-out-jili.webp",
      mobileSrc: checkSchedule("2024-12-01T00:00:00")
        ? "/images/promotion/sg8-monthly-roll-out-pgsoft-mobile.webp"
        : "/images/monthly-roll-out-jili-mobile.webp",
      alt: "SG8 Monthly Roll out",
      description: "SG8 Monthly Roll out",
      buttonText: "LEARN MORE",
      href: "/promotion/monthly-rollout/",
      position: "left",
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: false,
      textColor: "#fff",
      hidden: false,
    },
    {
      id: "FortunePay",
      src: "/images/promotion/sg8-official-banner-fortune-pay.webp",
      mobileSrc: "/images/promotion/sg8-fortunepay-15-bonus-at-sg8-mobile.webp",
      alt: "SG8 FortunePay Exclusive Deal",
      description: "SG8 Fortune Pay Exclusive Deal",
      buttonText: "LEARN MORE",
      href: "/promotion/fortunepay/",
      position: "left",
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: false,
      textColor: "#fff",
      hidden: false,
    },
    {
      id: "BetAndWin",
      src: "/images/promotion/bet_win-desktop.webp",
      mobileSrc: "/images/promotion/bet&win-desktop-1024x544.webp",
      alt: "Bet & Win ₱1,375,00 1000 Winners Every Week",
      description: "Bet & Win ₱1,375,00 1000 Winners Every Week at SG8",
      buttonText: "",
      href: "/promotion/bet-and-win/",
      position: "left",
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: true,
      textColor: "#fff",
      hidden: false,
    },
    {
      id: "DepositBonus",
      src: "/images/promotion/sg8-official-banner-deposit-bonus.webp",
      mobileSrc: "/images/promotion/deposit-bonus-at-sg8-mobile.webp",
      alt: "SG8 Casino Deposit Bonus | Boost Up to 150% Bonus",
      description:
        "Get more value for your money and enhance your gaming experience.",
      buttonText: "LEARN MORE",
      href: "/promotion/deposit-bonus/",
      position: "left",
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: false,
      textColor: "#fff",
      hidden: false,
    },
    {
      id: "WelcomeBonus",
      src: "/images/promotion/welcome-bonus-30php-at-sg8.webp",
      mobileSrc: "/images/promotion/welcome-bonus-30php-at-sg8-mobile.webp",
      alt: "SG8 Welcome Bonus | P30 Bonus Credits",
      description: "30 Pesos Bonus Credits On Registration!",
      buttonText: "LEARN MORE",
      href: "/promotion/welcome-bonus/",
      position: "left",
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: false,
      textColor: "#fff",
      hidden: false,
    },
    {
      id: "CashBack",
      src: "/images/promotion/new-daily-cashback.webp",
      mobileSrc: "/images/promotion/cashback-at-sg8-mobile-1.webp",
      alt: "",
      description: "",
      buttonText: "LEARN MORE",
      href: "/promotion/cash-back-bonus/",
      position: "left",
      hideContent: false,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: true,
      textColor: "#fff",
      hidden: false,
    },
    {
      id: "PaydayBonus",
      src: "/images/promotion/payday-bonus-at-sg8.webp.webp",
      mobileSrc: "/images/promotion/payday-bonus-at-sg8-mobile.webp",
      alt: "Payday Bonus | Extra Rewards on Payday",
      description:
        "Enjoy extra rewards and boost your gaming experience every payday.",
      buttonText: "LEARN MORE",
      href: "/promotion/payday-bonus/",
      position: "left",
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: false,
      textColor: "#fff",
      hidden: false,
    },
    {
      id: "Holiyay",
      src: "/images/promotion/holiyay-special-deal-at-sg8.webp",
      mobileSrc: "/images/promotion/holiyay-special-deal-at-sg8-mobile.webp",
      alt: "Holiyay Special Deal Get up to PHP 1500",
      description: "Holiyay Special Deal Get up to PHP 1500 at SG8",
      buttonText: "",
      href: "/promotion/holiyay/",
      position: "left",
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: true,
      textColor: "#fff",
      hidden: false,
    },
    {
      id: "Gcash",
      src: "/images/promotion/DIRECT_GCASH_BANNER.webp",
      mobileSrc: "/images/promotion/DIRECT_GCASH_BANNER-mobile.webp",
      alt: "Direct GCash transaction now available",
      description: "Direct GCash transaction now available at SG8",
      buttonText: "",
      href: isLoggedIn ? "/account/deposit/" : "/?from=--- Select ---",
      position: "left",
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: true,
      textColor: "#fff",
      hidden: false,
    },
    {
      id: "PP",
      src: "/images/slots/hero/pragmatic play-at-sg8.webp",
      mobileSrc: "/images/slots/hero/pragmatic-play-at-sg8-mobile.webp",
      alt: "Pragmatic Play",
      description:
        "Games offer top-quality online casino entertainment with a wide range of slots, table games, and specialty games. Available",
      buttonText: "Available Now",
      href: "/slots/pragmatic/",
      position: "left",
      hideContent: false,
      showOnPages: ["PP"],
      disableOnPages: [],
      overlay: true,
      hideButton: false,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "Download",
      src: "/images/download/apkbannerv1.webp",
      mobileSrc: "/images/download/mobilev2.jpg",
      alt: "SG8 Casino Filipino | Ultimate Gaming Experience is on the app now!",
      description:
        "Download the new SG8 Casino Filipino mobile app for the ultimate gaming experience",
      buttonText: "DOWNLOAD",
      href: apk,
      position: "left",
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: false,
      textColor: "#fff",
      hidden: false,
    },
    {
      id: "ReferralBonus",
      src: "/images/refer-a-friend-at-sg8.webp",
      mobileSrc: "/images/refer-a-friend-at-sg8-mobile.webp",
      alt: "Referral Bonus up to 10%",
      description: "",
      buttonText: "LEARN MORE",
      href: "/promotion/referral-bonus/",
      position: "left",
      hideContent: false,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: false,
      textColor: "#fff",
      hidden: false,
    },
    {
      id: "BirthdayBonus",
      src: "/images/sg8-birthday-bonus-special.webp",
      mobileSrc: "/images/sg8-birthday-bonus-special-mobile.webp",
      alt: "Exclusive Birthday Bonus",
      description:
        "Enjoy your special day with SG8 Casino's exclusive birthday bonus",
      buttonText: "LEARN MORE",
      href: "/promotion/birthday-bonus/",
      position: "left",
      hideContent: true,
      showOnPages: ["all"],
      disableOnPages: [],
      overlay: false,
      hideButton: false,
      textColor: "#fff",
      hidden: false,
    },
    // {
    //   id: "LoyaltyCard",
    //   src: "/images/promotion/loyalty-card-sg8.jpg",
    //   mobileSrc: "/images/promotion/loyalty-card-sg8-mobile.jpg",
    //   alt: "SG8 Casino Deposit Bonus | Boost Up to 150% Bonus",
    //   description:
    //     "Get more value for your money and enhance your gaming experience.",
    //   buttonText: "LEARN MORE",
    //   href: "/promotion/loyalty-card/",
    //   position: "left",
    //   hideContent: true,
    //   showOnPages: ["all"],
    //   disableOnPages: [],
    //   overlay: false,
    //   hideButton: false,
    //   textColor: "#fff",
    //   hidden: false,
    // },
    {
      id: "RTG",
      src: "/images/slots/hero/rtg-at-sg8.webp",
      mobileSrc: "/images/slots/hero/rtg-at-sg8-mobile.webp",
      alt: "RealTime Gaming",
      description:
        "RTG games offer top-quality online casino entertainment with a wide range of slots, table games, and specialty games. Available",
      buttonText: "Available Now",
      href: "/slots/rtg/",
      position: "left",
      hideContent: false,
      showOnPages: ["RTG"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "Spadegaming",
      src: "/images/slots/hero/spadegaming-at-sg8.webp",
      mobileSrc: "/images/slots/hero/spadegaming-at-sg8-mobile.webp",
      alt: "Spadegaming",
      description:
        "Leading Online Casino Game Provider, Spadegaming delivers high-quality online casino games with a variety of slots, table games, and more.",
      buttonText: "",
      href: "/slots/spadegaming/",
      position: "right",
      hideContent: false,
      showOnPages: ["Spadegaming"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "JILI",
      src: "/images/slots/hero/jili-at-sg8.webp",
      mobileSrc: "/images/slots/hero/jili-at-sg8-mobile.webp",
      alt: "Best JILI Slots in the Philippines",
      description: "Get ₱30 Free on Sign Up – Claim Now!",
      buttonText: "",
      href: "/slots/jili/",
      position: "left",
      hideContent: false,
      showOnPages: ["JILI"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#000",
      hidden: true,
      className: "jili-banner",
    },
    {
      id: "PLAYSTAR",
      src: "/images/slots/hero/playstar-at-sg8.webp",
      mobileSrc: "/images/slots/hero/playstar-at-sg8-mobile.webp",
      alt: "Playstar",
      description:
        "Superior Online Casino Games, Playstar provides exceptional online casino games with a wide array of slots, table games, and more. ",
      buttonText: "",
      href: "/slots/playstar/",
      position: "right",
      hideContent: false,
      showOnPages: ["PLAYSTAR"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "BNG",
      src: "/images/slots/hero/bng-at-sg8.webp",
      mobileSrc: "/images/slots/hero/bng-at-sg8-mobile.webp",
      alt: "BNG Games",
      description:
        "Top Online Casino Entertainment, BNG Games offers a premium selection of online casino games, including slots, table games, and more.",
      buttonText: "",
      href: "/slots/bng/",
      position: "right",
      hideContent: false,
      showOnPages: ["BNG"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "Playson",
      src: "/images/slots/hero/playson-at-sg8.webp",
      mobileSrc: "/images/slots/hero/playson-at-sg8-mobile.webp",
      alt: "Playson",
      description:
        "High-Quality Online Casino Games, Playson delivers a diverse range of online casino games, featuring slots, table games, and specialty games.",
      buttonText: "",
      href: "/slots/playson/",
      position: "left",
      hideContent: false,
      showOnPages: ["Playson"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "Netent",
      src: "/images/slots/hero/netent-at-sg8.webp",
      mobileSrc: "/images/slots/hero/netent-at-sg8-mobile.webp",
      alt: "NetEnt Better Gaming",
      description:
        "Premier Online Casino Gaming, NetEnt offers a top-notch collection of online casino games, including slots, table games, and live dealer options.",
      buttonText: "",
      href: "/slots/netent/",
      position: "left",
      hideContent: false,
      showOnPages: ["Netent"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "Redtiger",
      src: "/images/slots/hero/redtiger-at-sg8.webp",
      mobileSrc: "/images/slots/hero/redtiger-at-sg8-mobile.webp",
      alt: "RedTiger",
      description:
        "Leading Online Casino Games, Red Tiger provides a diverse selection of online casino games, including innovative slots and table games.",
      buttonText: "",
      href: "/slots/redtiger/",
      position: "left",
      hideContent: false,
      showOnPages: ["Redtiger"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "Nextspin",
      src: "/images/slots/hero/nextspin-at-sg8.webp",
      mobileSrc: "/images/slots/hero/nextspin-at-sg8-mobile.webp",
      alt: "NextSpin",
      description:
        "Top Online Casino Games, NextSpin delivers a premium range of online casino games, featuring exciting slots and engaging table games.",
      buttonText: "",
      href: "/slots/nextspin/",
      position: "left",
      hideContent: false,
      showOnPages: ["Nextspin"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "NLC",
      src: "/images/slots/hero/nlc-at-sg8.webp",
      mobileSrc: "/images/slots/hero/nlc-at-sg8-mobile.webp",
      alt: "NoLimit City",
      description:
        "Innovative Online Casino Games, NoLimit City offers a unique selection of online casino games, including creative slots and engaging table games.",
      buttonText: "",
      href: "/slots/nlc/",
      position: "left",
      hideContent: false,
      showOnPages: ["NLC"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "PGSoft",
      src: "/images/slots/hero/pgsoft-at-sg8.webp",
      mobileSrc: "/images/slots/hero/pgsoft-at-sg8-mobile.webp",
      alt: "PG Soft",
      description:
        "Premier Online Casino Gaming, PG Soft provides a wide range of online casino games, featuring innovative slots and table games. ",
      buttonText: "",
      href: "/slots/pgsoft/",
      position: "right",
      hideContent: false,
      showOnPages: ["PGSoft"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "JDB",
      src: "/images/slots/hero/jdb-at-sg8.webp",
      mobileSrc: "/images/slots/hero/jdb-at-sg8-mobile.webp",
      alt: "JDB",
      description:
        "Top Online Casino Games, JDB offers a diverse selection of online casino games, including engaging slots and table games. ",
      buttonText: "",
      href: "/slots/jdb/",
      position: "right",
      hideContent: false,
      showOnPages: ["JDB"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "CQ9",
      src: "/images/slots/hero/cq9-at-sg8.webp",
      mobileSrc: "/images/slots/hero/cq9-at-sg8-mobile.webp",
      alt: "CQ9 Gaming",
      description:
        "High-Quality Online Casino Games, CQ9 delivers a premium selection of online casino games, featuring innovative slots and engaging table games.",
      buttonText: "",
      href: "/slots/cq9/",
      position: "left",
      hideContent: false,
      showOnPages: ["CQ9"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "wazdan",
      src: "/images/slots/hero/wazdan-at-sg8.webp",
      mobileSrc: "/images/slots/hero/wazdan-at-sg8-mobile.webp",
      alt: "Wazdan",
      description:
        "Premier Online Casino Games, Wazdan offers a diverse array of online casino games, including dynamic slots and engaging table games.",
      buttonText: "",
      href: "/slots/wazdan/",
      position: "right",
      hideContent: false,
      showOnPages: ["wazdan"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "BTG",
      src: "/images/slots/hero/btg-at-sg8.webp",
      mobileSrc: "/images/slots/hero/btg-at-sg8-mobile.webp",
      alt: "Big Time Gaming",
      description:
        "Leading Online Casino Games, Big Time Gaming provides a top-notch selection of online casino games, including innovative slots and exciting table games.",
      buttonText: "",
      href: "/slots/btg/",
      position: "right",
      hideContent: false,
      showOnPages: ["BTG"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "TPG",
      src: "/images/slots/hero/tpg-at-sg8.webp",
      mobileSrc: "/images/slots/hero/tpg-at-sg8-mobile.webp",
      alt: "Triple Profit Gaming",
      description:
        "Premium Online Casino Experience, Triple Profit Games offers a wide range of online casino games, including engaging slots and table games.",
      buttonText: "",
      href: "/slots/tpg/",
      position: "right",
      hideContent: false,
      showOnPages: ["TPG"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "Fachai",
      src: "/images/slots/hero/fachai-at-sg8.webp",
      mobileSrc: "/images/slots/hero/fachai-at-sg8-mobile.webp",
      alt: "FA Chai",
      description:
        "Top Online Casino Games, FaChai delivers a diverse selection of online casino games, featuring exciting slots and engaging table games.",
      buttonText: "",
      href: "/slots/fachai/",
      position: "right",
      hideContent: false,
      showOnPages: ["Fachai"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "ygg",
      src: "/images/slots/hero/ygg-at-sg8.webp",
      mobileSrc: "/images/slots/hero/ygg-at-sg8-mobile.webp",
      alt: "YGGDrasil",
      description:
        "Premier Online Casino Games, Yggdrasil offers a high-quality selection of online casino games, including innovative slots and engaging table games.",
      buttonText: "",
      href: "/slots/ygg/",
      position: "right",
      hideContent: false,
      showOnPages: ["ygg"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "elbet",
      src: "/images/slots/hero/elbet-at-sg8.webp",
      mobileSrc: "/images/slots/hero/elbet-at-sg8-mobile.webp",
      alt: "Elbet",
      description:
        "Leading Online Casino Games, Elbet provides a diverse range of online casino games, featuring exciting slots and table games.",
      buttonText: "",
      href: "/slots/elbet/",
      position: "left",
      hideContent: false,
      showOnPages: ["elbet"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
    {
      id: "Habanero",
      src: "/images/slots/hero/habanero-at-sg8.webp",
      mobileSrc: "/images/slots/hero/habanero-at-sg8-mobile.webp",
      alt: "Habanero",
      description:
        "High-Quality Online Casino Games, Habanero offers a premium selection of online casino games, including engaging slots and table games.",
      buttonText: "",
      href: "/slots/habanero/",
      position: "left",
      hideContent: false,
      showOnPages: ["Habanero"],
      disableOnPages: [],
      overlay: true,
      hideButton: true,
      textColor: "#fff",
      hidden: true,
    },
  ].filter(Boolean)

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 840)

  const handleLinkClick = (slideData) => {
    window.location.href = slideData.href
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 840)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const getFilteredSlides = (slides, sliderCurrentPage) => {
    if (sliderCurrentPage === "CashBack") {
      const cashBackSlide = slides.find((slide) => slide.id === "CashBack")
      return cashBackSlide ? [cashBackSlide] : []
    } else if (sliderCurrentPage === "ReferralBonus") {
      const referralSlide = slides.find((slide) => slide.id === "ReferralBonus")
      return referralSlide ? [referralSlide] : []
    } else if (sliderCurrentPage === "BirthdayBonus") {
      const birthdaySlide = slides.find((slide) => slide.id === "BirthdayBonus")
      return birthdaySlide ? [birthdaySlide] : []
    } else if (sliderCurrentPage === "DepositBonus") {
      const depositSlide = slides.find((slide) => slide.id === "DepositBonus")
      return depositSlide ? [depositSlide] : []
    } else if (sliderCurrentPage === "GoldenNest") {
      const goldenNest = slides.find((slide) => slide.id === "GoldenNest")
      return goldenNest ? [goldenNest] : []
    } else if (sliderCurrentPage === "PaydayBonus") {
      const paydaySLide = slides.find((slide) => slide.id === "PaydayBonus")
      return paydaySLide ? [paydaySLide] : []
      // } else if (sliderCurrentPage === "LoyaltyCard") {
      //   const paydaySLide = slides.find((slide) => slide.id === "LoyaltyCard");
      //   return paydaySLide ? [paydaySLide] : [];
    } else if (sliderCurrentPage === "WelcomeBonus") {
      const welcomeSlide = slides.find((slide) => slide.id === "WelcomeBonus")
      return welcomeSlide ? [welcomeSlide] : []
    } else if (sliderCurrentPage === "Holiyay") {
      const holiyaySlide = slides.find((slide) => slide.id === "Holiyay")
      return holiyaySlide ? [holiyaySlide] : []
    } else if (sliderCurrentPage === "BetAndWin") {
      const betAndWinSlide = slides.find((slide) => slide.id === "BetAndWin")
      return betAndWinSlide ? [betAndWinSlide] : []
    } else if (sliderCurrentPage === "JILI") {
      const JILISlide = slides.find((slide) => slide.id === "JILI")
      return JILISlide ? [JILISlide] : []
    } else if (sliderCurrentPage === "FortunePay") {
      const FortuneSliide = slides.find((slide) => slide.id === "FortunePay")
      return FortuneSliide ? [FortuneSliide] : []
    } else if (sliderCurrentPage === "MonthlyRollOut") {
      const MonthlyRollOutSliide = slides.find(
        (slide) => slide.id === "MonthlyRollOut"
      )
      return MonthlyRollOutSliide ? [MonthlyRollOutSliide] : []
    } else if (sliderCurrentPage === "FortunePay") {
      const FortuneSliide = slides.find((slide) => slide.id === "FortunePay")
      return FortuneSliide ? [FortuneSliide] : []
    } else if (sliderCurrentPage === "all") {
      return slides.filter((slide) => !slide.hidden)
    } else if (sliderCurrentPage === "JiliHappyHour") {
      const happyHourSlide = slides.find(
        (slide) => slide.id === "JiliHappyHour"
      )
      return happyHourSlide ? [happyHourSlide] : []
    } else {
      return slides.filter(
        (slide) =>
          (slide.showOnPages.includes("all") ||
            slide.showOnPages.includes(sliderCurrentPage)) &&
          !slide.hidden
      )
    }
  }

  const filteredSlides = getFilteredSlides(slides, sliderCurrentPage)

  const activeSlideIndex = filteredSlides.findIndex(
    (slide) => slide.id === bannerCurrentPage
  )

  if (activeSlideIndex > 0) {
    const [activeSlide] = filteredSlides.splice(activeSlideIndex, 1)
    filteredSlides.unshift(activeSlide)
  }

  const renderSlide = (slide, index, totalSlides) => {
    const isHomePage = window.location.pathname === "/" // Check if it's the homepage
    const isFirstSlide = index === 0 // Check if it's the first slide
    const Tag = isFirstSlide ? "h1" : "h2" // Use h1 for the first slide, h2 for others

    const imgSrc = isMobile && slide.mobileSrc ? slide.mobileSrc : slide.src
    const isSlideInMobile = isMobile && ["JILI"].includes(slide.id)
    const textColor = { color: isSlideInMobile ? "#fff" : slide.textColor }
    const isSlideInContent =
      isMobile && ["CashBack", "ReferralBonus"].includes(slide.id)

    const slideClass = slide.className || ""

    return (
      <div key={slide.id} className={slideClass}>
        <a
          onClick={() => handleLinkClick(slide)}
          className="slide-container"
          style={{ display: "block", textDecoration: "none", color: "inherit" }}
        >
          <div
            className={`hero-banner-wrapper heroBannerWrapper-${slide.position}`}
            style={textColor}
          >
            <Tag
              className={`headingTag ${isFirstSlide ? "active-slide" : ""} ${
                slide.hideContent || isSlideInContent ? "hidden" : ""
              }`}
            >
              {isHomePage && isFirstSlide
                ? "The Best Online Casino for Filipino Players in 2024"
                : slide.alt}
            </Tag>
            {!slide.hideContent && !isSlideInContent && (
              <>
                <p className="slide-description">{slide.description}</p>
                {!slide.hideButton && (
                  <a onClick={() => handleLinkClick(slide)} className="heroBtn">
                    {slide.buttonText}
                  </a>
                )}
              </>
            )}
          </div>
          {isMobile && slide.overlay && <div className="overlay"></div>}
          <img src={imgSrc} alt={slide.alt} className="slide-image" />
        </a>
      </div>
    )
  }

  return (
    <div className="sg8-hero-banner">
      <Slider {...settings}>
        {filteredSlides.map((slide, index) =>
          renderSlide(slide, index, filteredSlides.length)
        )}
      </Slider>
    </div>
  )
}

export default MainSlider

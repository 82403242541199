import React, { useState, useEffect, useRef, useContext } from "react"

const CouponRedeem = () => {
  const [couponCode, setCouponCode] = useState("")
  const [message, setMessage] = useState("")
  const leaderboardApi = process.env.REACT_APP_LEADERBOARD

  const handleSubmit = (e) => {
    const username = localStorage.getItem("username")
    e.preventDefault()

    const apiUrl = `${leaderboardApi}?cmd=redeemSMVoucher&u=${username}&code=${couponCode.toUpperCase()}`

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if (data.e) {
          setMessage(data.e)
        } else {
          setMessage(data.s)
        }
      })
      .catch((error) => {
        console.error("Error redeeming coupon:", error)
      })

    setCouponCode("")
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          id="couponCode"
          className="coupon-redeem-area"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
          placeholder="Enter your coupon"
        />
        <button className="coupon-redeem-button" type="submit">
          Redeem
        </button>
      </form>
      {message && <p>{message}</p>}
    </div>
  )
}

export default CouponRedeem

import React, { useState, useEffect, useRef, useContext } from "react"
import axios from "axios"
import Popup from "reactjs-popup"
import { GlobalContext } from "./GlobalContextProvider"
import { checkSchedule } from "./checkSchedule"

import { tokenURL, httpsLaunchURL, apiJSONURL } from "../pages/LiveCasino"

const GameList = ({ provider, filter, searchQuery }) => {
  const [gameStart, setGameStart] = useState()
  const [gameEnd, setGameEnd] = useState()
  const [games, setGames] = useState([])
  const [loading, setLoading] = useState(true)
  const [launching, setLaunching] = useState(false)
  const [currentFrame, setCurrentFrame] = useState(19)
  const [animationComplete, setAnimationComplete] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupMessage, setPopupMessage] = useState("")
  const [topGamesList, setTopGamesList] = useState("")
  const [gamePopupWindow, setGamePopupWindow] = useState(null)
  const [isUnique, setIsUnique] = useState()
  const [isProvider, setIsProvider] = useState()
  const totalFrames = 77
  // const [uniqueGames, setUniqueGames] = useState(0);
  const { activePopup, setActivePopup } = useContext(GlobalContext)
  const { nJGames, setNJGames } = useContext(GlobalContext)
  const { uniqueGames, setUniqueGames } = useContext(GlobalContext)
  const { gameTime, setGameTime } = useContext(GlobalContext)
  const { isInGame, setIsInGame } = useContext(GlobalContext)
  const { nonFeaturedGames, setNonFeaturedGames } = useContext(GlobalContext)
  const imageRef = useRef(null)

  const isMobile = window.innerWidth <= 840

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const domain = isMobile ? "&m=1" : ""
        const response = await axios.post(
          apiJSONURL,
          `cmd=getGame&p=${provider}${domain}&domain=sg8`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        const jsonData = response.data
        const loadedGames = Object.values(jsonData).map((data) => ({
          gameId: data[0],
          gameName: data[1],
          gameImg: data[2],
          gameDemo: data[3],
          gameCategory: data[4],
          gameTab: data[5],
        }))
        setGames(loadedGames)
        setLoading(false)
      } catch (error) {
        console.error("Failed to fetch games:", error)
        setLoading(false)
      }
    }

    fetchGames()
  }, [provider, isMobile])

  useEffect(() => {
    const checkPopupClosed = () => {
      if (gamePopupWindow && gamePopupWindow.closed) {
        let newUG = uniqueGames
        let nJ = nJGames
        let nF = nonFeaturedGames
        setGameEnd(Date.now())
        console.log("Popup window closed.")
        // Custom logic when the popup closes
        if (provider != "jili") {
          nJ += 1
          setNJGames(nJ)
          localStorage.setItem("nJGames", nJ)
        }
        if (nJGames > 2) {
          localStorage.setItem("nJGames", "0")
          setNJGames(0)
        }
        if (isProvider) {
          nF += 1
          setNonFeaturedGames(nF)
          localStorage.setItem("nonFeaturedGames", nF)
        }
        if (nonFeaturedGames > 2) {
          localStorage.setItem("nonFeaturedGames", "0")
          setNonFeaturedGames(0)
        }
        if (isUnique) {
          newUG += 1
          setUniqueGames(newUG)
          localStorage.setItem("uniqueGames", newUG)
        }
        if (uniqueGames > 5) {
          setUniqueGames(0)
          localStorage.setItem("uniqueGames", "0")
        }
        // Cleanup the popup reference
        setIsInGame(false)
        setGamePopupWindow(null)
      }
    }

    // Set interval to periodically check if the popup is closed
    const interval = setInterval(checkPopupClosed, 1000)

    // Cleanup interval on component unmount or popup state change
    return () => clearInterval(interval)
  }, [gamePopupWindow])

  useEffect(() => {
    const fetchTopGamesList = async () => {
      try {
        const response = await axios.post(
          apiJSONURL,
          `cmd=getTopGames&domain=sg8`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )

        const jsonData = response.data
        let transformedData = {}
        let idList = []
        // Iterate over jsonData and transform it into the desired format
        for (let key in jsonData) {
          if (jsonData.hasOwnProperty(key)) {
            let splitValues = jsonData[key]
              .split("|")
              .map((value) => value.trim())
            transformedData[key] = splitValues[3]
            idList.push(transformedData[key])
          }
        }

        // Update dataList state with transformed data
        setTopGamesList(idList)
        setLoading(false)
      } catch (error) {
        console.error("Failed to fetch games:", error)
        setLoading(false)
      }
    }
    fetchTopGamesList()
  }, [])

  useEffect(() => {
    let interval
    if (loading || launching) {
      interval = setInterval(() => {
        setCurrentFrame((prevFrame) => {
          if (prevFrame < totalFrames) {
            return prevFrame + 1
          } else {
            setAnimationComplete(true)
            clearInterval(interval)
            return totalFrames
          }
        })
      }, 30)
    }
    return () => clearInterval(interval)
  }, [loading, launching])

  const saveRecentGame = (game) => {
    game["provider"] = provider
    let recentGames = JSON.parse(localStorage.getItem("recentGames")) || []
    recentGames = recentGames.filter((g) => g.gameId !== game.gameId)
    recentGames.unshift(game)
    if (recentGames.length > 5) {
      recentGames.pop()
    }
    localStorage.setItem("recentGames", JSON.stringify(recentGames))
  }

  useEffect(() => {
    // Time spent playing
    // Calculate the duration in minutes
    const duration = (gameEnd - gameStart) / (1000 * 60) // Convert milliseconds to minutes
    if (duration > 14.99 && provider != "fachai") {
      setGameTime(true)
    }
  }, [gameEnd, gameStart])

  const waitForResolve = () => {
    return new Promise((resolve) => {
      // Monitor changes in activePopup until it becomes null
      const checkPopup = setInterval(() => {
        if (activePopup === null) {
          clearInterval(checkPopup) // Stop checking once activePopup is null
          resolve() // Resolve the promise and continue
        }
      }, 100) // Poll every 100ms, adjust as needed
    })
  }

  const launchGame = async (game) => {
    if (!document.body.classList.contains("isLoggedIn")) {
      setPopupMessage("Please register and login to play the game.")
      setIsPopupOpen(true)
      return
    }

    setLaunching(true)
    setCurrentFrame(19)
    setAnimationComplete(false)
    if (imageRef.current) {
      imageRef.current.classList.remove("pop")
    }

    try {
      const username = localStorage.getItem("username")
      // const encryptedUsernameResponse = await axios.post(
      //   apiJSONURL,
      //   `cmd=getToken&u=${username}`
      // );
      //const encryptedUsername = encryptedUsernameResponse.data.token;
      const encryptedUsername = encodeURIComponent(
        localStorage.getItem("encryptedUsername")
      )

      const tokenResponse = await axios.get(
        `${tokenURL}?u=${encryptedUsername}&g=${game.gameId}&t=transfer&m=mobile`
      )
      const sessionToken = tokenResponse.data.token

      const lang = "en"
      const mobileIndicator = isMobile ? "1" : ""
      const url = `${httpsLaunchURL}?s=${sessionToken}&l=${lang}&m=${mobileIndicator}`
      console.log("Launching game with URL:", url)

      const launchGameAfterAnimation = async () => {
        //Compare launched game to recent game
        const rgJson = JSON.parse(localStorage.getItem("recentGames"))
        setGameStart(Date.now())
        setIsInGame(true)
        console.log(rgJson)
        if (Array.isArray(rgJson)) {
          const gameIdNs = rgJson.map((game) => game.gameIdN)
          const gameIds = rgJson.map((game) => game.gameId)
          const mroProvider = checkSchedule("2024-12-01T00:00:00")
            ? "pgsoft"
            : "fachai"
          if (game.provider != mroProvider) {
            //check if featured provider
            setIsProvider(true)
          }
          if (
            !gameIdNs.includes(game.gameId) &&
            !gameIds.includes(game.gameId)
          ) {
            setIsUnique(true)
            console.log(`Game with ID ${game.gameId} does not exist.`)
          }
        } else {
          console.error("recentGames is not an array")
        }
        // //put banner code here?
        //  if (localStorage.getItem("uniqueGames") > 5 && localStorage.getItem("nJGames") < 3 && activePopup === null )
        //    {
        //      setActivePopup("topGames");
        //    }
        //    console.log("Current popup: "+ activePopup);
        //    if (activePopup === "topGames")
        //    {
        //     await waitForResolve();
        //    }
        const popupWindow = window.open(
          url,
          "_blank",
          "toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=600"
        )
        setGamePopupWindow(popupWindow)
        if (popupWindow) {
          popupWindow.focus()
        } else {
          setPopupMessage("Please disable your pop-up blocker and try again.")
          setIsPopupOpen(true)
        }
        setLaunching(false)
        saveRecentGame(game)
      }

      if (animationComplete) {
        launchGameAfterAnimation()
      } else {
        setTimeout(() => {
          launchGameAfterAnimation()
        }, (totalFrames - currentFrame) * 30)
      }
    } catch (error) {
      console.error("Failed to launch game:", error)
      setLaunching(false)
    }
  }

  const filterGames = () => {
    return games
      .filter((game) => {
        let matchesFilter = true
        if (filter) {
          switch (filter) {
            case "featured":
              matchesFilter = game.gameTab.includes("featured")
              break
            case "top":
              matchesFilter = game.gameTab && game.gameTab.includes("top")
              break
            case "slots":
              matchesFilter = game.gameCategory === 1
              break
            case "table":
              matchesFilter = game.gameCategory === 2
              break
            case "videopoker":
              matchesFilter = game.gameCategory === 5
              break
            case "arcade":
              matchesFilter = game.gameCategory === 4
              break
            case "jackpotslots":
              matchesFilter = game.gameCategory === 6
              break
            default:
              matchesFilter = true
              break
          }
        }

        if (searchQuery) {
          matchesFilter =
            matchesFilter &&
            game.gameName.toLowerCase().includes(searchQuery.toLowerCase())
        }

        return matchesFilter
      })
      .sort((a, b) => a.gameName.localeCompare(b.gameName))
  }

  if (loading) {
    return (
      <section className="loadingGames">
        <img
          src="/images/sg8.gif"
          style={{ width: "200px" }}
          alt="Loading..."
        />
      </section>
    )
  }

  const handleImageLoad = (event) => {
    event.target.style.opacity = 1
  }

  const filteredGames = filterGames()
  const isLoggedIn = document.body.classList.contains("isLoggedIn")
  return (
    <div
      className="gameListWrapper"
      style={{
        display: "grid",
        gridTemplateColumns:
          filteredGames.length > 0
            ? "repeat(auto-fit, minmax(200px, 1fr))"
            : "repeat(1, 1fr)",
      }}
    >
      <Popup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        modal
        nested
      >
        <div
          className="popupError"
          style={{ backgroundImage: `url('/images/registration-form-bg.jpg')` }}
        >
          <img src="/images/promo-mobile-icon.webp" alt="Promo" />
          <div className="content">{popupMessage}</div>
          <div className="actions">
            <button
              className="yellow-btn"
              onClick={() => setIsPopupOpen(false)}
            >
              OK
            </button>
          </div>
        </div>
      </Popup>
      {launching && isLoggedIn && (
        <div className="overlay">
          <div className="loadingBar">
            <div className={`spinner ${animationComplete ? "pop" : ""}`}>
              <img
                ref={imageRef}
                src={`/images/frameLoading/sg8logo_${String(
                  currentFrame
                ).padStart(5, "0")}.png`}
                alt="Loading..."
              />
            </div>
          </div>
        </div>
      )}
      {filteredGames.length > 0 ? (
        filteredGames.map((game, index) => (
          <div
            key={index}
            className="game"
            onClick={() => (isMobile ? launchGame(game) : null)}
          >
            <div className="gameImg">
              <img
                alt={`${game.gameName} ${provider.toUpperCase()}`}
                src={game.gameImg}
                onLoad={handleImageLoad}
                style={{ opacity: 0, transition: "opacity 0.5s" }}
              />
            </div>
            {topGamesList.includes(game.gameId) ? (
              <div className="top-label-game" bis_skin_checked="1">
                <a href="/slots/top-games/">TOP</a>
              </div>
            ) : (
              ""
            )}
            {!isMobile && (
              <div className="gameDetails">
                <div className="gameName">{game.gameName}</div>
                <button onClick={() => launchGame(game)}>Play Now</button>
              </div>
            )}
          </div>
        ))
      ) : (
        <section className="loadingGames">
          <div>
            <img src="/images/sg8-icon.webp" style={{ width: "60px" }} />
            <p>No games found for selected filter.</p>
          </div>
        </section>
      )}
    </div>
  )
}

export default GameList

import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import {currentPath, currentDomain} from "../../App";

export default function BetAndWin() {
  const title = "Bet & Win";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("BetAndWin");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("BetAndWin");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bet & Win at SG8 Casino | Win a prize - 1000 Winners Every Week</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Join the excitement and be one of the 1,000 lucky winners every week! A massive prize pool worth 1,375,000.00 at SG8 Casino"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />
        <h2>
          Bet & Win - 1000 Winners Every Week
        </h2>
        <p>Winners will share a prize worth 1,375,000.00 weekly</p>
        <p></p>
        <h2>General Mechanics:</h2>
        <ol>
          <li>
            All verified and registered players can join the promo during the specified period.
          </li>
          <li>Players who wagered an accumulated amount of at least 1,300 weekly on Evolution games during the promo period.</li>
          <li>
            Qualified Games: All approved Evolution (Live Dealer Games & First Person Games).
          </li>
          <li>
            Each week 1,000 winners will share 1,375,000 credits. Weekly period begins every Monday at 12:00 AM and ends on Sunday at 23:59 PM. Players' wagers will reset weekly.
            <ol>
                <li>If two or more players have the same wager amount, the player who reaches that amount first will be ranked higher.</li>
                <div className="table-main">
                    <table>
                        <thead style={{textAlign: "center"}}>
                            <th>Number of winners</th>
                            <th>Max Payout Prize (PHP)</th>
                            <th>Max Winning Total Prize Amount (PHP)</th>
                        </thead>
                        <tbody>
                        <tr>
                            <td>1</td>
                            <td>55,000.00</td>
                            <td>55,000.00</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>44,000.00</td>
                            <td>44,000.00</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>27,500.00</td>
                            <td>82,500.00</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>16,500.00</td>
                            <td>82,500.00</td>
                        </tr>
                        <tr>
                            <td>25</td>
                            <td>5,500.00</td>
                            <td>137,500.00</td>
                        </tr>
                        <tr>
                            <td>65</td>
                            <td>2,750.00</td>
                            <td>178,750.00</td>
                        </tr>
                        <tr>
                            <td>145</td>
                            <td>1,650.00</td>
                            <td>239,250.00</td>
                        </tr>
                        <tr>
                            <td>255</td>
                            <td>1,100.00</td>
                            <td>280,500.00</td>
                        </tr>
                        <tr>
                            <td>500</td>
                            <td>550.00</td>
                            <td>275,000.00</td>
                        </tr>
                        <tr>
                            <td>Total Number of Winners: 1,000</td>
                            <td>-</td>
                            <td style={{fontWeight: "bold"}}>Total: 1,375,000.00</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </ol>  
          </li>
          <li>
            Qualified members will remain eligible for prizes in subsequent periods.
          </li>
          <li>
            Winners will be announced and credited within three working days of the following week.
            <ol>
                <li>Announcements will be made on our SG8Casino social media page. </li>
                <li>Winners will be notified via Email and SMS.</li>
            </ol>
          </li>
        </ol>
        <h2>Terms and conditions:</h2>
        <ol>
          <li>All prizes and other marketing costs shall be shouldered by the Service Provider – SG8</li>
          <li>General SP-SG8 marketing terms and conditions apply</li>
          <li>SP-SG8 reserves the right to suspend or cancel the promotion at any time without prior notice and subject for PAGCOR’s approval.</li>
          <li>SP retains the authority to disqualify a participant from the promotion upon observing instances of counter or arbitrage betting. </li>
        </ol>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react"

const BalloonComponent = () => {
  const [balloons, setBalloons] = useState([])
  const [opacity, setOpacity] = useState(1)

  const random = (num) => Math.floor(Math.random() * num)

  const getRandomStyles = (isRed) => {
    const r = isRed ? 255 : 0
    const g = 0
    const b = isRed ? 0 : 0
    const mt = random(200)
    const ml = random(50)
    const dur = random(5) + 5
    return {
      backgroundColor: `rgba(${r},${g},${b},0.7)`,
      color: `rgba(${r},${g},${b},0.7)`,
      boxShadow: `inset -7px -3px 10px rgba(${r - 10},${g - 10},${b - 10},0.7)`,
      margin: `${mt}px 0 0 ${ml}px`,
      animation: `float ${dur}s ease-in infinite`,
    }
  }

  const createBalloons = (num) => {
    const newBalloons = Array.from({ length: num }, (_, index) => {
      const isRed = Math.random() < 0.5
      return {
        id: index,
        style: getRandomStyles(isRed),
      }
    })
    setBalloons(newBalloons)
  }

  useEffect(() => {
    createBalloons(8)
  }, [])

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          id="balloon-container"
          style={{ opacity, transition: "opacity 0.5s ease-in-out" }}
        >
          {balloons.map((balloon) => (
            <div
              key={balloon.id}
              className="balloon"
              style={balloon.style}
            ></div>
          ))}
        </div>
      </div>
    </>
  )
}

export default BalloonComponent

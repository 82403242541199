import React, { useState } from "react";

export default function Darts({ toggleModal, isOpen }) {

    return (
             <div className={`game-modal ${isOpen ? "show" : ""}`}>
            <button className='modal-close' onClick={toggleModal}>✖</button>
            <div className="game-modal-content">
                <h2>Darts</h2>
                <ol>
                    <li>To play for the first time, players must follow SG8 Casino and Skinny Mike’s social media platforms (Facebook, Instagram, Tiktok)</li>
                    <li>For subsequent rounds, players must deposit into their registered SG8 account. Deposit amounts will follow PAGCOR regulations.</li>
                    <li>Participants will have to show proof of registration/deposit to Skinny Mike staff present to be given the darts</li>
                    <li>Participants will be entitled to 5 tries
                    <br/>BONUS: If bet on sports, given 3 additional tries (ie. total 8 tries)</li>
                    <li>The rings on the board will determine the prizes
                        <br/>
                        <ol>
                            <li>Hit dart on any number = 5 raffle tickets </li>
                            <li>Hit dart on the number ending with 8 = Free Beer </li>
                            <li>Hit dart twice on the number ending with 8= Spin the Wheel  </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
    );
}
import React from "react"
const handleLogout = () => {
  localStorage.removeItem("isLoggedIn")
  localStorage.removeItem("username")
  localStorage.removeItem("encryptedUsername")
  localStorage.removeItem("modalShown")
  sessionStorage.removeItem("hasShownSlotMachine")
  window.location.href = "/" // Redirect to homepage after logout
}

export default function LogoutFunction() {
  return (
    <span className="logout-BTN">
      <a onClick={handleLogout}>
        <img src="/images/logout.webp" alt="logout icon" />
        <p>LOGOUT</p>
      </a>
    </span>
  )
}

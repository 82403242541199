import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import ReactDOM from "react-dom"
import { checkSchedule } from "./checkSchedule"
import "./../style/gamestyle.css"

const GoldenNestJackpotSection = () => {
  const [currentValue, setCurrentValue] = useState(null)
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
  const [countdownMessage, setCountdownMessage] = useState(
    "Waiting to start..."
  )
  const [winners, setWinners] = useState([])
  const [showPopup, setShowPopup] = useState(false)

  const startDate = new Date("2024-12-16T00:00:00").getTime()
  const endDate = new Date("2024-12-31T23:59:59").getTime()

  useEffect(() => {
    const updateCountdown = () => {
      const now = Date.now()

      if (now < startDate) {
        const distance = startDate - now
        setCountdownMessage("Starts in :")
        setCountdown({
          days: Math.floor(distance / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((distance % (1000 * 60)) / 1000),
        })
      } else if (now <= endDate) {
        const distance = endDate - now
        setCountdownMessage("Ends in :")
        setCountdown({
          days: Math.floor(distance / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((distance % (1000 * 60)) / 1000),
        })
      } else {
        setCountdownMessage("The event has ended.")
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 })
      }
    }

    const interval = setInterval(updateCountdown, 1000)
    return () => clearInterval(interval)
  }, [startDate, endDate])

  useEffect(() => {
    const fetchJackpotAndWinners = async () => {
      try {
        const jackpotResponse = await fetch(
          "https://sg8.casino/json/wlp/json/indexPromo.jsp?cmd=getGoldenNestJackpot"
        )
        const jackpotData = await jackpotResponse.json()
        if (jackpotData?.jp) {
          setCurrentValue(jackpotData.jp)
        }

        const winnersResponse = await fetch(
          "https://sg8.casino/json/wlp/json/indexPromo.jsp?cmd=getGoldenNestJackpotWinners"
        )
        const winnersData = await winnersResponse.json()
        if (winnersData?.winners) {
          const winnersList = Object.values(winnersData.winners).map(
            (entry) => {
              const [
                username,
                provider,
                gameName,
                ,
                ,
                jackpotAmount,
                jackpotTime,
              ] = entry.split("|")
              return {
                username,
                provider,
                gameName,
                jackpotAmount: parseFloat(jackpotAmount).toFixed(2),
                jackpotTime,
              }
            }
          )
          setWinners(winnersList)
        }
      } catch (error) {
        console.error("Failed to fetch data:", error)
      }
    }

    fetchJackpotAndWinners()

    const interval = setInterval(fetchJackpotAndWinners, 30000)
    return () => clearInterval(interval)
  }, [])

  const formatValue = (value) => {
    const [whole, decimal] = value.toFixed(2).split(".")
    return { whole, decimal }
  }

  const popupContent = showPopup ? (
    <div className="popup-main-container">
      <div className="popup-main-content">
        <div className="popup-content-golden-nest">
          <h2>All Recent Winners</h2>
        </div>
        <div className="golden-nest-table-container">
          <table className="golden-nest-winners-table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Amount Won</th>
                <th>Played Game</th>
                <th>Game Provider</th>
                <th>Date of Winnings</th>
              </tr>
            </thead>
            <tbody>
              {winners.map((winner, index) => (
                <tr key={index}>
                  <td>{winner.username}</td>
                  <td>₱{winner.jackpotAmount}</td>
                  <td>{winner.gameName}</td>
                  <td>{winner.provider}</td>
                  <td>{new Date(winner.jackpotTime).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>{" "}
        <div className="golden-nest-closebtn">
          <button
            className="close-button-golden-nest"
            onClick={() => setShowPopup(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  ) : null


const winnersComponent = (
<div className="golden-nest-winners">
<p className="golden-nest-recent-winner-text">Winners :</p>
<div className="marquee-container">
  <div className="marquee">
    {winners.length > 0 ? (
      winners.map((winner, index) => (
        <span key={index} className="marquee-item">
          {winner.username} won ₱{winner.jackpotAmount} in{" "}
          {winner.gameName} ({winner.provider}) |
        </span>
      ))
    ) : (
      <span>No winners yet.</span>
    )}
  </div>
</div>
<p className="view-more-button" onClick={() => setShowPopup(true)}>
  View More
</p>
</div>);


  return (
    <>
      {ReactDOM.createPortal(popupContent, document.getElementById("root"))}
      {checkSchedule("2024-12-16T06:00:00") && winnersComponent}
      <div className="golden-nest-section animated-border">
        <div className="golden-coins">
          <img src="/images/left_img.webp" alt="" />
        </div>
        <div className="golden-coins-2">
          <img src="/images/left_img.webp" alt="" />
        </div>
        <div className="golden-coins-right">
          <img src="/images/progressive-coins.webp" alt="" />
        </div>
        <div className="golden-coins-left">
          <img src="/images/progressive-coins.webp" alt="" />
        </div>
        <div className="golden-coins-middle">
          <img src="/images/progressive-coins-middle.webp" alt="" />
        </div>
        <div className="golden-nest-text-area">
          <p>Panalo Ka Dito!</p>
          <h2>SG8 Grand Jackpot</h2>
          <p className="countdown-starts">
            {countdownMessage}{" "}
            {countdownMessage !== "The event has ended." && (
              <>
                <span>{countdown.days}d</span> : <span>{countdown.hours}h</span>{" "}
                <span>{countdown.minutes}m</span> :{" "}
                <span>{countdown.seconds}s</span>
              </>
            )}
          </p>
          <Link to="/promotion/progressive-jackpot/">
            <button>VIEW T&C</button>
          </Link>
        </div>
        <div className="golden-nest-main-container">
          <div className="golden-nest-title">
            <div className="golden-next-font-asset">
              <p className="ticker-title golden-asset-text-1">GOLDEN</p>
              <p className="ticker-title golden-asset-text-2">NEST</p>
            </div>
            <p className="ticker-title golden-asset-text-3">JACKPOT</p>
            <div className="golden-nest-coins">
              <img
                src="/images/golden-nest-coins.webp"
                className="golden-nest-coins-1"
                alt=""
              />
              <img
                src="/images/golden-nest-coins-right.webp"
                className="golden-nest-coins-2"
                alt=""
              />
            </div>
          </div>
          <div className="ticker-row-container">
            <div className="ticker-row">
              <span className="peso-sign">₱</span>
              {currentValue !== null ? (
                <>
                  {formatValue(currentValue)
                    .whole.split("")
                    .map((digit, index) => (
                      <div key={index} className="ticker-container">
                        <div
                          className="ticker-reel"
                          style={{
                            transform: `translateY(-${digit * 60}px)`,
                            transition: "transform 1s ease-in-out",
                          }}
                        >
                          {[...Array(10)].map((_, i) => (
                            <div key={i} className="ticker-number">
                              {i}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  <span className="decimal-separator">.</span>
                  {formatValue(currentValue)
                    .decimal.split("")
                    .map((digit, index) => (
                      <div
                        key={`decimal-${index}`}
                        className="ticker-container decimal"
                      >
                        <div
                          className="ticker-reel"
                          style={{
                            transform: `translateY(-${digit * 60}px)`,
                            transition: "transform 1s ease-in-out",
                          }}
                        >
                          {[...Array(10)].map((_, i) => (
                            <div key={i} className="ticker-number">
                              {i}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                <span className="ticker-number">Loading...</span>
              )}
            </div>
          </div>
          <div className="golden-nest-character-assets">
            <img
              src="/images/golden-nest-asset-boxing.webp"
              className="golden-nest-character-1"
              alt=""
            />
            <img
              src="/images/golden-nest-asset-dragon.webp"
              className="golden-nest-character-2"
              alt=""
            />
            <img
              src="/images/golden-nest-asset-gods.webp"
              className="golden-nest-character-3"
              alt=""
            />
            <img
              src="/images/golden-nest-coins-bulk-1.webp"
              className="golden-nest-character-4"
              alt=""
            />
            <img
              src="/images/golden-nest-coins-bulk-1.webp"
              className="golden-nest-character-5"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default GoldenNestJackpotSection

import { useState, useEffect } from "react"
import { apiJSONURL } from "../pages/LiveCasino"

const useFetchBalance = (triggerFetch) => {
  const [balance, setBalance] = useState(null)
  const [breakdown, setBreakdown] = useState({})
  const [userData, setUserData] = useState({})

  useEffect(() => {
    const username = localStorage.getItem("encryptedUsername")
    if (username && username !== "null") {
      const fetchUserData = async () => {
        try {
          const response = await fetch(apiJSONURL, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
              cmd: "getBalance",
              u: username,
            }).toString(),
          })
          const data = await response.json()
          const balance = data.balance
          setBalance(balance)
          setBreakdown(data)
          setUserData({ u: username })
        } catch (error) {
          console.error("Error fetching user data:", error)
        }
      }
      fetchUserData()
    }
  }, [triggerFetch])

  return { balance, breakdown, userData, setBalance, setBreakdown, setUserData }
}

export default useFetchBalance

import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiJSONURL } from '../pages/LiveCasino';
const dataLink = process.env.REACT_APP_DATA;

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showLoginOtpForm, setLoginShowOtpForm] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(true);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [loginOtpSuccess, setLoginOtpSuccess] = useState(false);
  const [closeOtpForm, setCloseOtpForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState('');
  const [countdown, setCountdown] = useState(0);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const currentPath = window.location.pathname;
  async function encrypt(text) {
    const response = await axios.post(
      apiJSONURL,
      null,
      {
        params: {
          cmd: "getKey",
        },
      }
    );
    const key = response.data.key;
    const iv = key;
    const encodedText = new TextEncoder().encode(text);
    const cryptoKey = await window.crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode(key),
      { name: 'AES-CBC' },
      false,
      ['encrypt']
    );
    const encrypted = await window.crypto.subtle.encrypt(
      { name: 'AES-CBC', iv: new TextEncoder().encode(iv) },
      cryptoKey,
      encodedText
    );
    return new Uint8Array(encrypted);
  }
  
  function uint8ArrayToBase64(uint8Array) {
    let binary = '';
    const len = uint8Array.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(uint8Array[i]);
    }
    return btoa(binary);
  }

  async function getEncryptedUsername(data){
      try
      {if (localStorage.getItem("username")){
          await encrypt(data).then(encrypted => {
          localStorage.setItem('encryptedUsername', uint8ArrayToBase64(encrypted));
          });
      }}
      catch (error) {
        console.error("Failed to get the key:", error);
    }
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(dataLink, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          cmd: "loginjs",
          u: username,
          p: password,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      if (data.username){
      await encrypt(data.username).then(encrypted => {
        localStorage.setItem('encryptedUsername', uint8ArrayToBase64(encrypted));
      });}
      handleResponse(data);
    } catch (error) {
      setErrorMessage(
        "There was a problem with the login operation. Please try again."
      );
      console.error("There was a problem with the fetch operation:", error);
    }
  };
  
  const HandleLoginOtp = async () => {
    
    if (!mobile) {
    setErrorMessage("Please enter your phone number");
    return;
    }

    if (mobile.length !== 11) {
      setErrorMessage("Phone number must be 11 characters long");
      return;
    }

    if (!/^\d+$/.test(mobile)) {
      setErrorMessage("Phone number must only include numbers");
      return;
    }

    if (!/^09/.test(mobile)) {
      setErrorMessage("Phone number must start with 09");
      return;
    }
    
    try {
      setLoading(true);
      const response = await axios.post(
        dataLink,
        null,
        {
          params: {
            cmd: "getLoginOTP",
            mobile: mobile,
          },
        }
      );
      if (response.data.success) {
        setLoginOtpSuccess(true);
        setCloseOtpForm(true);
        setCountdown(60)
      }
    } catch (error) {
      console.error("Error getting OTP:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(timer); // Clear the timer when countdown reaches 0 or component unmounts
    }
  }, [countdown]);

  const handleResendOTP = async () => {
    if (countdown > 0) return; // Prevent resending if countdown is active

    try {
      const response = await axios.post(
        dataLink,
        null,
        {
          params: {
            cmd: "resendOTP",
            mobile: mobile,
          },
        }
      );
      setCountdown(60); // Start the countdown after OTP is sent
    } catch (error) {
      console.error("Error getting OTP:", error);
    }
  };

  const handleOTP = async () => {
    setLoading(true);

    try {
      const response = await fetch(dataLink, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams({
          cmd: 'loginWithOTP',
          mobile: mobile,
          otp: otp
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.u) {
        await encrypt(data.u).then(encrypted => {
          localStorage.setItem('encryptedUsername', uint8ArrayToBase64(encrypted));
        });
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("registered", "true");
        localStorage.setItem('username', data.u);
        setIsLoggedIn(true);
        if (window.location.pathname === "/promotion/skinny-mikes/" || window.location.pathname === "/promotion/skinny-mikes") {
               window.location.href = "/promotion/skinny-mikes/";
             }else{
              window.location.href = "/";
             }
      } else {
        setOtpErrorMessage(data.e);
      }
    } catch (error) {
      setOtpErrorMessage("An unexpected error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const getOtp = async () => {
    try {
      const response = await axios.post(
        dataLink,
        null,
        {
          params: {
            cmd: "getOTP",
            mobile: mobile,
          },
        }
      );
      if (response.data.s) {
        setOtpSuccess(true); // Show success message
      }
    } catch (error) {
      console.error("Error getting OTP:", error);
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        dataLink,
        null,
        {
          params: {
            cmd: "changepasswordWithOTP",
            mobile: mobile,
            otp: otp,
            password: newPassword,
          },
        }
      );
      if (response.data.s) {
        setErrorMessage(
          "Password changed successfully, please proceed to login with your new password."
        );
        setOtpSuccess(false);
        setShowOtpForm(false);
        setCloseOtpForm(false);
      } else {
        setErrorMessage(response.data.e || "Error changing password.");
      }
    } catch (error) {
      setErrorMessage(
        "There was an error changing the password. Please try again."
      );
      console.error("Error changing password:", error);
    }
  };

  const handlePasswordClick = () => {
    setShowPasswordForm(true);
    setLoginShowOtpForm(false);
  };

  const handleLoginOtpClick = () => {
    setLoginShowOtpForm(true);
    setShowPasswordForm(false);
  };

  const handleOtpClick = () => {
    setShowOtpForm(true);
    setCloseOtpForm(true);
  };

  const handleCloseOtpClick = () => {
    setShowOtpForm(false);
    setCloseOtpForm(false);
    setOtpSuccess(false);
  };

  const handleInputChange = (e) => {
    setMobile(e.target.value);
  };



  const handleResponse = (data) => {
    if (data.s === "success") {
      if (rememberMe) {
        localStorage.setItem("username", data.username);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("rememberMe", "true");
      }
      localStorage.setItem("username", data.username);
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("rememberMe", "true");
    //   if (localStorage.getItem("username")){
    //      encrypt(data.username).then(encrypted => {
    //          localStorage.setItem('encryptedUsername', uint8ArrayToBase64(encrypted));
    //     });
    //  }
      setUsername(data.username);
      setIsLoggedIn(true);
      setErrorMessage("");
      /* Calumpit Filter Code */
      if (window.location.pathname === "/promotion/skinny-mikes/" || window.location.pathname === "/promotion/skinny-mikes") {
             window.location.href = "/promotion/skinny-mikes/";
           }
           else{
            window.location.href = "/";
           }
    } else {
      setErrorMessage(data.emsg || "Login failed. Please try again.");
      console.error("Login failed:", data);
    }
  };

  
  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };

  // useEffect(() => {
  //   if (window.location.pathname === "/promotion/skinny-mikes/" || window.location.pathname === "/promotion/skinny-mikes") {
  //     window.location.href = "/";
  //   }
  // }, []);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem("isLoggedIn") === "true";
    const savedUsername = localStorage.getItem("username");
    const savedRememberMe = localStorage.getItem("rememberMe") === "true";
    if (savedRememberMe && loggedInStatus && savedUsername) {
      setIsLoggedIn(true);
      setUsername(savedUsername);
      setRememberMe(true);

      /* Calumpit Filter Code */
      if (
        (window.location.pathname === "/sportsbook/"
        // ||
        //  window.location.pathname === "/account/deposit/" ||
        //  window.location.pathname === "/account/withdraw/"
        ) &&
        savedUsername.startsWith("SG8JPCP")
      ) {
        window.location.href = "/";
      }
    }

    if (savedUsername) {
      // const elementsToToggle = [
      //   "depo-btn",
      //   "withdraw-btn",
      //   "depo-inactive-btn",
      //   "withdraw-inactive-btn",
      // ];
      // elementsToToggle.forEach((className) => {
      //   const elements = document.getElementsByClassName(className);
      //   for (let element of elements) {
      //     element.style.display = savedUsername.startsWith("SG8JPCP")
      //       ? "none"
      //       : "block";
      //   }
      // });

      const promoElements =
        document.getElementsByClassName("sg8-calumpit-promo");
      for (let element of promoElements) {
        element.style.display = savedUsername.startsWith("SG8JPCP")
          ? "grid"
          : "none";
      }

      if (savedUsername.startsWith("SG8JPCP")) {
        const hiddenElements = document.getElementsByClassName("hidden");
        for (let element of hiddenElements) {
          element.style.display = "none";
        }
      }
    } else {
      const promoElements =
        document.getElementsByClassName("sg8-calumpit-promo");
      for (let element of promoElements) {
        element.style.display = "none";
      }
    }
  }, []);

  return (
    <div>
      {errorMessage && (
        <p
          style={{ position: "relative", fontSize: "0.8em", color: "#ff8f1b"}}
        >
          {errorMessage}
        </p>
      )}
      {showPasswordForm && (<form className="form-login" onSubmit={handleLogin}>
        <label>Username/Mobile No.</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username or Mobile No."
        />
        <label>Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <label>
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={handleRememberMe}
          />
          Remember Me
        </label>
        <button type="submit">Login</button>
        <button
          type="button"
          className="loginmethod-trigger"
          onClick={handleLoginOtpClick}
        >
          Login using OTP
        </button>
        <button
          type="button"
          className="forgetpw-trigger"
          onClick={handleOtpClick}
        >
          Forgot Password or Username?
        </button>
      </form>)}
      {showLoginOtpForm && (<div className="form-login">
        <label>Provide your mobile number</label>
        <input
          type="text"
          value={mobile}
          onChange={handleInputChange}
          placeholder="Enter mobile number"
        />
        <span className="notice">
          Notice: Please input your registered mobile number that starts with '0'.
        </span>
        <button onClick={HandleLoginOtp}>Send OTP</button>
        <button
          type="button"
          className="loginmethod-trigger"
          onClick={handlePasswordClick}
        >
          Login using password
        </button>
        <button
          type="button"
          className="forgetpw-trigger"
          onClick={handleOtpClick}
        >
          Forgot Password or Username?
        </button>
        {loading && <div className='loadingContainer'><img src="/images/sg8.gif" alt="Loading..." /><span>Processing...</span></div>}
      </div>)}
      {loginOtpSuccess && (
        <div className="success-otp">
          <label className="otplabel">OTP</label>
          <input
            className="otpinput"
            type="text"
            placeholder="OTP"
            autoComplete="off"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <button className={`resendotp ${countdown > 0 ? 'countdown-active' : ''}`} onClick={handleResendOTP} disabled={countdown > 0}>
            {countdown > 0 ? `Resend in ${countdown}s` : "Resend"}
          </button>
          <button onClick={handleOTP}>Login using OTP</button>
          {otpErrorMessage && (
          <p style={{ color: '#ff8400', fontWeight: '600', display: 'block,', textAlign: 'center', padding: '10px', fontSize: '.9em' }}>{otpErrorMessage}</p>
            )}
        </div>
      )}{loading && <div className='loadingContainer'><img src="/images/sg8.gif" alt="Loading..." /><span>Processing...</span></div>}
      {closeOtpForm && (
        <button className="otp-close" onClick={handleCloseOtpClick}>
          ✖
        </button>
      )}
      {showOtpForm && (
        <div className="formotp">
          <span className="notice">
            Notice: Please input your registered mobile number that starts with '0'.
          </span>
          <label>Registered Number.</label>
          <input
            type="text"
            value={mobile}
            onChange={handleInputChange}
            placeholder="Enter mobile number"
          />
          <button onClick={getOtp}>Submit</button>
        </div>
      )}
      {otpSuccess && (
        <div className="success-otp">
          <label className="otplabel">OTP</label>
          <input
            className="otpinput"
            type="text"
            placeholder="OTP"
            autoComplete="off"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <label>New Password</label>
          <input
            type="password"
            placeholder="New Password"
            autoComplete="off"
            required
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <label>Confirm New Password</label>
          <input
            type="password"
            placeholder="Confirm New Password"
            autoComplete="off"
            required
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
          <button onClick={handleChangePassword}>Change Password</button>
        </div>
      )}
      {closeOtpForm && (
        <button className="otp-close" onClick={handleCloseOtpClick}>
          ✖
        </button>
      )}
    </div>
  );
};

export default Login;

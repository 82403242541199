import React, { useState, useEffect } from "react"
import { apiJSONURL } from "./LiveCasino"
import Popup from "reactjs-popup"
import axios from "axios"
import qs from "qs"

const GcashBtn = (
  <div id="GcashBtn">
    <img src="/images/gcash-icon.webp" alt="QRPH" />
    Gcash
  </div>
)
const PayMayaBtn = (
  <div id="payMayaBtn">
    <img src="/images/maya.webp" alt="Maya" />
    Maya
  </div>
)
const EWalletBtn = (
  <div id="eWalletBtn">
    <img src="/images/ewallet-icon.webp" alt="eWallet" />
    eWallet
  </div>
)
const BankBtn = (
  <div id="dragonPayBtn">
    <img src="/images/bank-icon.webp" alt="DragonPay" />
    Bank Account
  </div>
)
const OTC = (
  <div id="cashBtn">
    <img src="/images/cash-icon.webp" alt="OTC" />
    OTC
  </div>
)
const QRPH = (
  <div id="cashBtn">
    <img src="/images/qr-icon.webp" alt="QRPH" />
    QRPH
  </div>
)

const Deposit = () => {
  const setting = false
  const defaultVisibleId = "Gcash"

  const getDefaultMethod = (id) => {
    if (id === "Gcash") {
      return "gcash"
    } else if (id === "PayMaya") {
      return "maya"
    } else if (id === "EWallet") {
      return "ewallet"
    } else if (id === "Bank") {
      return "bank"
    }
    return null
  }

  const [visibleId, setVisibleId] = useState(defaultVisibleId)
  const [amt, setAmount] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [bonusId, setBonusId] = useState("-1")
  const [method, setMethod] = useState(getDefaultMethod(defaultVisibleId))
  const [bonusList, setBonusList] = useState([])
  const [procIdOptions, setProcIdOptions] = useState([])
  const [selectedProcId, setSelectedProcId] = useState("")
  const [mbc, setMbc] = useState("")
  const [loading, setLoading] = useState(false)
  const [minValue, setMinValue] = useState("")
  const [minValueOTC, setMinValueOTC] = useState("")
  const [minValueDp, setMinValueDp] = useState("")
  const [qrData, setQrData] = useState("")
  const [msg, setMsg] = useState("")
  const [showQrCode, setShowQrCode] = useState(false)
  const [invalidInputMessage, setInvalidInputMessage] = useState("")
  const [inputMessageTimer, setInputMessageTimer] = useState(null)
  const getReturnUrl = (response) => {
    return response.data.base64Image
  }

  const getReturnMsg = (response) => {
    return response.data.depositId
  }

  useEffect(() => {
    const fetchBonusList = async () => {
      const username = localStorage.getItem("username")

      if (username) {
        try {
          const response = await axios.post(
            apiJSONURL,
            qs.stringify({
              cmd: "getBonusList",
              u: username,
            }),
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )

          const data = response.data
          if (setting) {
            if (data.ifDeposited === false) {
              setMinValue("500")
              setMinValueOTC("500")
              setMinValueDp("500")
            } else {
              setMinValue("20")
              setMinValueOTC("100")
              setMinValueDp("200")
            }
          } else {
            setMinValue("20")
            setMinValueOTC("100")
            setMinValueDp("200")
          }

          const bonusData = response.data.bonus
          if (bonusData) {
            setBonusList(bonusData)
            setBonusId("1") // Set default value to "No Bonus" after data is populated
          } else {
            console.error(
              "Bonus data not found in the response:",
              response.data
            )
          }
        } catch (error) {
          console.error("Error fetching bonus list:", error)
        }
      } else {
        console.error("Username not found in local storage")
      }
    }

    fetchBonusList()
  }, [])

  useEffect(() => {
    const fetchProcId = async () => {
      if (visibleId === "Bank") {
        try {
          const response = await axios.post(
            apiJSONURL,
            qs.stringify({
              cmd: "getDragonPayProcId",
            }),
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          const result = response.data
          // console.log(result);

          // Assuming response.data.s contains the options in string format
          if (result.s) {
            const tempDiv = document.createElement("div")
            tempDiv.innerHTML = result.s
            const options = Array.from(tempDiv.querySelectorAll("option")).map(
              (option) => ({
                value: option.value,
                text: option.text,
              })
            )
            setProcIdOptions(options)
          } else {
            console.error("No options received")
          }
        } catch (error) {
          console.error("Error fetching procId:", error)
        }
      }
    }

    fetchProcId()
  }, [visibleId])

  const checkDepositBonus = (bonusId, amount) => {
    let isError = false
    let message = ""
    if (bonusId == 10) {
      if (amount != 500) {
        message = "Invalid amount, only valid for 500 deposit."
        isError = true
      }
    } else if (bonusId == 11) {
      if (amount != 1000) {
        message = "Invalid amount, only valid for 1000 deposit."
        isError = true
      }
    } else if (bonusId == 12) {
      if (amount != 2000) {
        message = "Invalid amount, only valid for 2000 deposit."
        isError = true
      }
    } else if (bonusId == 13) {
      if (amount < 3000) {
        message = "Invalid amount, only valid for 3000 and above deposit."
        isError = true
      }
    }
    setAlertMessage(message)
    return isError
  }

  const handleDepositClick = (id) => {
    setVisibleId(id)
    setMethod(getDefaultMethod(id))
    setAmount("")
    setBonusId("1")
    setAlertMessage("")
    setInvalidInputMessage("")
    if (inputMessageTimer) {
      clearTimeout(inputMessageTimer)
      setInputMessageTimer(null)
    }
  }

  const handleSubmit = async (additionalPayload = {}) => {
    const username = localStorage.getItem("username")
    const amount = parseFloat(amt)
    const selectedBonusId = parseInt(bonusId)

    if (amount < parseFloat(minValue)) {
      setAlertMessage(`Amount must be at least ${minValue}`)
      return
    }

    if (checkDepositBonus(selectedBonusId, amount)) {
      return
    }

    setLoading(true)
    setAlertMessage("") // Clear any existing alert messages

    try {
      const response = await axios.post(
        apiJSONURL,
        qs.stringify({
          cmd: "deposit-request",
          method,
          u: username,
          amt: amount,
          bonusid: selectedBonusId,
          ...additionalPayload,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )

      console.log("API response:", response)
      const returnUrl = getReturnUrl(response)
      const returnMsg = getReturnMsg(response)
      setQrData(returnUrl)
      setMsg(returnMsg)
      // setShowQrCode(true);

      if (response.data && response.data.url) {
        window.location.href = response.data.url
      } else if (response.data && response.data.base64Image) {
        setShowQrCode(true)
      } else {
        setAlertMessage(response.data.emsg)
      }
    } catch (error) {
      console.error("API call error:", error)
      setAlertMessage(
        "An unexpected error occurred. Please contact support for assistance."
      )
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitQRPH = async (additionalPayload = {}) => {
    const username = localStorage.getItem("username")
    const amount = parseFloat(amt)
    const selectedBonusId = parseInt(bonusId)

    if (amount < parseFloat(minValue)) {
      setAlertMessage(`Amount must be at least ${minValue}`)
      return
    }

    if (checkDepositBonus(selectedBonusId, amount)) {
      return
    }

    setLoading(true)
    setAlertMessage("") // Clear any existing alert messages

    try {
      const response = await axios.post(
        apiJSONURL,
        qs.stringify({
          cmd: "deposit-request",
          method: "qrph",
          u: username,
          amt: amount,
          bonusid: selectedBonusId,
          ...additionalPayload,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )

      console.log("API response:", response)
      const returnUrl = getReturnUrl(response)
      const returnMsg = getReturnMsg(response)
      setQrData(returnUrl)
      setMsg(returnMsg)

      if (response.data && response.data.url) {
        window.location.href = response.data.url
      } else if (response.data && response.data.base64Image) {
        setShowQrCode(true)
      } else {
        setAlertMessage(response.data.emsg)
      }
    } catch (error) {
      console.error("API call error:", error)
      setAlertMessage(
        "An unexpected error occurred. Please contact support for assistance."
      )
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitOTC = async (additionalPayload = {}) => {
    const username = localStorage.getItem("username")
    const amount = parseFloat(amt)
    const selectedBonusId = parseInt(bonusId)

    if (amount < parseFloat(minValueOTC)) {
      setAlertMessage(`Amount must be at least ${minValueOTC}`)
      return
    }

    if (checkDepositBonus(selectedBonusId, amount)) {
      return
    }

    setLoading(true)
    setAlertMessage("") // Clear any existing alert messages

    try {
      const response = await axios.post(
        apiJSONURL,
        qs.stringify({
          cmd: "deposit-request",
          method: "otc",
          u: username,
          amt: amount,
          bonusid: selectedBonusId,
          ...additionalPayload,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )

      console.log("API response:", response)
      const returnUrl = getReturnUrl(response)
      const returnMsg = getReturnMsg(response)
      setQrData(returnUrl)
      setMsg(returnMsg)

      if (response.data && response.data.url) {
        window.location.href = response.data.url
      } else if (response.data && response.data.base64Image) {
        setShowQrCode(true)
      } else {
        setAlertMessage(response.data.emsg)
      }
    } catch (error) {
      console.error("API call error:", error)
      setAlertMessage(
        "An unexpected error occurred. Please contact support for assistance."
      )
    } finally {
      setLoading(false)
    }
  }

  const handleGcashSubmit = async () => {
    await handleSubmit()
  }

  const handlePaymayaSubmit = async () => {
    await handleSubmit()
  }

  const handleEWalletSubmit = async () => {
    await handleSubmit({ mbc: mbc })
  }

  const handleOTC = async () => {
    await handleSubmitOTC()
  }

  const handleQRPH = async () => {
    await handleSubmitQRPH()
  }

  const handleBankSubmit = async () => {
    const amount = parseFloat(amt) // Assuming 'amt' is accessible here

    if (amount < parseFloat(minValueDp)) {
      setAlertMessage(`Amount must be at least ${minValueDp}`)
      return
    }

    await handleSubmit({ procId: selectedProcId })
  }

  const handleKeyPress = (e) => {
    const charCode = e.charCode
    if (charCode < 48 || charCode > 57) {
      e.preventDefault()
      setInvalidInputMessage("Only numbers are allowed")
      const timer = setTimeout(() => {
        setInvalidInputMessage("")
      }, 3000)
      setInputMessageTimer(timer)
    } else {
      setInvalidInputMessage("")
    }
  }

  const handleChange = (e) => {
    const value = e.target.value
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value)
      setInvalidInputMessage("")
    } else {
      setInvalidInputMessage("Only numbers are allowed")
      const timer = setTimeout(() => {
        setInvalidInputMessage("")
      }, 3000)
      setInputMessageTimer(timer)
    }
  }

  return (
    <>
      <div className="account-container">
        <div className="page-headlines-container">
          <div className="page-h2-text-style">
            <h2>Deposit Request</h2>
            <span>Get our Best Deals from SG8.Casino</span>
          </div>
        </div>
        <div className="deposit-container">
          <div className="depositnav-nav nav-tabs">
            <a
              className={`${visibleId === "Gcash" ? "active" : ""}`}
              onClick={() => handleDepositClick("Gcash")}
            >
              {GcashBtn}
            </a>
            <a
              className={`${visibleId === "QRPH" ? "active" : ""}`}
              onClick={() => handleDepositClick("QRPH")}
            >
              {QRPH}
            </a>
            <a
              className={`${visibleId === "PayMaya" ? "active" : ""}`}
              onClick={() => handleDepositClick("PayMaya")}
            >
              {PayMayaBtn}
            </a>
            <a
              className={`${visibleId === "Bank" ? "active" : ""}`}
              onClick={() => handleDepositClick("Bank")}
            >
              {BankBtn}
            </a>
            <a
              className={`${visibleId === "EWallet" ? "active" : ""}`}
              onClick={() => handleDepositClick("EWallet")}
            >
              {EWalletBtn}
            </a>
            <a
              className={`${visibleId === "OTC" ? "active" : ""}`}
              onClick={() => handleDepositClick("OTC")}
            >
              {OTC}
            </a>
          </div>
          <div className="all-form-container">
            {visibleId === "Gcash" && (
              <form id="Gcash">
                <div className="form-title">{GcashBtn}</div>
                <div style={{ textAlign: "center" }}>
                  <div className="notice">
                    <div>
                      <strong>Notice: </strong>Please contact us if game credits
                      are not credited to your account within 10 minutes upon
                      successful payment.
                    </div>
                  </div>
                  <div className="row">
                    <div className="hLabel">Deposit Amount</div>
                    <div className="input-container">
                      <input
                        id="paycools-amount"
                        className="form-control txt-amount"
                        autoComplete="off"
                        max="30000"
                        maxLength="10"
                        min={minValue}
                        name="amt"
                        required="required"
                        type="text"
                        placeholder={`Min:${minValue}, Max:50,000`}
                        value={amt}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="hLabel">Bonus</div>
                    <div className="input-container">
                      <select
                        name="bonusid"
                        id="bonusListNonBTC"
                        className="form-control bonusList"
                        value={bonusId}
                        onChange={(e) => setBonusId(e.target.value)}
                      >
                        <option value="-1">-- Select Bonus --</option>
                        {bonusList.length > 0 && (
                          <option value="1">No Bonus</option>
                        )}
                        {bonusList &&
                          bonusList
                            .filter((bonus) => bonus.bonusid !== "1")
                            .map((bonus) => (
                              <option value={bonus.bonusid} key={bonus.bonusid}>
                                {bonus.bonusnameEN}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div style={{ textAlign: "center" }}>
                      <div
                        className="alert alert-info fade in alert-dismissable"
                        dangerouslySetInnerHTML={{ __html: alertMessage }}
                      ></div>
                      {invalidInputMessage && (
                        <div className="alert alert-danger numeric">
                          {invalidInputMessage}
                        </div>
                      )}
                      <button
                        className="yellow-btn"
                        type="button"
                        onClick={handleGcashSubmit}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}

            {visibleId === "PayMaya" && (
              <form id="PayMaya">
                <div className="form-title">{PayMayaBtn}</div>
                <div style={{ textAlign: "center" }}>
                  <div className="notice">
                    <div>
                      <strong>Notice: </strong>Please contact us if game credits
                      are not credited to your account within 10 minutes upon
                      successful payment.
                    </div>
                  </div>
                  <div className="row">
                    <div className="hLabel">Deposit Amount</div>
                    <div className="input-container">
                      <input
                        id="paymaya-amount"
                        className="form-control txt-amount"
                        autoComplete="off"
                        max="30000"
                        maxLength="10"
                        min={minValue}
                        name="amt"
                        required="required"
                        type="text"
                        placeholder={`Min:${minValue}, Max:50,000`}
                        value={amt}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="hLabel">Bonus</div>
                    <div className="input-container">
                      <select
                        name="bonusid"
                        id="bonusListNonBTC"
                        className="form-control bonusList"
                        value={bonusId}
                        onChange={(e) => setBonusId(e.target.value)}
                      >
                        <option value="-1">-- Select Bonus --</option>
                        {bonusList.length > 0 && (
                          <option value="1">No Bonus</option>
                        )}
                        {bonusList &&
                          bonusList
                            .filter((bonus) => bonus.bonusid !== "1")
                            .map((bonus) => (
                              <option value={bonus.bonusid} key={bonus.bonusid}>
                                {bonus.bonusnameEN}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div style={{ textAlign: "center" }}>
                      <div
                        className="alert alert-info fade in alert-dismissable"
                        dangerouslySetInnerHTML={{ __html: alertMessage }}
                      ></div>
                      {invalidInputMessage && (
                        <div className="alert alert-danger numeric">
                          {invalidInputMessage}
                        </div>
                      )}
                      <button
                        className="yellow-btn"
                        type="button"
                        onClick={handlePaymayaSubmit}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}

            {visibleId === "EWallet" && (
              <form id="EWallet">
                <div className="form-title">{EWalletBtn}</div>
                <div style={{ textAlign: "center" }}>
                  <div className="notice">
                    <div>
                      <strong>Notice: </strong>Please contact us if game credits
                      are not credited to your account within 10 minutes upon
                      successful payment.
                    </div>
                  </div>
                  <div className="row">
                    <div className="hLabel">eWallet</div>
                    <div className="input-container">
                      <select
                        value={mbc}
                        onChange={(e) => setMbc(e.target.value)}
                        name="mbc"
                        className="form-control"
                      >
                        <option value="">Select eWallet</option>
                        <option value="FORTUNEPAY">FortunePay</option>
                        <option value="COINS">Coins</option>
                        <option value="GRABPAY">GrabPay</option>
                        <option value="SHOPEE">ShopeePay</option>
                        <option value="OMNIPAY">OmniPay</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="hLabel">Deposit Amount</div>
                    <div className="input-container">
                      <input
                        id="fotunepay-amount"
                        className="form-control txt-amount"
                        autoComplete="off"
                        max="30000"
                        maxLength="10"
                        min={minValue}
                        name="amt"
                        required="required"
                        type="text"
                        placeholder={`Min:${minValue}, Max:50,000`}
                        value={amt}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="hLabel">Bonus</div>
                    <div className="input-container">
                      <select
                        name="bonusid"
                        id="bonusListNonBTC"
                        className="form-control bonusList"
                        value={bonusId}
                        onChange={(e) => setBonusId(e.target.value)}
                      >
                        <option value="-1">-- Select Bonus --</option>
                        {bonusList.length > 0 && (
                          <option value="1">No Bonus</option>
                        )}
                        {bonusList &&
                          bonusList
                            .filter((bonus) => bonus.bonusid !== "1")
                            .map((bonus) => (
                              <option value={bonus.bonusid} key={bonus.bonusid}>
                                {bonus.bonusnameEN}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div style={{ textAlign: "center" }}>
                      <div
                        className="alert alert-info fade in alert-dismissable"
                        dangerouslySetInnerHTML={{ __html: alertMessage }}
                      ></div>
                      {invalidInputMessage && (
                        <div className="alert alert-danger numeric">
                          {invalidInputMessage}
                        </div>
                      )}
                      <button
                        className="yellow-btn"
                        type="button"
                        onClick={handleEWalletSubmit}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}

            {visibleId === "OTC" && (
              <form id="OTC">
                <div className="form-title">{OTC}</div>
                <div style={{ textAlign: "center" }}>
                  <div className="notice">
                    <div>
                      <strong>Notice: </strong>Please contact us if game credits
                      are not credited to your account within 10 minutes upon
                      successful payment.
                    </div>
                  </div>
                  <div className="row">
                    <div className="hLabel">Deposit Amount</div>
                    <div className="input-container">
                      <input
                        id="paycools-amount"
                        className="form-control txt-amount"
                        autoComplete="off"
                        max="30000"
                        maxLength="10"
                        min="{minValueOTC}"
                        name="amt"
                        required="required"
                        type="text"
                        placeholder={`Min:${minValueOTC}, Max:50,000`}
                        value={amt}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="hLabel">Bonus</div>
                    <div className="input-container">
                      <select
                        name="bonusid"
                        id="bonusListNonBTC"
                        className="form-control bonusList"
                        value={bonusId}
                        onChange={(e) => setBonusId(e.target.value)}
                      >
                        <option value="-1">-- Select Bonus --</option>
                        {bonusList.length > 0 && (
                          <option value="1">No Bonus</option>
                        )}
                        {bonusList &&
                          bonusList
                            .filter((bonus) => bonus.bonusid !== "1")
                            .map((bonus) => (
                              <option value={bonus.bonusid} key={bonus.bonusid}>
                                {bonus.bonusnameEN}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div style={{ textAlign: "center" }}>
                      <div
                        className="alert alert-info fade in alert-dismissable"
                        dangerouslySetInnerHTML={{ __html: alertMessage }}
                      ></div>
                      {invalidInputMessage && (
                        <div className="alert alert-danger numeric">
                          {invalidInputMessage}
                        </div>
                      )}
                      <button
                        className="yellow-btn"
                        type="button"
                        onClick={handleOTC}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}

            {visibleId === "QRPH" && (
              <form id="QRPH">
                <div className="form-title">{QRPH}</div>
                <div style={{ textAlign: "center" }}>
                  <div className="notice">
                    <div>
                      <strong>Notice: </strong>Please contact us if game credits
                      are not credited to your account within 10 minutes upon
                      successful payment.
                    </div>
                  </div>
                  <div className="row">
                    <div className="hLabel">Deposit Amount</div>
                    <div className="input-container">
                      <input
                        id="paycools-amount"
                        className="form-control txt-amount"
                        autoComplete="off"
                        max="30000"
                        maxLength="10"
                        min={minValue}
                        name="amt"
                        required="required"
                        type="text"
                        placeholder={`Min:${minValue}, Max:50,000`}
                        value={amt}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="hLabel">Bonus</div>
                    <div className="input-container">
                      <select
                        name="bonusid"
                        id="bonusListNonBTC"
                        className="form-control bonusList"
                        value={bonusId}
                        onChange={(e) => setBonusId(e.target.value)}
                      >
                        <option value="-1">-- Select Bonus --</option>
                        {bonusList.length > 0 && (
                          <option value="1">No Bonus</option>
                        )}
                        {bonusList &&
                          bonusList
                            .filter((bonus) => bonus.bonusid !== "1")
                            .map((bonus) => (
                              <option value={bonus.bonusid} key={bonus.bonusid}>
                                {bonus.bonusnameEN}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div style={{ textAlign: "center" }}>
                      <div
                        className="alert alert-info fade in alert-dismissable"
                        dangerouslySetInnerHTML={{ __html: alertMessage }}
                      ></div>
                      {invalidInputMessage && (
                        <div className="alert alert-danger numeric">
                          {invalidInputMessage}
                        </div>
                      )}
                      <button
                        className="yellow-btn"
                        type="button"
                        onClick={handleQRPH}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}

            {visibleId === "Bank" && (
              <form id="Bank">
                <div className="form-title">{BankBtn}</div>
                <div style={{ textAlign: "center" }}>
                  <div className="notice">
                    <div>
                      <strong>Notice: </strong>Please contact us if game credits
                      are not credited to your account within 10 minutes upon
                      successful payment.
                    </div>
                  </div>

                  <div className="row">
                    <div className="hLabel">Payment Options</div>
                    <div className="input-container">
                      <select
                        id="dragonPayProcId"
                        name="procId"
                        className="formSelect"
                        value={selectedProcId}
                        onChange={(e) => setSelectedProcId(e.target.value)}
                      >
                        <option value="">-- Select Option --</option>
                        {procIdOptions.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="hLabel">Deposit Amount</div>
                    <div className="input-container">
                      <input
                        id="fotunepay-amount"
                        className="form-control txt-amount"
                        autoComplete="off"
                        max="30000"
                        maxLength="10"
                        min={minValueDp}
                        name="amt"
                        required="required"
                        type="text"
                        placeholder={`Min:${minValueDp}, Max:50,000`}
                        value={amt}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="hLabel">Bonus</div>
                    <div className="input-container">
                      <select
                        name="bonusid"
                        id="bonusListNonBTC"
                        className="form-control bonusList"
                        value={bonusId}
                        onChange={(e) => setBonusId(e.target.value)}
                      >
                        <option value="-1">-- Select Bonus --</option>
                        {bonusList.length > 0 && (
                          <option value="1">No Bonus</option>
                        )}
                        {bonusList &&
                          bonusList
                            .filter((bonus) => bonus.bonusid !== "1")
                            .map((bonus) => (
                              <option value={bonus.bonusid} key={bonus.bonusid}>
                                {bonus.bonusnameEN}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                  {visibleId === "Bank" && showQrCode && (
                    <div className="qrCode">
                      <p>
                        <span className="highlight">Pay to:</span> SG8
                      </p>
                      <section className="box-content">
                        <p>
                          <span className="highlight">Note:</span> This is
                          One-time payment transaction. Do not refresh your
                          browser
                        </p>
                        <div>
                          <img src={qrData} alt="QR Code" />
                        </div>
                        <div className="message">
                          <p>
                            Your deposit request{" "}
                            <span className="depositId">#{msg}</span> has been
                            received, please proceed to make your payment
                            through this code.
                          </p>
                        </div>
                        <div>
                          <a
                            href={qrData}
                            className="yellow-btn download-qr"
                            download="SG8-DEPOSIT-QR"
                          >
                            Save QR Code
                          </a>
                        </div>
                      </section>
                      <Popup
                        trigger={
                          <div className="instruction">
                            {" "}
                            Payment Instruction{" "}
                          </div>
                        }
                        modal
                        nested
                      >
                        {(close) => (
                          <div
                            className="qrCodeContent"
                            style={{
                              backgroundImage: `url('/images/registration-form-bg.jpg')`,
                            }}
                          >
                            <button className="close" onClick={close}>
                              &times;
                            </button>
                            <div className="content">
                              <ol>
                                <li>
                                  <h3>Download QR Code</h3>
                                  <p>
                                    Open your E-wallet/Bank app and upload it to
                                    your app
                                  </p>
                                  <img
                                    src="/images/payment-qr-code.webp"
                                    alt=""
                                  />
                                </li>
                                <li>
                                  <h3>Or Scan QR Code</h3>
                                  <p>
                                    Scan the QR Code using your E-Wallet/Bank
                                    app
                                  </p>
                                </li>
                              </ol>
                            </div>
                          </div>
                        )}
                      </Popup>
                    </div>
                  )}
                  <div className="row">
                    <div style={{ textAlign: "center" }}>
                      <div
                        className="alert alert-info fade in alert-dismissable"
                        dangerouslySetInnerHTML={{ __html: alertMessage }}
                      ></div>
                      {invalidInputMessage && (
                        <div className="alert alert-danger numeric">
                          {invalidInputMessage}
                        </div>
                      )}
                      <button
                        className="yellow-btn"
                        type="button"
                        onClick={handleBankSubmit}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
          {loading && (
            <div className="loadingContainer">
              <img src="/images/sg8.gif" alt="Loading..." />
              <span>Processing...</span>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default Deposit

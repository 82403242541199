import React, { useState, useEffect } from 'react';
import Login from '../api/Login';
import Slider from 'react-slick';

function LoginModal() {
  var settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 200,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const bannerList = [
    { id: 1, image: "/images/promotion/paydaybonusModal.jpg", alt: "payday at sg8" },
    { id: 2, image: "/images/promotion/depositbonusModal.jpg", alt: "grab our 150% deposit bonus" },
    { id: 3, image: "/images/promotion/welcomebonusModal.webp", alt: "Register to get 30 Pesos Bonus Credits" },
  ];

  // Shuffle the images inside of bannerList
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const [shuffledBannerList, setShuffledBannerList] = useState([]);

  useEffect(() => {
    setShuffledBannerList(shuffleArray([...bannerList]));
  }, []);

  const listItems = shuffledBannerList.map((data) => (
    <div key={data.id}>
      <img src={data.image} alt={data.alt} />
    </div>
  ));

  return (
    <>
      <div className='modal-container modal-login'>
        <div className='modal-banner'>
          <Slider {...settings} style={{ width: "255px", zIndex: "9999", objectFit: 'contain'}}>
            {listItems}
          </Slider>
        </div>
        <div style={{ width: "100%", padding: "15px" , position: 'relative', outline: "none"}}>
          <img className='formlogo' src='/images/SG8-logo.png' alt='sg8 casino logo' />
          <Login />
        </div>
      </div>
    </>
  );
}

export default LoginModal;
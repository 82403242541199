import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import SlotsFilter from "../../snippets/SlotsFilter";
import SlotSearchList from '../../snippets/SlotSearchList';
import PaymentMethod from '../../snippets/PaymentMethod';
import {currentPath, currentDomain} from '../../App';
import TopGameList from "../../snippets/TopGameList";


function TopGames() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Play the Most Popular Jili Slots at SG8 - Don't Miss Out!</title> 
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta name="description" content="SG8 has the top slot games, including Jili slots and other fan favorites. Start playing now and win big!"/>
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <div className="main-wrapper">
        <div className="main-content-area">
          <div class="page-headlines-container">
          <div className='pageHeaderText'>
          <h1>Our top Jili games are waiting for you! Ready to play</h1>
          </div>
          </div>
      <TopGameList limit={50}/>
        </div>
      </div>

      <div className="main-content-wrapper">
            <div className="text-content-area">
              <h2>Online Slots at SG8 Casino</h2>
              <p>Experience the best online slots at SG8 Casino, where Filipino players can enjoy a secure and fair gaming environment regulated by PAGCOR. Our extensive collection of online slots offers a thrilling gaming experience from the comfort of your home. Enjoy seamless gameplay, high-quality graphics, and the chance to win big with every spin. SG8 Casino is dedicated to providing a top-tier gaming experience that keeps you entertained and engaged.</p>
              <h2>Play JILI Games, RTG Slots & More</h2>
              <p>SG8 Casino features a wide range of slots from over 20 top providers, including popular games from JILI, RTG, PG Soft, Fa Chai, and NetEnt. These providers are known for their engaging gameplay and stunning graphics, making every gaming session exciting. Explore our selection of games from these providers and many more to discover new favorites. Whether you prefer classic themes, innovative features, or high payout potentials, there's something for everyone at SG8 Casino. Our platform ensures that you have access to the latest and most popular slots in the industry.</p>
              <h2>Exclusive Promotions and Bonuses</h2>
              <p>Maximize your wins with our exclusive promotions and bonuses. SG8 Casino offers a variety of incentives designed to enhance your online slot experience, giving you more chances to win. From welcome bonuses to daily promotions and loyalty rewards, we provide opportunities to boost your gameplay and increase your chances of hitting the jackpot. Join SG8 Casino today, take advantage of our special offers, and start your online slot adventure. Your next big win is just a spin away! Enjoy the thrill of online slots with SG8 Casino's rewarding and exciting promotions.</p>
              <PaymentMethod/>
            </div>
            </div>
    </>
  );
}

export default TopGames;

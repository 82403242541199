import React, { useState, useContext } from 'react';
import { checkSchedule } from '../snippets/checkSchedule';
import { GlobalContext } from '../snippets/GlobalContextProvider';

export default function NonFeaturedProviderBanner() {
    let provider = checkSchedule("2024-12-01T00:00:00") ?'PGSoft': 'Fa Chai'
  const {nonFeaturedGames, setNonFeaturedGames} = useContext(GlobalContext);
    const {activePopup, setActivePopup} = useContext(GlobalContext);

    const handleClose = () => {
        localStorage.setItem("nonFeaturedGames", 0);
        setNonFeaturedGames(0);
        setActivePopup(null); 
      };

      const handleRedirect = () => {
        localStorage.setItem("nonFeaturedGames", 0);
        setNonFeaturedGames(0);
        setActivePopup(null); 
        window.location.href = checkSchedule("2024-12-01T00:00:00") ? "/slots/pgsoft/" : "/slots/fachai/";
      };
    return (
        <div className="modal trigger-modal">
            <div className="trigger-main-container rolling-commission">
              <button className='verification-close modal-close' onClick={handleClose}>✖</button>
              <div className='trigger-image'>
                <img className='desktop' src={checkSchedule("2024-12-01T00:00:00") ?'/images/promotion/sg8-monthly-roll-out-pgsoft-popup.webp': '/images/rollout-pop-fc.webp'} alt='Get up to .25% rolling commission!'/>
                <img className='mobile' src={checkSchedule("2024-12-01T00:00:00") ?'/images/promotion/sg8-monthly-roll-out-pgsoft-popup.webp': '/images/rollout-pop-fc.webp'} alt='Get up to .25% rolling commission!'/>
              </div>
                <div className="modal-content">
                  <h2>Earn while you play!</h2>
                  <p>Get up to <b>.25% rolling commission</b> while playing with any <b>{provider}</b> Game</p>
                  <button className='yellow-btn' onClick={handleRedirect}>Check it out now!</button>
                </div>
            </div>
        </div>
    );
}
import React, { useState } from 'react';

const upload = process.env.REACT_APP_UPLOAD;

const UploadPhotoForm = () => {
    const [uploadMessage, setUploadMessage] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
    const [selfieFileName, setSelfieFileName] = useState('');
    const [idFileName, setIdFileName] = useState('');
    const [loading, setLoading] = useState(false);

    const handleUpload = async (event) => {
        event.preventDefault();

        const form = event.target;
        const formData = new FormData(form);

        const newFormData = new FormData();
        const username = localStorage.getItem('username')?.trim().toUpperCase() || '';
        newFormData.append('u', username);

        formData.forEach((value, key) => {
            newFormData.append(key, value);
        });

        try {
            setLoading(true);
            const response = await fetch(upload, {
                method: 'POST',
                body: newFormData,
            });

            const json = await response.json();
            if (json.e) {
                setUploadMessage(`Error! ${json.e}`);
            } else if (json.s) {
                localStorage.setItem("isLoggedIn", "true");
                localStorage.setItem("registered", "true");
                setIsLoggedIn(true);
                if (window.location.pathname === "/promotion/skinny-mikes/" || window.location.pathname === "/promotion/skinny-mikes") {
                    window.location.href = "/promotion/skinny-mikes/";
                  }else{
             window.location.href = "/";
               }
            }
        } catch (error) {
            setUploadMessage('Error! Could not upload the photo.');
        }
        setLoading(false);
    };

    const handleFileChange = (event, setFileName) => {
        const file = event.target.files[0];
        setFileName(file ? `File uploaded: ${file.name}` : 'No file selected');

    };

    return (
        <>
            <form id="uploadPhotoForm" onSubmit={handleUpload} encType="multipart/form-data">
                <h3 style={{ fontSize: '1.2em', marginBottom: '12px', fontWeight: 'bold' }}>Upload your ID photo</h3>
                <div className="instruction"><i>Size limit: 5MB, JPG or PNG Only</i></div>
                <div className='upload-container'>
                    <div className="formrow">
                        <label htmlFor="imgSelfie">
                            <img src='/images/selfie-id.webp' alt="Selfie with ID" />
                            <img className='upload-icon' src='/images/upload-icon.webp' alt="Upload Icon" />
                            <span>
                                Upload
                                <b> Selfie with ID</b>
                            </span>
                            <input 
                                type="file" 
                                id="imgSelfie" 
                                name="imgSelfie" 
                                accept=".jpg,.png" 
                                onChange={(e) => handleFileChange(e, setSelfieFileName)} 
                            />
                        </label>
                        <div className='filename'>{selfieFileName}</div>
                    </div>
                    <div className="formrow">
                        <label htmlFor="imgFront">
                            <img src='/images/id.webp' alt="ID Photo" />
                            <img className='upload-icon' src='/images/upload-icon.webp' alt="Upload Icon" />
                            <span>
                                Upload
                                <b> ID Photo</b>
                            </span>
                            <input 
                                type="file" 
                                id="imgFront" 
                                name="imgFront" 
                                accept=".jpg,.png" 
                                onChange={(e) => handleFileChange(e, setIdFileName)} 
                            />
                        </label>
                        <div className='filename'>{idFileName}</div>
                    </div>
                </div>
                <div id="uploadPhotoMsg" dangerouslySetInnerHTML={{ __html: uploadMessage }}></div>
                <button id="uploadPhotoBtn" type="submit">SUBMIT</button>
            </form>
            {loading && <div className='loadingContainer'><img src="/images/sg8.gif" alt="Loading..." /><span>Processing...</span></div>}
        </>
    );
};

export default UploadPhotoForm;
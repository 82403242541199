import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {currentPath, currentDomain} from "../App";
import ArticleList from "../snippets/ArticleList";

export const articleGamesList = [
    {
        id: 0,
        ArticleGameImg: "/images/superace.webp",
        ArticleImgAlt: "superace88",
        ArticleGameTitle: "Super Ace",
        ArticleGameDescription: "In the crowded world of online slots, Super Ace by JILI Games stands out as a game that offers both substance and style.",
        ArticleLink: "/jili-games/super-ace/"

    },
    {
        id: 1,
        ArticleGameImg: "/images/boxing-king.webp",
        ArticleImgAlt: "jilibet",
        ArticleGameTitle: "Boxing King",
        ArticleGameDescription: "Step into the ring, embrace the challenge, and let the games begin. Your journey to becoming the ultimate Boxing King starts now.",
        ArticleLink: "/jili-games/boxing-king/"

    },
    {
        id: 2,
        ArticleGameImg: "/images/fortune-gems.webp",
        ArticleImgAlt: "jiliko",
        ArticleGameTitle: "Fortune Gems",
        ArticleGameDescription: "Fortune Gems is more than just a slot game—it’s an experience. With its blend of simplicity, strategic depth, and high payout potential.",
        ArticleLink: "/jili-games/fortune-gems/"

    },
    {
        id: 3,
        ArticleGameImg: "/images/golden-empire.webp",
        ArticleImgAlt: "jili no 1",
        ArticleGameTitle: "Golden Empire",
        ArticleGameDescription: "Golden Empire is a masterclass in slot game design, combining rich visuals, strategic gameplay, and rewarding features.",
        ArticleLink: "/jili-games/golden-empire/"

    },
    {
        id: 4,
        ArticleGameImg: "/images/money-coming.webp",
        ArticleImgAlt: "jiliko",
        ArticleGameTitle: "Money Coming",
        ArticleGameDescription: "If you’re looking for a slot game that combines classic charm with modern excitement, Money Coming by JILI Games is a must-try.",
        ArticleLink: "/jili-games/money-coming/"

    },
    {
        id: 5,
        ArticleGameImg: "/images/color-game.webp",
        ArticleImgAlt: "jili philippines",
        ArticleGameTitle: "Color Game",
        ArticleGameDescription: "The Color Game is more than just a dice game—it's an adventure. The premise is straightforward: players place their bets on which color.",
        ArticleLink: "/jili-games/color-game/"

    },
    {
        id: 6,
        ArticleGameImg: "/images/crazy-777.webp",
        ArticleImgAlt: "jilibet",
        ArticleGameTitle: "Crazy 777",
        ArticleGameDescription: "Is a classic slot with a twist. Its straightforward gameplay appeals to many players. Filipino gamers love the nostalgic feel combined with modern features, enhancing the overall gaming experience.",
        ArticleLink: "/slots/jili/"

    },
    {
        id: 7,
        ArticleGameImg: "/images/mega-ace.webp",
        ArticleImgAlt: "jilibet mega ace casino games",
        ArticleGameTitle: "Mega Ace",
        ArticleGameDescription: "Dive into the world of Mega Ace today and see where the reels take you. With so much on offer, every spin is a new adventure just waiting to unfold.",
        ArticleLink: "/jili-games/mega-ace/"

    },
]

function TopJiliGames() {
    const hiddenItems = [2];
    const articleGamesBlock = articleGamesList.map((data) => (
        <div className="article-games-flexbox" key={data.id}>
            <img src={data.ArticleGameImg} alt={`SG8 ${data.ArticleImgAlt}`} className=""/>
            <h2>{data.ArticleGameTitle}</h2>
            <p>{data.ArticleGameDescription}</p>
            <a href={data.ArticleLink}>Read More</a>
        </div>
    ));
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Top JILI Slot Games in the Philippines | Ultimate Gaming Experience</title>
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta
                    name="description"
                    content="Top JILI Slot games in the Philippines. Enjoy engaging gameplay for the ultimate gaming experience with JILI Games."
                />
                <link rel="canonical" href={`${currentDomain}${currentPath}`} />
            </Helmet>
            <div className="main-wrapper">
               <div className="hero-article">
               </div>
            </div>
            <div className="main-content-article">
                <h1>Top JILI Slot in the Philippines</h1>
                <p>In the exciting online casino world of the Philippines, JILI Games has become a favorite among Filipino players. Known for its innovative design and engaging gameplay, JILI Slots games offer an ultimate gaming experience.</p>
                <h2 className="h2-article-center">Our Wide Range of Jili Online Slot Games</h2>
                <div className="article-games-block">
                    {articleGamesBlock}
                </div>
                <p className="article-highlight-text">For the best JILI Philippines Experience, look no further than these casino games on SG8.casino.</p>
            </div>
            <ArticleList hiddenItems={hiddenItems} />
        </>
    );
}

export default TopJiliGames;

export const checkSchedule = (start, end = null) => {
  const now = new Date()
  const startTime = new Date(start)
  const endTime = end ? new Date(end) : null

  if (endTime) {
    return now >= startTime && now <= endTime
  }

  return now >= startTime
}

import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import {currentPath, currentDomain} from "../../App";

export default function JiliHappyHour() {
  const title = "JILI 24/7 Spin Frenzy";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("JiliHappyHour");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("JiliHappyHour");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>JILI 24/7 Spin Frenzy</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="SG8 JILI 24/7 Spin Frenzy Promotion"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />
        <h2>
        JILI 24/7 Spin Frenzy
        </h2>
        <p>November 1, 2024 to until December 31, 2024</p>
        <h2>Prizes:</h2>
        <span><b>1 Gift Code = FREE 20 Spins</b>
        <br/><i>Calculation:</i>
        <br/>1 Spin = Php1.00
        <br/>20 Spins = Php20.00 </span>

        <h2>General Mechanics:</h2>
        <ol>
          <li>
          Open to all verified players of SG8. 
          </li>
          <li>
          This promotion is only for the first 500 players meeting the wagering requirement.
          </li>
          <li>
          Players who hit the minimum of 500x spins in any JILI slot games from 6:00 AM to 5:59 AM the next day, will receive a gift code of <b>20 free spins</b>. 
          </li>
          <li>
          Eligible players can find their gift code in the promotion tab on the website.  (<b>1 Player = 1 Gift Code</b>)
          <br/>  -	Upon meeting the wagering requirement, eligible players will receive the gift code on the website's promotion tab.
          <br/>  -	Players can use their <b>20 free spins</b> by entering the gift code in the gift box icon in any JILI slot game.
          </li>
          <li>
          No turnover requirement for the winnings earned by the free spins given only.
          </li>
          <li>
          This promotion is a one-time usage only. 
          </li>
        </ol>
        <h2>Terms and conditions:</h2>
        <ol>
          <li>All prizes will be shouldered by JILI, and other marketing costs will be shouldered by SG8.</li>
          <li>General Service Provider - SG8 terms and conditions apply.</li>
          <li>SP reserves the right to take back any bonuses claimed by players if the terms and conditions of the promotion are found to have been breached by the player, and/or there is reasonable suspicion of foul play.</li>
          <li>SP has the right to modify or cancel the promotion without prior notice and is subject to PAGCOR's approval.</li>
          <li>SP retains the authority to disqualify a participant from the promotion upon observing instances of counter or arbitrage betting.</li>
        </ol>
      </div>
    </>
  );
}

import React, { useState, useEffect, useContext, useRef } from "react"
import axios from "axios"
import { apiJSONURL } from "./pages/LiveCasino"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faUser } from "@fortawesome/free-solid-svg-icons"
import LoginModal from "./Modal/LoginModal"
import Register from "./api/Register"
import Verification from "./api/Verification"
import UploadPhoto from "./api/UploadPhoto"
import LoginPromptModal from "./snippets/LoggedInPrompt"
import { promotionList } from "./pages/Promotion"
import { BlogList } from "./pages/Blog/Blog"
import { slotsBox } from "./pages/slots/Slots"
import useFetchBalance from "./snippets/useFetchBalance"
import Maintenance from "./Modal/Maintenance"
import NonFeaturedProviderBanner from "./Modal/NonFeaturedProviderBanner"
// import TopGamesBanner from "./Modal/TopGamesBanner";
import GamelympicsQualified from "./Modal/GamelympicsQualified"
import CreatePassword from "./api/CreatePassword"
import Cookies from "js-cookie"
import LogoutFunction from "./snippets/LogoutFunction"
import { apk } from "./Footer"
import { GlobalContext } from "./snippets/GlobalContextProvider"
import DailyNotif from "./Modal/DailyNotif"
import RollingCommissionIdle from "./snippets/RollingCommissionIdle"
import GameTime from "./Modal/GameTime"
import { checkSchedule } from "./snippets/checkSchedule"
import { useTriggerTracker } from "./snippets/TriggerTracker"
import JiliTriggers from "./snippets/JiliTriggers"
import JiliHappyHourModal from "./Modal/JiliHappyHourModal"
import JiliHappyHourGame from "./Modal/JiliHappyHourGame"
import BlackFridaySlots from "./snippets/BlackFriday"

function Header() {
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [showRegisterForm, setShowRegisterForm] = useState(false)
  const [username, setUsername] = useState("")
  const [isBodyLoggedIn, setIsBodyLoggedIn] = useState(false)
  const [triggerFetch, setTriggerFetch] = useState(false)
  const [isVisibleBalance, setIsVisible] = useState(false)
  const [isVisibleAccount, setIsVisibleAccount] = useState(false)
  const [isVisibleVerify, setIsVisibleVerify] = useState(false)
  const [isVisiblePhoto, setIsVisiblePhoto] = useState(false)
  const [isVisibleCreatePassword, setIsVisibleCreatePassword] = useState(false)
  const [isVisibleCompleteAcc, setIsVisibleCompleteAcc] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isVisibleTNC, setVisibleTNC] = useState(false)
  const [showMaintenance, setShowMaintenance] = useState(false)
  const [activeMenu, setActiveMenu] = useState(window.location.pathname)
  const [isLoggedIn] = useState(localStorage.getItem("isLoggedIn"))
  const [hasViewed, setHasViewed] = useState(false)
  const [modalShown, setModalShown] = useState(
    sessionStorage.getItem("modalShown") === "true"
  )
  const [isQualified, setIsQualified] = useState(false)
  const [rollOutCommission, setRollOutCommission] = useState(null)
  const [isIdle, setIsIdle] = useState(false)
  const idleTimeoutRef = useRef(null)
  const leaderboardApi = process.env.REACT_APP_LEADERBOARD
  const { uniqueGames, setUniqueGames } = useContext(GlobalContext)
  const { activePopup, setActivePopup } = useContext(GlobalContext)
  const { nJGames, setNJGames } = useContext(GlobalContext)
  const { nonFeaturedGames, setNonFeaturedGames } = useContext(GlobalContext)
  const { gameTime, setGameTime } = useContext(GlobalContext)
  const { isInGame, setIsInGame } = useContext(GlobalContext)
  const { popupModalActive, setPopupModalActive } = useContext(GlobalContext)
  const { hasTriggerBeenShown, setTrigger } = useTriggerTracker()
  const promoApi = process.env.REACT_APP_LEADERBOARD
  const { voucherCode, setVoucherCode } = useContext(GlobalContext)

  useEffect(() => {
    const username = localStorage.getItem("username")

    if (username) {
      const apiUrl = `${promoApi}?cmd=getJiliVoucher&u=${username}`

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          if (data.voucherId) {
            localStorage.setItem("jilihappyhour", "true")
            setVoucherCode(data.voucherId)
          } else {
            localStorage.removeItem("jilihappyhour")
            setVoucherCode("Not yet qualified")
          }
        })
        .catch((error) => {
          console.error("Error fetching Jili Happy Hour Voucher:", error)
        })
    } else {
      console.error("No username found in local storage.")
    }
  }, [])

  useEffect(() => {
    const resetIdleTimer = () => {
      if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current)

      if (isInGame === false) {
        idleTimeoutRef.current = setTimeout(() => {
          setIsIdle(true) // User has been idle for 3 minutes
        }, 180000)
      }
    }

    window.addEventListener("mousemove", resetIdleTimer)
    window.addEventListener("keydown", resetIdleTimer)
    window.addEventListener("scroll", resetIdleTimer)
    window.addEventListener("click", resetIdleTimer)

    resetIdleTimer()

    if (isIdle && activePopup === null) {
      if (!hasTriggerBeenShown("nonFeatured")) {
        {
          setActivePopup("idle")
          setTrigger("nonFeatured")
        }
      }
    }

    // Clean up event listeners and timeout on component unmount
    return () => {
      window.removeEventListener("mousemove", resetIdleTimer)
      window.removeEventListener("keydown", resetIdleTimer)
      window.removeEventListener("scroll", resetIdleTimer)
      window.removeEventListener("click", resetIdleTimer)

      if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current)
    }
  }, [isIdle, isInGame]) // Dependency array contains `isIdle` to update state

  //Daily Notifs
  if (sessionStorage.getItem("modalShown")) {
    if (!hasTriggerBeenShown("dailyNotif")) {
      {
        setActivePopup("dailyNotif")
        setTrigger("dailyNotif")
      }
    }
  }
  // {
  //   const now = new Date();
  //   const currentDate = now.toISOString().split('T')[0]; // "YYYY-MM-DD"
  //   const storedDate = localStorage.getItem("dailyNotif");

  //   if (storedDate != currentDate) {
  //     setActivePopup("dailyNotif");
  //     localStorage.setItem("dailyNotif", currentDate);
  //     }
  // }
  // else
  // {
  //   const now = new Date();
  //   const currentDate = now.toISOString().split('T')[0]; // "YYYY-MM-DD"
  //   setActivePopup("dailyNotif");
  //   localStorage.setItem("dailyNotif", currentDate);
  // }

  const IdleBanner = () => {
    const handleClose = () => {
      setActivePopup(null)
    }
    const handleRedirect = () => {
      setActivePopup(null)
      window.location.href = checkSchedule("2024-12-01T00:00:00")
        ? "/slots/pgsoft/"
        : "/slots/fachai/"
    }
    return (
      <RollingCommissionIdle
        handleClose={handleClose}
        handleRedirect={handleRedirect}
      />
    )
  }

  const DailyBanner = () => {
    const handleClose = () => {
      setActivePopup(null)
    }
    const handleRedirect = () => {
      setActivePopup(null)
      window.location.href = checkSchedule("2024-12-01T06:00:00")
        ? "/slots/pgsoft/"
        : "/slots/fachai/"
    }
    return (
      <DailyNotif handleClose={handleClose} handleRedirect={handleRedirect} />
    )
  }

  useEffect(() => {
    if (
      window.location.pathname === "/promotion/skinny-mikes/" ||
      window.location.pathname === "/promotion/skinny-mikes"
    ) {
      Cookies.set("fromSkinnyMikes", "true", { expires: 1 })
    }
  }, [])

  useEffect(() => {
    const username = localStorage.getItem("username")

    if (username) {
      const apiUrl = `${leaderboardApi}?cmd=getSG8MonthlyRollOutComm&u=${username}`

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.comm) {
            setRollOutCommission(data.comm)
            // console.log(data.comm);
          } else {
            setRollOutCommission(0)
          }
        })
        .catch((error) => {
          console.error("Error fetching rollout commission:", error)
          setRollOutCommission(0)
        })
    } else {
      console.error("No username found in local storage.")
      setRollOutCommission(0)
    }
  }, [])

  // const leaderboardAPI = process.env.REACT_APP_LEADERBOARD;

  // async function getQualified(){
  //     const user = localStorage.getItem("username");
  //     const response = await axios.post(
  //       leaderboardAPI,
  //       null,
  //       {
  //         params: {
  //           cmd: "ifQualified",
  //           u: user,
  //         },
  //       }
  //     );
  //     if (response.data.isQualified === true)
  //     {
  //       setIsQualified(true);
  //     }
  // }

  // useEffect(() => {
  //   if (localStorage.getItem("username")){
  //   getQualified();
  //   }
  // }, []);

  useEffect(() => {
    if (localStorage.getItem("nJGames") > 2 && activePopup === null) {
      if (!hasTriggerBeenShown("jiliHappyHour")) {
        {
          setActivePopup("jiliHappyHourGame")
          setTrigger("jiliHappyHour")
        }
      }
    }
    if (localStorage.getItem("nonFeaturedGames") > 2 && activePopup === null) {
      if (!hasTriggerBeenShown("nonFeatured")) {
        {
          setActivePopup("nonFeatured")
          setTrigger("nonFeatured")
        }
      }
    }
    // else if (localStorage.getItem("uniqueGames") > 5 && activePopup === null )
    //   {
    //     setActivePopup("topGames");
    //   }
    if (gameTime === true && activePopup === null) {
      if (!hasTriggerBeenShown("nonFeatured")) {
        {
          setActivePopup("gameTime")
          setTrigger("nonFeatured")
        }
      }
    }
  }, [gameTime, nJGames, nonFeaturedGames, uniqueGames])

  const activePage = window.location.pathname
  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const from = urlParams.get("from")
    const hasFbCampaign = urlParams.has("fbcampaign")
    const hasFbClid = urlParams.has("fbclid")

    if (urlParams.has("download-app")) {
      window.location.href = apk
    }

    if ((from || hasFbCampaign || hasFbClid) && !isLoggedIn && modalShown) {
      setShowRegisterForm(true)
    }
  }, [])

  const handleVerifyClick = () => {
    setIsVisibleVerify(!isVisibleVerify)
  }

  const handlePhotoClick = () => {
    setIsVisiblePhoto(!isVisiblePhoto)
  }

  const handleCreatePasswordClick = () => {
    setIsVisibleCreatePassword(!isVisibleCreatePassword)
  }

  const CompleteAccount = () => {
    setIsVisibleCompleteAcc(!isVisibleCompleteAcc)
  }

  const handleMenuClick = (path) => {
    setActiveMenu(path)
  }

  useEffect(() => {
    const elements = document.querySelectorAll("#handleVerifyClick")

    elements.forEach((element) => {
      element.addEventListener("click", handleVerifyClick)
    })

    return () => {
      elements.forEach((element) => {
        element.removeEventListener("click", handleVerifyClick)
      })
    }
  }, [])

  useEffect(() => {
    const elements = document.querySelectorAll("#handlePhotoClick")

    elements.forEach((element) => {
      element.addEventListener("click", handlePhotoClick)
    })

    return () => {
      elements.forEach((element) => {
        element.removeEventListener("click", handlePhotoClick)
      })
    }
  }, [])

  useEffect(() => {
    const elements = document.querySelectorAll("#handleCreatePasswordClick")

    elements.forEach((element) => {
      element.addEventListener("click", handleCreatePasswordClick)
    })

    return () => {
      elements.forEach((element) => {
        element.removeEventListener("click", handleCreatePasswordClick)
      })
    }
  }, [])

  const handleClose = () => {
    setIsVisibleVerify(false)
    setIsVisiblePhoto(false)
    setIsVisibleCreatePassword(false)
    setIsVisibleCompleteAcc(false)
  }

  useEffect(() => {
    const savedUsername = localStorage.getItem("username")
    if (savedUsername) {
      setUsername(savedUsername)
    }
  }, [])

  const handleBreakdownClick = () => {
    setIsVisible(!isVisibleBalance)
    setIsVisibleAccount(false)
  }

  const handleAccountClick = () => {
    setIsVisibleAccount(!isVisibleAccount)
    setIsVisible(false)
  }

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn")
    localStorage.removeItem("username")
    localStorage.removeItem("rememberMe", true)
    window.location.href = "/"
  }

  const { balance, breakdown } = useFetchBalance(triggerFetch)

  const handleReturnWallet = async () => {
    const username = localStorage.getItem("username")
    const params = new URLSearchParams({
      cmd: "withdrawFromAll",
      u: username,
    })

    try {
      const response = await fetch(`${apiJSONURL}?${params}`)
      if (!response.ok) {
        throw new Error("Network response was not ok")
      }
      setTriggerFetch((prevState) => !prevState)
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error)
    }
  }

  const [vipLevel, setVipLevel] = useState(null)

  useEffect(() => {
    const username = localStorage.getItem("encryptedUsername")
    if (username && username !== "null") {
      const fetchVIPData = async () => {
        try {
          const response = await fetch(apiJSONURL, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
              cmd: "getVIP",
              u: username,
            }).toString(),
          })
          if (!response.ok) {
            throw new Error("Network response was not ok:")
          }
          const data = await response.json()
          setVipLevel(data.vipLevel)
        } catch (error) {
          console.error("Error fetching VIP data:", error)
        }
      }

      fetchVIPData()
    }
  }, [])

  const getVipLevelImage = (level) => {
    switch (level) {
      case 1:
        return "/images/bronze.webp"
      case 2:
        return "/images/silver.webp"
      case 3:
        return "/images/gold.webp"
      case 4:
        return "/images/platinum.webp"
      default:
        return "/images/unknown.webp"
    }
  }

  const handleRegisterFromLoginClick = () => {
    setShowLoginForm(false)
    setShowRegisterForm(true)
  }

  const handleLoginFromRegisterClick = () => {
    setShowRegisterForm(false)
    setShowLoginForm(true)
  }

  const handleLoginClick = () => {
    setShowLoginForm(true)
  }

  const handleRegisterClick = () => {
    setShowRegisterForm(true)
  }

  const handleCloseClick = () => {
    setShowLoginForm(false)
    setShowRegisterForm(false)
  }

  useEffect(() => {
    setIsBodyLoggedIn(document.body.classList.contains("isLoggedIn"))
  }, [])

  useEffect(() => {
    const registered = localStorage.getItem("registered")
    if (registered == "true") {
      setShowModal(true)
    }
  }, [])

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn")
    const encryptedUsername = localStorage.getItem("encryptedUsername")

    if (isLoggedIn === "true" && !encryptedUsername) {
      setShowMaintenance(true)
    }
  }, [])

  const handleAgree = () => {
    setShowModal(false)
    localStorage.removeItem("registered", "true")
  }

  const promoClicks = promotionList.map((promo) => promo.promoClick)
  const blogList = BlogList.map((promo) => promo.link)
  const slotsBoxs = slotsBox.map((slot) => slot.btn)
  return (
    <>
      {/* Gamelympics popup */}
      {!Cookies.get("hasViewed") ? (
        isQualified ? (
          <GamelympicsQualified />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {activePopup === "idle" && <IdleBanner />}
      {activePopup === "jiliHappyHour" && (
        <JiliHappyHourModal voucherCode={voucherCode} />
      )}
      {activePopup === "dailyNotif" && <DailyBanner />}
      {activePopup === "nonFeatured" && <NonFeaturedProviderBanner />}
      {activePopup === "jiliHappyHourGame" && <JiliHappyHourGame />}
      {activePopup === "gameTime" && <GameTime />}
      {/* {activePopup === 'topGames' && <TopGamesBanner />} */}
      {showMaintenance && <Maintenance />}
      {showModal && <LoginPromptModal onAgree={handleAgree} />}

      {isVisibleVerify && (
        <div>
          <div className="modal verification-container">
            <div className="modal-container completeprofilecontainer">
              <Verification />
              <button
                className="verification-close modal-close"
                onClick={handleClose}
              >
                ✖
              </button>
            </div>
          </div>
        </div>
      )}
      {isVisiblePhoto && (
        <div>
          <div className="modal verification-container">
            <div className="modal-container">
              <UploadPhoto />
              <button
                className="verification-close modal-close"
                onClick={handleClose}
              >
                ✖
              </button>
            </div>
          </div>
        </div>
      )}
      {isVisibleCreatePassword && (
        <div>
          <div className="modal verification-container">
            <div className="modal-container" style={{ maxWidth: "400px" }}>
              <CreatePassword />
              <button
                className="verification-close modal-close"
                onClick={handleClose}
              >
                ✖
              </button>
            </div>
          </div>
        </div>
      )}
      <div id="LoginTrigger" className={`modal ${showLoginForm ? "show" : ""}`}>
        <div
          className="modal-main-container"
          style={{ backgroundImage: `url("/images/registration-form-bg.jpg")` }}
        >
          <LoginModal />
          <button className="modal-close" onClick={handleCloseClick}>
            ✖
          </button>
          {!showRegisterForm && (
            <div className="HaveAccount">
              Don't have an account?{" "}
              <a onClick={handleRegisterFromLoginClick}>Register</a>
            </div>
          )}
        </div>
      </div>
      <div
        id="RegTrigger"
        className={`modal ${showRegisterForm ? "show" : ""}`}
      >
        <div
          className="modal-main-container"
          style={{ backgroundImage: `url("/images/registration-form-bg.jpg")` }}
        >
          <Register />
          <button className="modal-close" onClick={handleCloseClick}>
            ✖
          </button>
          {!showLoginForm && (
            <div className="HaveAccount">
              Have an account?{" "}
              <a className="login" onClick={handleLoginFromRegisterClick}>
                Login
              </a>
            </div>
          )}
        </div>
      </div>
      <header id="masthead" className="site-header has-logo has-menu">
        <div className="sticky-navigation desktop-view">
          <div className="navigation-fixed-container">
            <a href="/">
              <img
                className="website-logo"
                src="/images/christmaslogo.webp"
                alt="official sg8 logo"
              />
            </a>
            <div className="user-information">
              <div className="userwrapper">
                <span className="userID">
                  {vipLevel !== null ? (
                    <a href="/vip/">
                      <img
                        src={getVipLevelImage(vipLevel)}
                        alt={`VIP Level ${vipLevel}`}
                      />
                    </a>
                  ) : (
                    <div></div>
                  )}
                  {username}
                </span>
                <span id="userBalance" className="userBalance">
                  {balance ?? "..."}
                  <button
                    type="button"
                    className="button"
                    onClick={handleBreakdownClick}
                  >
                    <FontAwesomeIcon icon={faChevronDown} />
                  </button>
                </span>
                {isVisibleBalance && (
                  <div className="balance-brakedown">
                    {Object.keys(breakdown).map((key) => {
                      if (key !== "data" && key !== "balance") {
                        return (
                          <div key={key}>
                            {key}: <span>{breakdown[key]}</span>
                          </div>
                        )
                      } else {
                        return null // Exclude these keys from rendering
                      }
                    })}
                    <button onClick={handleReturnWallet}>
                      Return to Main Wallet
                    </button>
                  </div>
                )}
              </div>

              {activePage === "/account/deposit/" ||
              activePage === "/account/withdraw/" ? (
                activePage === "/account/deposit/" ? (
                  <div className="c2a-depo-withdraw">
                    <a href="/account/deposit/" className="depo-active-btn">
                      Deposit
                    </a>
                    <a
                      href="/account/withdraw/"
                      className="withdraw-inactive-btn"
                    >
                      Withdraw
                    </a>
                  </div>
                ) : (
                  <div className="c2a-depo-withdraw">
                    <a href="/account/deposit/" className="depo-inactive-btn">
                      Deposit
                    </a>
                    <a
                      href="/account/withdraw/"
                      className="withdraw-active-btn"
                    >
                      Withdraw
                    </a>
                  </div>
                )
              ) : (
                <div className="c2a-depo-withdraw">
                  <a href="/account/deposit/" className="depo-inactive-btn">
                    Deposit
                  </a>
                  <a
                    href="/account/withdraw/"
                    className="withdraw-inactive-btn"
                  >
                    Withdraw
                  </a>
                </div>
              )}
            </div>
            {isBodyLoggedIn && <JiliTriggers />}
            {isBodyLoggedIn && (
              <div className="rollout-container">
                <a href="/promotion/monthly-rollout/">
                  <label>BONUS:</label>
                  <span id="roCommission">
                    {rollOutCommission !== null ? rollOutCommission : "..."}
                  </span>
                </a>
              </div>
            )}
            <div className="c2a-landingpage">
              <a onClick={handleLoginClick} className="login-btn">
                Login
              </a>
              <a onClick={handleRegisterClick} className="reg-btn">
                Register
              </a>
            </div>
            <div className="navigation-menu-list">
              <div className="primary-menu-list">
                <div
                  // className={`menu ${activeMenu === '/slots/jili/' ? 'active' : ''}`}
                  className={`link-nav menu ${
                    ["/slots/", ...slotsBoxs].includes(activeMenu)
                      ? "active"
                      : ""
                  }`}
                  style={{
                    backgroundImage: `url(/images/slots-navigation.webp)`,
                  }}
                  onClick={() => handleMenuClick("/slots/jili/")}
                >
                  <a href="/slots/jili/">
                    <span>Slots</span>
                  </a>
                </div>
                <div
                  className={`link-nav menu ${
                    activeMenu === "/live-casino/" ? "active" : ""
                  } `}
                  style={{ backgroundImage: `url(/images/ld-navigation.webp)` }}
                  onClick={() => handleMenuClick("/live-casino/")}
                >
                  <a href="/live-casino/">
                    <span>Live Dealer</span>
                  </a>
                </div>
                {!username.startsWith("SG8JPCP") && (
                  <div
                    className={`link-nav menu ${
                      activeMenu === "/sportsbook/" ? "active" : ""
                    } sports-menu-navigation `}
                    style={{
                      backgroundImage: `url(/images/sportsbook-navigation.webp)`,
                    }}
                    onClick={() => handleMenuClick("/sportsbook/")}
                  >
                    <a href="/sportsbook/">
                      <span>Sportsbook</span>
                    </a>
                  </div>
                )}
                {/* <div
                  className={`link-nav menu ${
                    activeMenu === "/promotion/gamelympics-games/" ? "" : ""
                  } gamelympics-menu-container`}
                  style={{
                    backgroundImage: `url(/images/gamelympics-background.webp)`,
                    backgroundSize: `cover`,
                    backgroundPosition: `center`,
                    backgroundRepeat: `no-repeat`,
                  }}
                  onClick={() => handleMenuClick("/live-casino/")}
                >
                  <a
                    href="/promotion/gamelympics-games/"
                    className="gamelympics-menu"
                  >
                    <img
                      src="/images/sg8-gamelympics-logo-1.webp"
                      alt="sg8 gamelympics"
                    />
                  </a>
                </div> */}
              </div>
              <div className="secondary-menu-list">
                <ul>
                  <li
                    className={`.secondary-menu-list li ${
                      ["/promotion/", ...promoClicks].includes(activeMenu)
                        ? "active"
                        : ""
                    } link-nav`}
                  >
                    <a href="/promotion/">Promotion</a>
                  </li>
                  <li
                    className={`.secondary-menu-list li ${
                      activeMenu === "/vip/" ? "active" : ""
                    } link-nav`}
                  >
                    <a href="/vip/">VIP</a>
                  </li>
                  <li
                    className={`.secondary-menu-list li ${
                      ["/news/", ...blogList].includes(activeMenu)
                        ? "active"
                        : ""
                    } link-nav`}
                  >
                    <a href="/news/">News</a>
                  </li>
                  {isBodyLoggedIn && (
                    <li
                      className={`.secondary-menu-list li ${
                        activeMenu === "/account/request-report/"
                          ? "active"
                          : ""
                      } link-nav`}
                    >
                      <a href="/account/request-report/">Activity</a>
                    </li>
                  )}
                  {isBodyLoggedIn && (
                    <li
                      className={`.secondary-menu-list li ${
                        activeMenu === "/account/dashboard/" ? "active" : ""
                      } link-nav`}
                    >
                      <a href="/account/dashboard/">Profile</a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-header-container mobile-view">
          <section>
            <div className="mobile-website-logo">
              <a href="/">
                <img
                  className="website-logo"
                  src="/images/christmaslogo.webp"
                  alt="official sg8 logo"
                />
              </a>
            </div>
            {!isBodyLoggedIn && (
              <div className="mobile-login-reg">
                <a onClick={handleLoginClick} className="login-btn">
                  Login
                </a>
              </div>
            )}
            {isBodyLoggedIn && (
              <div className="mobile-balance">
                <div>
                  <p>Balance</p>
                  <span className="userBalance">{balance ?? "..."}</span>
                </div>
                {/* Conditional rendering based on username */}
                {!username.startsWith("SG8JPCP") && (
                  <div
                    className="arrowdown"
                    style={{ color: "white" }}
                    onClick={handleBreakdownClick}
                  >
                    <button
                      style={{ background: "none" }}
                      type="button"
                      className="button btn-toogle-wallet"
                    >
                      <FontAwesomeIcon
                        style={{
                          color: "#111",
                          fontSize: "20px",
                          padding: "5px",
                        }}
                        icon={faChevronDown}
                      />
                    </button>
                  </div>
                )}
                {isVisibleBalance && (
                  <div className="balance-brakedown">
                    {Object.keys(breakdown).map((key) => {
                      if (key !== "data" && key !== "balance") {
                        return (
                          <div key={key}>
                            {key}: <span>{breakdown[key]}</span>
                          </div>
                        )
                      } else {
                        return null // Exclude these keys from rendering
                      }
                    })}
                    <button onClick={handleReturnWallet}>
                      Return to Main Wallet
                    </button>
                    <a href="/account/deposit/" className="depo-btn">
                      Deposit
                    </a>
                    <a href="/account/withdraw/" className="withdraw-btn">
                      Withdraw
                    </a>
                  </div>
                )}
                <div style={{ color: "white" }}>
                  <button
                    style={{ background: "none" }}
                    type="button"
                    className="button"
                    onClick={handleAccountClick}
                  >
                    <FontAwesomeIcon
                      style={{
                        fontSize: "20px",
                        padding: "5px",
                        color: "#fff",
                      }}
                      icon={faUser}
                    />
                  </button>
                </div>
                {isVisibleAccount && (
                  <div className="accountsub">
                    <span className="username">
                      {vipLevel !== null ? (
                        <a className="mobilevip" href="/vip/">
                          <img
                            src={getVipLevelImage(vipLevel)}
                            alt={`VIP Level ${vipLevel}`}
                          />
                        </a>
                      ) : (
                        <div></div>
                      )}
                      {username}
                    </span>
                    <a href="/account/request-report/">Activity</a>
                    <a href="/account/dashboard/">Profile</a>
                    <a href="/coupon/">Coupon</a>
                    <LogoutFunction />
                  </div>
                )}
              </div>
            )}
          </section>
          {!isBodyLoggedIn && (
            <div className="HaveAccount">
              Don't have an account?{" "}
              <a onClick={handleRegisterFromLoginClick}>Register</a>
            </div>
          )}
          <div className="header-promo-container">
            {isBodyLoggedIn && (
              <div className="rollout-container">
                <a href="/promotion/monthly-rollout/">
                  <label>BONUS:</label>
                  <span id="roCommission">
                    {rollOutCommission !== null ? rollOutCommission : "..."}
                  </span>
                </a>
              </div>
            )}
            {isBodyLoggedIn && <JiliTriggers />}
          </div>
        </div>
      </header>
    </>
  )
}
export default Header

import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export default function GamelympicsQualified() {
  const [isVisible, setIsVisible] = useState(!Cookies.get('hasViewed'));

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        Cookies.set('hasViewed', true, { path: '/' });
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="modal congratulation-container">
      <div className="modal-main-container">
        <div className="modal-content">
          <div className='gamelympics-modal'>
            <h2>Gamelympics Notice</h2>
            <p className='modal-message'>
              We regret to inform you that GAMELYMPICS 2024 has been canceled due to an insufficient amount of participants. We apologize for any inconvenience this may cause and appreciate your understanding. Stay tuned for future events and opportunities!
            </p>
            <p>Working towards better promotions,</p>
            <p><b>SG8</b></p>
            <a 
              onClick={() => {
                setIsVisible(false);
                Cookies.set('hasViewed', true, { path: '/' });
              }} 
              href=""
            >
              Close Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { apiJSONURL } from '../pages/LiveCasino';
function Verification() {
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    placeOfBirth: '',
    currentAddress: '',
    permanentAddress: '',
    nationality: '',
    natureOfWork: '',
    typeOfIndustry: '',
    sourceOfIncome: '',
    idType: '',
    idNumber: '',
    photoStatus: ''
  });

  const [loading, setLoading] = useState(false);
  const [copyCurrentAddress, setCopyCurrentAddress] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [checkFirstName, setCheckFirstName] = useState();
  const [checkLastName, setCheckLastName] = useState();
  const [checkDob, setCheckDob] = useState();

  useEffect(() => {
    const getPlayerDetails = async () => {
      try {
        const encryptedUsername = localStorage.getItem('encryptedUsername');
        const response = await fetch(apiJSONURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            cmd: 'getPlayerDetails',
            u: encryptedUsername
          }).toString()
        });

        const data = await response.json();
        setCheckFirstName(data.firstName);
        setCheckLastName(data.lastName);
        setCheckDob(data.dob);
        setUserDetails({
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          dob: data.dob || '',
          placeOfBirth: data.placeOfBirth || '',
          currentAddress: data.currentAddress || '',
          permanentAddress: data.permanentAddress || '',
          nationality: data.nationality || '',
          natureOfWork: data.natureOfWork || '',
          typeOfIndustry: data.typeOfIndustry || '',
          sourceOfIncome: data.sourceOfIncome || '',
          idType: data.idType || '',
          idNumber: data.idNumber || '',
          photoStatus: data.photoStatus || ''
        });
      } catch (error) {
        setErrorMessage("Error fetching player details");
      }
    };

    getPlayerDetails();
  }, []);

  useEffect(() => {
    if (copyCurrentAddress) {
      setUserDetails(prevDetails => ({
        ...prevDetails,
        permanentAddress: prevDetails.currentAddress
      }));
    }
  }, [copyCurrentAddress, userDetails.currentAddress]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
  
    // Use the firstName and lastName from userDetails, which is populated by data
    const firstName = userDetails.firstName || event.target.firstName.value.trim();
    const lastName = userDetails.lastName || event.target.lastName.value.trim();
    const dob = userDetails.dob || event.target.dob.value.trim();
  
    // Validate firstName, lastName, and dob
    if ((!firstName && !lastName) || !dob) {
      setErrorMessage('Please provide either a first name or last name, and a date of birth.');
      return;
    }
  
    setLoading(true);
  
    try {
      const encryptedUsername = localStorage.getItem('encryptedUsername');
    
      const formData = new FormData(event.target);
      if (checkFirstName) {
        formData.set('firstName', checkFirstName);
      } else {
        formData.set('firstName', firstName);
      }
      if (checkLastName) {
        formData.set('lastName', checkLastName);
      } else {
        formData.set('lastName', lastName);
      }
      if (checkDob) {
        formData.set('dob', checkDob);
      } else {
        formData.set('dob', dob);
      }
      formData.append('cmd', 'verifyAccount');
      formData.append('u', encryptedUsername);
  
      const formParams = new URLSearchParams();
      for (const [key, value] of formData.entries()) {
        formParams.append(key, value);
      }
  
      const response = await fetch(apiJSONURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formParams.toString()
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
  
      if (data.s) {
        setSuccessMessage(data.s);
      } else {
        setErrorMessage(data.e);
      }
  
      setUserDetails(prevDetails => ({
        ...prevDetails,
        ...data
      }));
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errorMessage || successMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
        setSuccessMessage('');
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage, successMessage]);

  return(
    <div>
      <form onSubmit={handleFormSubmit}>
        <div className="verificationform">
        <div className="page-h2-text-style" bis_skin_checked="1">
          <span>Personal Information</span>
        </div>
        <div className="formrow">
          <label htmlFor="firstName">First Name</label>
          <input type="text" name="firstName" id="firstName" maxLength="100" placeholder="Juan" value={userDetails.firstName} onChange={handleInputChange} disabled={checkFirstName} />
        </div>
        <div className="formrow">
          <label htmlFor="lastName">Last Name</label>
          <input type="text" name="lastName" id="lastName" maxLength="100" placeholder="Dela Cruz"  value={userDetails.lastName} onChange={handleInputChange} disabled={checkLastName}/>
        </div>
        <div className="formrow">
          <label htmlFor="dob">Date of Birth</label>
          <input type="date" name="dob" id="dob" maxLength="100" placeholder="Date of Birth" value={userDetails.dob} onChange={handleInputChange}  disabled={checkDob}/>
        </div>
        <div className="formrow">
          <label htmlFor="placeOfBirth">Place of Birth:</label>
          <input type="text" name="placeOfBirth" id="placeOfBirth" maxLength="100" placeholder="Place of Birth" value={userDetails.placeOfBirth} onChange={handleInputChange}/>
        </div>
        <div className="formrow">
          <label htmlFor="currentAddress">Current Address</label>
          <input type="text" name="currentAddress" id="currentAddress" maxLength="100" placeholder="Current Address" value={userDetails.currentAddress} onChange={handleInputChange}/>
        </div>
        <div className="formrow">
          <label htmlFor="permanentAddress">Permanent Address</label>
          <input type="text" name="permanentAddress" id="permanentAddress" maxLength="100" placeholder="Permanent Address" value={copyCurrentAddress ? userDetails.currentAddress : userDetails.permanentAddress} onChange={handleInputChange}/>
        </div>
        <div className="formrow" style={{ flexFlow: 'row', paddingTop: '8px', paddingLeft: '8px' }}>
          <input type="checkbox" id="copyCurrentAddress" checked={copyCurrentAddress} onChange={() => setCopyCurrentAddress(!copyCurrentAddress)}/>
          <label htmlFor="copyCurrentAddress" style={{ width: '100%', fontSize: '.9em', paddingLeft: '8px', marginTop: '0px' }}>
            Same with current address
          </label>
        </div>
        <div className="formrow">
          <label htmlFor="nationality">Nationality</label>
          <select name="nationality" id="nationality" className="selectSite" value={userDetails.nationality} onChange={handleInputChange}>
            <option value="">--- Select ---</option>
            <option>Filipino</option>
            <option>Afghan</option>
            <option>Algerian</option>
            <option>American</option>
            <option>Angolan</option>
            <option>Argentine</option>
            <option>Australian</option>
            <option>Austrian</option>
            <option>Bangladeshi</option>
            <option>Belarusian</option>
            <option>Belgian</option>
            <option>Bolivian</option>
            <option>Bosnian/Herzegovinian</option>
            <option>Brazilian</option>
            <option>British</option>
            <option>Bulgarian</option>
            <option>Cambodian</option>
            <option>Cameroonian</option>
            <option>Canadian</option>
            <option>Central African</option>
            <option>Chadian</option>
            <option>Chinese</option>
            <option>Colombian</option>
            <option>Congolese</option>
            <option>Costa Rican</option>
            <option>Croatian</option>
            <option>Czech</option>
            <option>Danish</option>
            <option>Dutch</option>
            <option>Ecuadorian</option>
            <option>Egyptian</option>
            <option>Emirati</option>
            <option>English</option>
            <option>Estonian</option>
            <option>Ethiopian</option>
            <option>Finnish</option>
            <option>French</option>
            <option>German</option>
            <option>Ghanaian</option>
            <option>Greek</option>
            <option>Guatemalan</option>
            <option>Honduran</option>
            <option>Hungarian</option>
            <option>Icelandic</option>
            <option>Indian</option>
            <option>Indonesian</option>
            <option>Iranian</option>
            <option>Iraqi</option>
            <option>Irish</option>
            <option>Israeli</option>
            <option>Italian</option>
            <option>Ivorian</option>
            <option>Jamaican</option>
            <option>Japanese</option>
            <option>Jordanian</option>
            <option>Kazakh</option>
            <option>Kenyan</option>
            <option>Lao</option>
            <option>Latvian</option>
            <option>Libyan</option>
            <option>Lithuanian</option>
            <option>Malagasy</option>
            <option>Malaysian</option>
            <option>Malian</option>
            <option>Mauritanian</option>
            <option>Mexican</option>
            <option>Moroccan</option>
            <option>Namibian</option>
            <option>New Zealand</option>
            <option>Nicaraguan</option>
            <option>Nigerian</option>
            <option>Nigerien</option>
            <option>Norwegian</option>
            <option>Omani</option>
            <option>Pakistani</option>
            <option>Panamanian</option>
            <option>Paraguayan</option>
            <option>Peruvian</option>
            <option>Polish</option>
            <option>Portuguese</option>
            <option>Romanian</option>
            <option>Russian</option>
            <option>Salvadoran</option>
            <option>Saudi, Saudi Arabian</option>
            <option>Scottish</option>
            <option>Senegalese</option>
            <option>Serbian</option>
            <option>Singaporean</option>
            <option>Slovak</option>
            <option>Somalian</option>
            <option>South African</option>
            <option>Spanish</option>
            <option>Sudanese</option>
            <option>Swedish</option>
            <option>Swiss</option>
            <option>Syrian</option>
            <option>Thai</option>
            <option>Tunisian</option>
            <option>Turkish</option>
            <option>Turkmen</option>
            <option>Ukranian</option>
            <option>Uruguayan</option>
            <option>Vietnamese</option>
            <option>Welsh</option>
            <option>Zambian</option>
            <option>Zimbabwean</option>
          </select>
        </div>
        <div className="formrow">
          <label htmlFor="natureOfWork">Nature of Work</label>
          <select name="natureOfWork" id="natureOfWork" className="selectSite" value={userDetails.natureOfWork} onChange={handleInputChange}>
            <option value="">--- Select ---</option>
            <option>Private</option>
            <option>Self employed/professional</option>
          </select>
        </div>
        <div className="formrow">
          <label htmlFor="typeOfIndustry">Type of Industry</label>
          <select name="typeOfIndustry" id="typeOfIndustry" className="selectSite" value={userDetails.typeOfIndustry} onChange={handleInputChange}>
            <option value="">--- Select ---</option>
            <option>Agriculture, Forestry, Mining</option>
            <option>Industrials (Manufacturing, Construction, etc.)</option>
            <option>Energy and Utilities</option>
            <option>Transport and Logistics</option>
            <option>Media and Creative Industries</option>
            <option>Data Infrastructure and Telecom</option>
            <option>Healthcare</option>
            <option>Education</option>
            <option>Life Sciences</option>
            <option>Retail / ecommerce</option>
            <option>Hospitality, Food and Leisure Travel</option>
            <option>Public Service and Social Service</option>
            <option>Financial Services</option>
            <option>Professional Services (Law, Accounting, Consulting, etc.)</option>
            <option>Others</option>
          </select>
        </div>
        <div className="formrow">
          <label htmlFor="sourceOfIncome">Source of Income</label>
          <select name="sourceOfIncome" id="sourceOfIncome" className="selectSite" value={userDetails.sourceOfIncome} onChange={handleInputChange}>
            <option value="">--- Select ---</option>
            <option>Salary</option>
            <option>Investment</option>
            <option>Savings</option>
            <option>Professional fees</option>
            <option>Business income</option>
            <option>Pension</option>
            <option>Commission</option>
            <option>Others</option>
          </select>
        </div>
        <div className="formrow">
          <label htmlFor="idType">ID Type</label>
          <select name="idType" id="idType" className="selectSite" value={userDetails.idType} onChange={handleInputChange}>
            <option value="">--- Select ---</option>
            <option>Phil ID</option>
            <option>Passport</option>
            <option>Driver's License</option>
            <option>Professional Regulations Commission (PRC) ID</option>
            <option>Postal ID</option>
            <option>Voter's ID</option>
            <option>Barangay Certification (with photo)</option>
            <option>Tax Identification (TIN)</option>
            <option>Social Security System (SSS) Card</option>
            <option>Senior Citizen Card</option>
            <option>Alien Certificate of Registration/ Immigrant Certificate of Registration</option>
            <option>Unified Multi-purpose ID (UMID)</option>
          </select>
        </div>
        <div className="formrow">
          <label htmlFor="idNumber">ID Number</label>
          <input type="text" name="idNumber" id="idNumber" maxLength="100" placeholder="ID Number" value={userDetails.idNumber} onChange={handleInputChange}/>
        </div>
        <div className="formrow">
          <div id="verify-alert-cont" className="alert alert-info">
            <div id="verify-alert"></div>
          </div>
        </div>
        </div>
        <div className="formrow">
          {errorMessage && <p className="emsg">{errorMessage}</p>}
          {successMessage && <p className="smsg">{successMessage}</p>}
          <button id="verifyBtn" type="submit">Verify my account</button>
        </div>
      </form>
      {loading && <div>Loading...</div>}
      
    </div>
  );

}

export default Verification;

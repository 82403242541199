import React, { useState, useEffect, useRef, useCallback } from "react"
import axios from "axios"
import QRCode from "qrcode.react"
import AccountSelection from "../snippets/AccountSelection"
import { apiJSONURL } from "../pages/LiveCasino"

const Referral = () => {
  const [username, setUsername] = useState(localStorage.getItem("username"))
  const [referralLink, setReferralLink] = useState("")
  const [activePlayers, setActivePlayers] = useState([])
  const [loading, setLoading] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const referralLinkRef = useRef(null)

  useEffect(() => {
    const savedUsername = localStorage.getItem("username")
    if (savedUsername) {
      setUsername(savedUsername)
    }

    fetchReferralDetails()
  }, [username])

  const fetchReferralDetails = useCallback(async () => {
    if (!username) {
      console.warn("Username is empty.")
      return
    }

    setLoading(true)

    try {
      const params = new URLSearchParams()
      params.append("cmd", "getReferralDetails")
      params.append("u", username)

      const response = await axios.post(apiJSONURL, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })

      // Set the referral link to use 'url2' instead of 'url'
      setReferralLink(response.data.url2)

      // Assuming active players data is part of the response
      setActivePlayers(response.data.l || [])
    } catch (error) {
      console.error("Error fetching referral details:", error)
    } finally {
      setLoading(false)
    }
  }, [username])

  const copyReferralURL = () => {
    if (referralLinkRef.current) {
      referralLinkRef.current.select()
      document.execCommand("copy")
      console.log("Referral URL copied:", referralLinkRef.current.value)
      setShowPopup(true)
      setTimeout(() => setShowPopup(false), 2000) // Hide the popup after 2 seconds
    }
  }

  return (
    <div className="account-content report-page">
      <AccountSelection />
      <div className="referral-wrapper">
        {referralLink && (
          <>
            <div>
              <h2>Referral Program</h2>
              <div className="title-line title-ref" bis_skin_checked="1"></div>
              <div className="referral-link-wrapper">
                <div>
                  <div className="referral-details">
                    <label>Referral ID :</label>
                    <input
                      className="referral-id-input"
                      type="text"
                      id="usernameInput"
                      value={username}
                      placeholder="Username"
                      readOnly
                    />
                  </div>
                  <div className="referral-link">
                    <label>Referral Link :</label>
                    <input
                      className="referral-input"
                      type="text"
                      id="referralLink"
                      value={referralLink}
                      ref={referralLinkRef}
                      readOnly
                    />

                    <div className="btn-generator-wrapper">
                      {showPopup && (
                        <div className="popup-message">
                          Referral URL copied!
                        </div>
                      )}
                      <button
                        className="yellow-btn referral-btn-copy"
                        onClick={copyReferralURL}
                      >
                        Copy Link
                      </button>
                    </div>
                  </div>
                </div>
                <div className="qr-wrapper">
                  <QRCode
                    id="qrcode"
                    value={referralLink}
                    size={200}
                    renderAs="svg"
                    imageSettings={{
                      src: "/images/sg8-casino-logo-1.webp",
                      x: null,
                      y: null,
                      height: 0,
                      width: 0,
                      excavate: false,
                    }}
                  />
                  <div className="qr-image-bg">
                    <img src="/images/sg8-casino-logo-1.webp" alt="Profile" />
                  </div>
                </div>
              </div>
              <div className="tnc-Wrapper">
                <h2>Referral Bonus up to 10%</h2>
                <p>Php 500 playing credits for eligible players.</p>
                <h2>General Mechanics:</h2>
                <ol>
                  <li>
                    Referral commission is based on the referred player’s total
                    losses per week (Monday 06:00 AM – Monday 05:59 AM) and the
                    referrer’s VIP Level (%).
                  </li>
                  <li>
                    NGR = GGR – Welcome Bonus/Birthday Bonus/ other Bonuses –
                    Cashback – Promotion.
                  </li>
                  <li>Expenses</li>
                  <li>NGR (Net Gaming Revenue)</li>
                  <li>GGR (Gross Gaming Revenue)</li>
                </ol>

                <h2>
                  To be eligible for the SG8 Referral bonus, referrer MUST have
                </h2>
                <ol>
                  <li>At least 4 Active referred players weekly.</li>
                  <li>Minimum of Php 8,000 losses from referrals.</li>
                </ol>
                <h2>Sample Computation:</h2>
                <ol>
                  <li>Referred players total losses: Php 10,000</li>
                  <li>
                    Expenses: Php 7,000 (including and not limited to bonuses,
                    promotions and etc)
                  </li>
                  <li>
                    Earned referral commission: Php (10,000 – 7,000) x 10%
                    (Referrer’s VIP Level is PLATINUM = Php 300)
                  </li>
                  <li>
                    SG8 also reserves the right to exclude any
                    players/applicants from this promotion where it detects
                    applicants bearing the same identity (same name, mailing
                    address. Email address, telephone number, payment account)
                    when registering on the website.
                  </li>
                  <li>1x rollover is required before withdrawal bonus</li>
                  <li>
                    VIP Tiers and Requirements
                    <ol style={{ listStyleType: "upper-roman" }}>
                      <li>
                        VIP Tiers will be updated at 1PM (GMT+8) on the 1st day
                        of every month.
                      </li>
                      <li>
                        Meeting both requirements is necessary to advance to the
                        next tier.
                      </li>
                    </ol>
                  </li>
                </ol>
                <h2>Terms and conditions:</h2>
                <ol>
                  <li>General SG8.Casino terms and conditions apply.</li>
                  <li>
                    SG8.Casino reserves the right to take back any bonuses
                    claimed by players in the event that the terms and
                    conditions of the promotion are found to have been breached
                    by the player, and/or there is reasonable suspicion of foul
                    play.
                  </li>
                </ol>
              </div>
            </div>
          </>
        )}
        <div className="referral-data-table">
          <div className="active-players">
            <h2>Active Players</h2>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div className="table-main">
                <table id="activePlayers">
                  <thead>
                    <tr>
                      <th>Username</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activePlayers.length > 0 ? (
                      activePlayers.map((player, index) => (
                        <tr key={index}>
                          <td>{player}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>No active players</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Referral
